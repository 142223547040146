<div class="picture-container">
    <div class="side-pictures">
        <img [src]="firstSource" [alt]="firstAlt">
        <span class="textStyle"><ng-content select="[text1]"></ng-content></span>
    </div>
    <div class="middle-picture">
        <img [src]="secondSource" [alt]="secondAlt">
        <span class="textStyle"><ng-content select="[text2]"></ng-content></span>
    </div>
    <div class="side-pictures">
        <img [src]="thirdSource" [alt]="thirdAlt">
        <span class="textStyle"><ng-content select="[text3]"></ng-content></span>
    </div>
</div>