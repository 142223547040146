<div class="headlines-h1">
    <ng-content select="[headline]"></ng-content>
</div>
<div class="headlines-p">
    <ng-content select="[headline2]"></ng-content>
</div>
<div class="container responsive-container">
    <div class="row justify-content-evenly mb-2">
        <div class="col-sm-12 col-md-5 mb-3">
            <ng-content select="[left]"></ng-content>
        </div>
        <div class="col-sm-12 col-md-5 mb-3">
            <ng-content select="[right]"></ng-content>
        </div>
    </div>
</div>