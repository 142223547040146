import { Pipe, PipeTransform } from '@angular/core';
import { IKursLevel } from '../interfaces';

@Pipe({
  name: 'kursLevelById'
})
export class KursLevelByIdPipe implements PipeTransform {
  transform(id : string, kursLevelList: IKursLevel[]): string {
    if (!kursLevelList || !id) {
      return '';
    }

    const level = kursLevelList.find(l => l.id === id);
    return level ? level.niveau : '';
  }
}
