import { Component, OnInit, Inject, PLATFORM_ID, OnChanges, SimpleChanges } from '@angular/core';
import { EndUserService, ISprache, SkiiraRouteStanglwirt, WebContent, WebContentService } from '@skiira/core';
import { MetaTagsService } from '@skiira/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-buero-de',
    templateUrl: './buero-de.component.html',
    styleUrls: ['./buero-de.component.scss'],
})
export class BueroDeComponent implements OnInit {
    WebContent: WebContent | undefined;
    skiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(
        private WebContentService: WebContentService,
        private metaService: MetaTagsService,
        private endUserService: EndUserService,
        private router: Router
    ) {}

    ngOnInit() {
        try {
            this.metaService.metaTagCaller('BueroStanglwirtDe');
        } catch (error) {
            console.error('Error occured receiving Observable in ngOnInit:', error);
        }

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.bueroEn]);
            }
        });
    }
}
