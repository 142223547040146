<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>

  
  <skiira-textbox>
    <h1 headline>Company name:</h1>
    <ng-container headline2>LR Snow Academies GmbH<br>Oberhausenweg 2b, 6373 Jochberg</ng-container>
  </skiira-textbox>
  
  <div class="container">
    <div class="content-row">
      <br><b>Managing Director:</b><br>
      Alois Reichholf laut Tiroler Skischulgesetz 1995
      <br><br>We are subject to the supervision of the District Administrative Authority BH Kitzbühel in accordance with the Tyrolean Ski Instructors Association and the Tyrolean Ski School Act.
      <br><br>Oberhausenweg 2b
      <br>A-6373 Jochberg
      <br>Büro - Tel. +43699 / 10 71 03 30
      <br><br>Subject to change and errors excepted!
      <br>UID-Nummer: ATU 72362105
      <br>FN 471714y | Landesgericht Innsbruck
      <br><br><b>Our bank details are as follows:</b>
      <br>Raiffeisen Bank
      <br>IBAN: AT55 3626 3000 0406 3418
      <br>BIC: RZTIAT22263
      <br><br>Concept, design, programming: © 2023 WA-UF, all rights reserved 
      <br><br><b>Copyright and trademark law:</b><br>
      <br><br>The content and works created by the site operators on these pages are subject to Austrian copyright law. Reproduction, editing, distribution, and any kind of use beyond the limits of copyright require the written consent of the respective author or creator. Downloads and copies of these pages are only permitted for private, non-commercial use. Insofar as the content on this page was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.
      <br><br><b>The content of the website:</b><br>
      <br><br>The content of our pages has been created with the utmost care. However, we cannot guarantee the accuracy, completeness, and timeliness of the content. As a service provider, we are responsible for our own content on these pages in accordance with § 7 para. 1 TMG (German Telemedia Act) under general laws. According to §§ 8 to 10 TMG, we are not obligated as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under general laws remain unaffected. However, liability in this regard is only possible from the moment of knowledge of a specific legal violation. Upon becoming aware of such legal violations, we will remove this content immediately. All prices listed on the website are in EUR and subject to change.
      <br><br><b>References and Links:</b><br>
      <br><br>Our website may contain links to external third-party websites over whose content we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal content was not recognizable at the time of linking. However, a permanent content control of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any legal violations, we will remove such links immediately.
      <br><br><b>Disclaimer:</b><br>
      <br><br>This disclaimer is to be regarded as part of the internet publication from which you were referred to this page. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact. Despite careful content control, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.
      <br><br><br><br>
    </div>
  </div>
  
  <skiira-footer></skiira-footer>