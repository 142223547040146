<div>
    <img src="../../../../../assets/verleih.jpg" style="width: 100%; height: 80%" />
</div>
<skiira-textbox>
    <h1 headline>Exclusive Ski Rental at Hotel Stanglwirt</h1>
    <ng-container headline2>Elevate Your Alpine Adventure with Premium Equipment</ng-container>
    <ng-container left>The convenience of booking online is unbeatable. You can do it anytime and from anywhere, whether you're at home, on the go, or even at the last minute. It means you're not tied to the opening hours of offices or agencies. With just a few clicks, you have access to a wealth of information and options.</ng-container>
    <ng-container right>Time is precious, and online booking saves you a lot of it. Imagine how much time you save by foregoing the traditional process of making phone calls or showing up in person. On our website, you can accomplish everything in no time, from selecting the best options to confirming your booking.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Comprehensive information and transparency</b><br>Our online booking platform provides detailed information about activities, prices, and availability. You can easily get a comprehensive overview and compare options to choose the one that best suits your needs and preferences. This also means transparency, as you have all the information right in front of you. It offers unbeatable convenience, time savings, comprehensive information, and security.</ng-container>
    <ng-container right><b>Security and Flexibility</b><br>Most reputable online booking platforms offer secure payment methods and data protection policies to safeguard your personal information. Furthermore, our platform also provides flexible cancellation and change policies, offering you a degree of security in case your plans change. Online booking is not only convenient but also environmentally friendly. You save on paper and reduce resource consumption compared to traditional booking methods.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Undoubtedly, online booking will undoubtedly make your planning smoother and more enjoyable.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>
