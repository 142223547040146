<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>

  
  <skiira-textbox>
    <h1 headline>FAQ</h1>
    <ng-container headline2>In this section and for your interest, you will find answers to the most frequently asked questions that we receive.</ng-container>
    <ng-container left></ng-container>
    <ng-container right></ng-container>
  </skiira-textbox>
  
  <div class="container">
    <div class="content-row">
      <br><b>What methods of payment are acceptable?</b><br>
      After checking your availability for your online booking, within 24 hours you will receive a booking confirmation with your payment options and necessary details so that you can make a transfer. After we have received your transfer of the full amount the booking is reserved and binding. The cancellation of a booking up to 7 days before arrival is free of charge and the full amount will be refunded; however, after 7 days before arrival and there is a 100% charge from the full amount.
      <br><br><b>When do the group lessons start?</b><br>
      The children's course begins basically every day except Saturdays.
      <br><br><b>How long is the group lesson?</b><br>
      A full day lesson starts at 10:00hrs to 12:00hrs continuing at 13:00hrs to 15:00hrs. There is a lunch break in between these 2 hour sessions. Half day lessons are from 10:00hrs to 12:00hrs.
      <br><br><b>Is there a possibility of lunch?</b><br>
      Lunch for children is possible every day. This costs € 13,00 per day for each child which will consist of a main course and drink. The midday care will be organized for you directly in our office in the lobby of the Hotel Kempinski das Tirol at the registration. Otherwise the child must be collected by a parent at the time allocated for lunch.
      <br><br><b>Full day or half day courses for children?</b><br>
      As a rule we accept children from the age of 4 years for full day courses. For half day courses we accept children from the ages of 3-4 years old. As a rule, on full day courses we leave with the children for the whole day and do not expect to return to the meeting point until the end of the course. If a child cannot manage this then a proportionate refund will of course be made!
      <br><br><b>What language will be spoken on the course?</b><br>
      All our instructors speak German and English. We have many instructors who have various language skills and can provide private courses or join group lessons as and when possible. We cannot guarantee an instructor who can speak a language other than German or English. However, we will promise to do our absolute best to provide an instructor who can speak your language if at all possible. We will try to fulfil all language requests. It is best to book a private lesson if you do not speak German or English and please specify your language requirement at the time of booking your private lesson. For group lessons this is more difficult and a group lesson cannot be guaranteed!
      <br><br><b>In which language can I have a private lesson?</b><br>
      All our instructors speak German and English. But you will always benefit from lesson from instructors with foreign language skills in other languages, for example: Dutch, Swedish, Danish, Italian, French, Spanish, Portuguese, Croatian, Slovenian, Bulgarian, Russian, Czech Republic, but please make your reservation well in advance. Many of our instructors are booked many months in advance by our regular guests who book for the whole week. So an early booking is recommend so we can reserve our instructors for you!
      <br><br><b>Why should I book online?</b><br>
      The obvious advantage of booking online is that you will get the weekly discount and of course rental too. But there are more benefits from booking online. It will enable you to guarantee your lesson and ski hire especially when at high season instructors and ski hire is booked. We always try to take as many guests as our capacity will allow as long as this does not compromise the high level of service we pride ourselves on.
      <br><br><br><br>
    </div>
  </div>
  
  <skiira-footer></skiira-footer>
  