import { Component, OnInit } from '@angular/core';
import { ApplicationService, Domain, MetaTag } from '@skiira/core';
import { Meta } from '@angular/platform-browser';
import { MetaTagsService } from '@skiira/core';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'skiira-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{
  //domain: Domain = Domain.none;
  domain = '';
  Domain = Domain;

  constructor(public appService: ApplicationService, private meta: Meta, private metaTagService: MetaTagsService, private primeNgConfig: PrimeNGConfig) {}

  ngOnInit() {  
    this.primeNgConfig.ripple = true;
  }
}