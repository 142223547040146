<div class="container" style="margin-top: 10rem; margin-bottom: 2rem;" *ngIf="transfomedData">
    <h1>Zahlungsinfos:</h1>
    <br />

    <div *ngFor="let article of articles; let i = index">
        <div *ngIf="i > 0">
            <p>Zahlung für: {{ article.bezeichnung }}</p>
            <p>Menge: {{ article.menge }}</p>
            <p>Tage: {{ article.tage }}</p>
            <p>Datum von: {{ article.dateFrom | date : 'mediumDate' : '' : 'de-DE' }}</p>
            <p>Datum bis: {{ article.dateTo | date : 'mediumDate' : '' : 'de-DE' }}</p>
            <hr />
            <p *ngIf="article.helm">Helm: {{ article.helmpreis | number : '1.0-2' : 'de-DE' }} €</p>
            <p *ngIf="article.schuhe">Schuhe: {{ article.schuhepreis | number : '1.0-2' : 'de-DE' }} €</p>
            <p *ngIf="article.versichert">Versichert: {{ article.versichertpreis | number : '1.0-2' : 'de-DE' }} €</p>

            <p>Preis ohne MWSt: {{ article.gesamtPreis | number : '1.0-2' : 'de-DE' }} €</p>
            <p>MWSt (20%): {{ article.mwst | number : '1.0-2' : 'de-DE' }} €</p>
            <hr />
            <p>Preis mit MWSt: {{ article.gesamtPreisMitMwst | number : '1.0-2' : 'de-DE' }} €</p>
            <hr style="border-top: 3px solid #bbb" />
            <br />
            <br />
            <br />
        </div>
    </div>
    <h4>Gesamtbetrag:</h4>
    <p>{{ gesamtPreisMitMwst | number : '1.0-2' : 'de-DE' }} €</p>
    <p-button label="Zahlen" styleClass="p-button-raised p-button-success" (click)="showDialog()"></p-button>
    <p-dialog header="Daten eingeben" [(visible)]="visible" [style]="{ width: '50vw' }">

    </p-dialog>
  
    </div>
