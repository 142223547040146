import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class DateInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.body) {
      if (req.body.type && req.body.type.indexOf('application/') > -1) {
        // it's a file
        return next.handle(req);
      }
      for (let prop in req.body) {
        if (req.body[prop] instanceof Date) {
          const d = req.body[prop] as Date;
          const day = (d.getDate()).toString().padStart(2, '0');
          const month = (d.getMonth() + 1).toString().padStart(2, '0');
          const year = d.getFullYear().toString();
          const newDate = `${year}-${month}-${day}T00:00:00+0000`;
          req.body[prop] = new Date(newDate);
        }
      }
    }
    return next.handle(req);
  }

}
