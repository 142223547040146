import { Component, Input } from '@angular/core';

@Component({
  selector: 'skiira-werbecontainer',
  templateUrl: './werbecontainer.component.html',
  styleUrls: ['./werbecontainer.component.scss'],
})
export class WerbecontainerComponent {
  @Input() imageUrl = '';
  @Input() imageAlt  = '';
}
