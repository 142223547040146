<div>
    <img src="../../../assets/verleih.jpg" style="width: 100%; height: 80%" />
</div>

<div *ngIf="environment.production === false" style="margin: 24px; margin-top: 120px;">
    <p-dataView #dv [value]="verleihArticleList" [layout]="layout">
        <ng-template pTemplate="header">
            <div class="flex justify-content-end">
                <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
            </div>
        </ng-template>

        <!-- List View Template -->
        <ng-template let-verleihItem pTemplate="listItem">
            <form [formGroup]="carditem" (ngSubmit)="cardSubmit(verleihItem)">
                <div class="halfW-center">
                    <div class="col-12">
                        <div class="ticket">
                            <div>{{ verleihItem.kursModelList[0].texte | articleTextPipe: 'Titel' }}</div>
                            <div *ngIf="verleihItem.fotolink">
                                <img class="imagesize-dataview" [src]="verleihItem.fotolink" alt="Image not Found" />
                            </div>
                            <div class="flexbox-userinteraction m-2">
                                <div>
                                    <div
                                        class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2 inliner">
                                        <div class="inliner">
                                            <span>Ab &euro; </span>
                                            <span>{{ verleihItem.kursModelList[0].preis.preis1 }}</span>
                                        </div>
                                        <div class="inliner">
                                            <span>Tage: </span>
                                            <p-dropdown [options]="verleihItem.preistags" [(ngModel)]="selectedTag"
                                                [ngModelOptions]="{standalone: true}"
                                                (onChange)="priceFactoring($event)" optionLabel="name"
                                                placeholder="Wählen Sie die Tage"></p-dropdown>
                                        </div>
                                        <div class="inliner">
                                            <div class="m-2 inliner">Menge:</div>
                                            <p-dropdown [options]="numberlist" formControlName="menge"
                                                optionLabel="name" placeholder="Wählen Sie die Menge"></p-dropdown>
                                        </div>
                                        <div class="inliner">
                                            <div class="m-2 inliner">Datum:</div>
                                            <p-calendar class="inliner" formControlName="dateFrom"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="inliner" *ngFor="let variant of verleihItem.kursModelList[0].varianten">
                                        <p-checkbox class="checkbox-margin" value="true"
                                            inputId="label"></p-checkbox>
                                        <label for="label">{{variant.variantenContent[1].text | articleTextPipe: 'beschreibung'}}</label>
                                        <button (click)="verleihItemFunction(verleihItem)" type="button">Click me!</button>
                                    </div>
                                </div>
                            </div>
                            <button pButton icon="pi pi-shopping-cart"
                                class="ml-auto mb-2 p-button-rounded cart-button"></button>

                        </div>
                    </div>
                </div>
            </form>
        </ng-template>
        <!-- Grid View Template -->
        <ng-template let-verleih pTemplate="gridItem">
            <div class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
                <div class="p-4 border-1 surface-border surface-card border-round">
                    <div class="flex flex-column align-items-center gap-3 py-5">
                        <div class="text-2xl font-bold">{{ verleih.bezeichnung }}</div>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <span class="text-2xl font-semibold">{{ '€' + verleih.grundPreis }}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>

<skiira-textbox>
    <h1 headline>Exklusiver Skiverleih im Hotel Stanglwirt</h1>
    <ng-container headline2>Erleben Sie erstklassige Ausrüstung für Ihr alpines Abenteuer</ng-container>
    <ng-container left>Die Möglichkeit, online zu buchen, ist unschlagbar bequem. Sie können dies jederzeit und von
        überall aus erledigen, sei es von zu Hause aus, während Sie unterwegs sind oder sogar in letzter Minute. Es
        bedeutet, dass Sie nicht an die Öffnungszeiten von Büros oder Agenturen gebunden sind. Mit wenigen Klicks haben
        Sie Zugang zu einer Fülle von Informationen und Optionen.</ng-container>
    <ng-container right>Die Zeit ist kostbar, und Online-Buchen spart Ihnen eine Menge davon. Stellen Sie sich vor, wie
        viel Zeit Sie sparen, indem Sie auf den herkömmlichen Prozess des Telefonierens oder des persönlichen
        Erscheinens verzichten. Auf unserer Seite können Sie in kürzester Zeit alles erledigen, von der Auswahl der
        besten Optionen bis hin zur Bestätigung Ihrer Buchung.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Umfassende Informationen und Transparenz</b><br>Unsere Online-Buchungsplattform bietet
        detaillierte Informationen über Aktivitäten, Preise und Verfügbarkeiten. Sie können sich leicht einen
        umfassenden Überblick verschaffen und die Optionen vergleichen, um diejenige auszuwählen, die am besten zu Ihren
        Bedürfnissen und Vorlieben passt. Dies bedeutet auch Transparenz, da Sie alle Informationen direkt vor sich
        haben. Es bietet unschlagbare Bequemlichkeit, Zeitersparnis, umfassende Informationen und
        Sicherheit.</ng-container>
    <ng-container right><b>Sicherheit und Flexibilität</b><br>Die meisten seriösen Online-Buchungsplattformen bieten
        sichere Zahlungsmethoden und Datenschutzrichtlinien, um Ihre persönlichen Informationen zu schützen. Darüber
        hinaus bietet unsere Plattform auch flexible Stornierungs- und Änderungsrichtlinien, die Ihnen eine gewisse
        Sicherheit geben, falls sich Ihre Pläne ändern. Online-Buchen ist nicht nur praktisch, sondern auch
        umweltfreundlich. Sie sparen Papier und reduzieren den Ressourcenverbrauch im Vergleich zu traditionellen
        Buchungsmethoden.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Online-Buchen wird zweifellos dazu beitragen, Ihre Planung nahtloser und angenehmer zu
        gestalten.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>
