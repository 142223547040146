import { EndUserService } from './../../../../../../../libs/skiira-core/src/lib/services/enduser.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-langlauf-de',
    templateUrl: './langlauf-de.component.html',
    styleUrls: ['./langlauf-de.component.scss'],
})
export class LanglaufDeComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;
    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('LanglaufDe');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.langlaufEn]);
            }
        });
    }
}
