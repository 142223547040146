export enum SkiiraRouteStanglwirt {
    none = '',
    agb = 'agb',
    biathlon = 'biathlon',
    biathlonEn = 'biathlon-en',
    buchen = 'buchen',
    buchenEn = 'buchen-en',
    buero = 'skischool-office',
    bueroEn = 'skischool-office-en',
    events = 'events',
    eventsEn = 'events-en',
    faq = 'information_faq',
    faqEn = 'information_faq-en',
    freeride = 'freeride',
    freerideEn = 'freeride-en',
    gruppenkurs = 'gruppenkurs',
    gruppenkursEn = 'gruppenkurs-en',
    heliskiing = 'heli-skiing',
    heliskiingEn = 'heli-skiing-en',
    impressum = 'impressum',
    imprint = 'imprint',
    kitzbueheltv = 'kitzbueheltv',
    kitzbueheltvEn = 'kitzbueheltv-en',
    kontakt = 'contact',
    kontaktEn = 'contact-en',
    langlauf = 'langlauf',
    langlaufEn = 'langlauf-en',
    location = 'de',
    locationEn = 'en',
    partner = 'information_partner',
    partnerEn = 'information_partner-en',
    privatunterricht = 'skischool-stanglwirt-private',
    privatunterrichtEn = 'skischool-stanglwirt-private-en',
    renntraining = 'renntraining',
    renntrainingEn = 'renntraining-en',
    schikurse = 'skischool-stanglwirt',
    schikurseEn = 'skischool-stanglwirt-en',
    skischule = 'skischule',
    skischuleEn = 'skischule-en',
    skiverleih = 'skiverleih',
    skiverleihEn = 'skiverleih-en',
    tourenski = 'rent-a-ski-tourenski',
    tourenskiEn = 'rent-a-ski-tourenski-en',
    verleih = 'verleih',
    verleihEn = 'verleih-en',
    zahlung = 'zahlung',
    tos = 'terms-conditions',

    //verleih

    economyde = 'economy',
    economyen = 'economy-en',
    platinde = 'platin',
    platinen = 'platin-en',
    premiumde = 'premium',
    premiumen = 'premium-en',
    snowboard = 'snowboard',
    snowboarden = 'snowboard-en',
    

}
