import { Injectable } from '@angular/core';
import { IVerleihSubmitParent } from '../interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { VerleihSubmitParent } from '../models/verleihSubmitParent.model';

@Injectable({
  providedIn: 'root'
})
export class ZahlungService {
  private dataHistory: BehaviorSubject<IVerleihSubmitParent[]> = new BehaviorSubject<IVerleihSubmitParent[]>([new VerleihSubmitParent()]);
  private latestDataObject: BehaviorSubject<IVerleihSubmitParent> = new BehaviorSubject<IVerleihSubmitParent>(new VerleihSubmitParent());
  totalprice: number = 0;

  private hasChangedInitially = false;
  private hasChangedInitiallySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.hasChangedInitially);

  setTotalPrice(price: number) {
    this.totalprice = price;
  }

  getTotalPrice(): number {
    return this.totalprice;
  }

  setCurrentData(dataIn: IVerleihSubmitParent) {
    
    // Update the latest data object
    this.latestDataObject.next(dataIn);

    if (!this.hasChangedInitially) {
      this.hasChangedInitially = true;
      this.hasChangedInitiallySubject.next(this.hasChangedInitially);
    }
  }

  setHistoryData(dataIn: IVerleihSubmitParent[]) {
    this.dataHistory.next(dataIn);
  }

  // To get the latest data
  getLatestData(): Observable<IVerleihSubmitParent> {
    return this.latestDataObject.asObservable();
  }

  // To get the entire history in the required format
  getHistoryDataObject(): Observable<IVerleihSubmitParent[]> {
    return this.dataHistory.asObservable();
  }

  // To check if data has changed initially
  getHasChangedInitially(): Observable<boolean> {
    return this.hasChangedInitiallySubject.asObservable();
  }

  // Reset the data
  resetData() {
    this.dataHistory.next([new VerleihSubmitParent()]);
    this.latestDataObject.next(new VerleihSubmitParent());
    this.hasChangedInitially = false;
    this.hasChangedInitiallySubject.next(false);
  }
}
