<header>
    <!-- Modal -->

    <div class="header-grid unset-header-background">
        <div class="col-1 text-center">
            <div class="dropdown" (mouseover)="hoverHeader()" (mouseout)="unhoverHeader()">
                <div class="dropdown-btn">
                    <div class="dropdown-button">
                        <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <a *ngIf="lang(skiiraLanguage.german)">Skischule</a>
                            <a *ngIf="lang(skiiraLanguage.english)">Ski school</a>
                        </button>
                        <ul class="dropdown-list" *ngIf="lang(skiiraLanguage.german)">
                            <li>
                                <a class="dropdown-item" [routerLink]="skiiraRoute.privatunterricht">
                                    Privatunterricht
                                </a>
                            </li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.gruppenkurs">Gruppenkurs</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.freeride">Freeride & Touren</a></li>
                            <li>
                                <a class="dropdown-item" [routerLink]="skiiraRoute.renntraining">Rennlauf-Training</a>
                            </li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.events">Events</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.heliskiing">Heli-Skiing</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.langlauf">Langlauf</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.biathlon">Biathlon</a></li>
                        </ul>
                        <ul class="dropdown-list" *ngIf="lang(skiiraLanguage.english)">
                            <li>
                                <a class="dropdown-item" [routerLink]="skiiraRoute.privatunterrichtEn">
                                    Private Lessons
                                </a>
                            </li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.gruppenkursEn">Group Courses</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.freerideEn">Freeride & Tours</a></li>
                            <li>
                                <a class="dropdown-item" [routerLink]="skiiraRoute.renntrainingEn">Race Training</a>
                            </li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.eventsEn">Events</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.heliskiingEn">Heli-Skiing</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.langlaufEn">Cross-Country</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.biathlonEn">Biatholon</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-1 text-center me-4">
            <div (mouseover)="hoverHeader()" (mouseout)="unhoverHeader()">
                <div class="dropdown-btn">
                    <div class="dropdown-button">
                        <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                        >
                            <a [routerLink]="skiiraRoute.verleih" *ngIf="lang(skiiraLanguage.german)">Skiverleih</a>
                            <a [routerLink]="skiiraRoute.verleihEn" *ngIf="lang(skiiraLanguage.english)">Ski rental</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <div style="z-index: 10000" [routerLink]="skiiraRoute.location">
            <img
                src="assets\StanglwirtSnowakademy-cropped.svg"
                width="200px"
                height="100px"
                class="headerimg"
                (mouseover)="hoverHeader()"
                (mouseout)="unhoverHeader()"
            />
        </div>

        <div class="col-1 text-center ms-4">
            <div class="dropdown" (mouseover)="hoverHeader()" (mouseout)="unhoverHeader()">
                <div class="dropdown-btn">
                    <div class="dropdown-button">
                        <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            (click)="routeBuchung()"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <a *ngIf="lang(skiiraLanguage.german)" [href]="skiiraRoute.buchen">Onlinebuchen</a>
                            <a *ngIf="lang(skiiraLanguage.english)" [href]="skiiraRoute.buchenEn">Book Online</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-1 text-center">
            <div class="dropdown" (mouseover)="hoverHeader()" (mouseout)="unhoverHeader()">
                <div class="dropdown-btn">
                    <div class="dropdown-button">
                        <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <a *ngIf="lang(skiiraLanguage.german)">Info</a>
                            <a *ngIf="lang(skiiraLanguage.english)">Information</a>
                        </button>
                        <ul class="dropdown-list" *ngIf="lang(skiiraLanguage.german)">
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.buero">Büros</a></li>
                            <li>
                                <a class="dropdown-item" [routerLink]="skiiraRoute.kitzbueheltv">Kitzb&uuml;hel TV</a>
                            </li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.kontakt">Kontakt</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.faq">FAQ</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.partner">Partner</a></li>
                        </ul>
                        <ul class="dropdown-list" *ngIf="lang(skiiraLanguage.english)">
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.bueroEn">Offices</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.kitzbueheltvEn">Kitzbühel TV</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.kontaktEn">Contact</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.faqEn">FAQ</a></li>
                            <li><a class="dropdown-item" [routerLink]="skiiraRoute.partnerEn">Partner</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Burger Menu Button -->
            <div class="container" (click)="toogleBurgerMenu()" type="button">
                <a class="myMenuToggle">
                    <div [ngClass]="burgerMenuOpen ? 'nav-open' : 'nav-close'">
                        <span class="menu-toggle-bar myMenuToggleBarTop"></span>
                        <span class="menu-toggle-bar myMenuToggleBarMiddle"></span>
                        <span class="menu-toggle-bar myMenuToggleBarBottom"></span>
                    </div>
                </a>
            </div>
        </div>
        <div *ngIf="shoppingCartAvailable">
            <i
                class="pi pi-shopping-cart shopping-cart"
                [attr.value]="articleCount"
                (click)="shoppingCartDisplay()"
            ></i>
            <div class="shopping-cart-dropdown-container" *ngIf="shoppingCartFullcontext">
                <div *ngFor="let item of shoppingCartItems; let i = index">
                    <ng-container *ngIf="i > 0">
                        <div class="shoppingcart-row">
                            <p>{{ item.bezeichnung }}</p>
                            <p-button
                                icon="pi pi-trash"
                                styleClass=" p-button-rounded p-button-danger p-button-text p-button-raised"
                                (onClick)="deleteShoppingCartArticle(item)"
                            ></p-button>
                        </div>
                        <p>Preis: {{ item.gesamtPreis | number : '1.0-2' : 'de-DE' }} €</p>
                        <hr />
                    </ng-container>
                </div>
                <p>Mwst:</p>
                <p>{{ latestTotalmwst | number : '1.0-2' : 'de-DE' }} €</p>
                <hr />
                <p>Gesamtpreis:</p>
                <p>{{ latestTotalPrice | number : '1.0-2' : 'de-DE' }} €</p>
                <p-button
                    styleClass="p-button-sm p-button-raised p-button-text mg-r-12"
                    label="Kasse"
                    (click)="routeKasse()"
                ></p-button>
                <p-button
                    styleClass="p-button-sm p-button-raised p-button-text p-button-danger"
                    label="Alles Löschen"
                    (click)="deleteCart()"
                ></p-button>
            </div>
        </div>
    </div>

    <!--Burgermenue-->
    <p-sidebar [(visible)]="burgerMenuOpen" position="right" class="sidebar">
        <div class="container" (click)="toogleBurgerMenu()" type="button">
            <a class="myMenuToggle-sidebar">
                <div [ngClass]="burgerMenuOpen ? 'nav-open' : 'nav-close'">
                    <span class="menu-toggle-bar myMenuToggleBarTop"></span>
                    <span class="menu-toggle-bar myMenuToggleBarMiddle"></span>
                    <span class="menu-toggle-bar myMenuToggleBarBottom"></span>
                </div>
            </a>
        </div>

        <a class="sidebar-title">Sprachen</a>
        <div class="lang">
            <ul>
                <li
                    *ngFor="let sprache of userService.languageList"
                    (click)="selectLanguage(sprache)"
                    style="cursor: pointer"
                >
                    {{ sprache.bezeichnung }}
                </li>
            </ul>
        </div>

        <div class="responsive-header">
            <div class="responsive-button" (click)="skiSchuleClickResponsive()">
                <button *ngIf="lang(skiiraLanguage.german)" class="btn-style">Skischule</button>
                <button *ngIf="lang(skiiraLanguage.english)" class="btn-style">Ski school</button>
                <ul *ngIf="skiSchuleResponsiveClicked && lang(skiiraLanguage.german)" style="list-style-type: none;">
                    <li>
                        <a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.privatunterricht">
                            Privatunterricht
                        </a>
                    </li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.gruppenkurs">Gruppenkurs</a></li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.freeride">Freeride & Touren</a></li>
                    <li>
                        <a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.renntraining">Rennlauf-Training</a>
                    </li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.events">Events</a></li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.heliskiing">Heli-Skiing</a></li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.langlauf">Langlauf</a></li>
                </ul>
                <ul *ngIf="skiSchuleResponsiveClicked && lang(skiiraLanguage.english)" style="list-style-type: none;">
                    <li>
                        <a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.privatunterrichtEn">
                            Private Lessons
                        </a>
                    </li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.gruppenkursEn">Group Courses</a></li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.freerideEn">Freeride & Tours</a></li>
                    <li>
                        <a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.renntrainingEn">Race Training</a>
                    </li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.eventsEn">Events</a></li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.heliskiingEn">Heli-Skiing</a></li>
                    <li><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.langlaufEn">Cross-Country</a></li>
                </ul>
            </div>
            <div class="responsive-button">
                <button [routerLink]="skiiraRoute.verleih" *ngIf="lang(skiiraLanguage.german)" class="btn-style">
                    Skiverleih
                </button>
                <button [routerLink]="skiiraRoute.verleihEn" *ngIf="lang(skiiraLanguage.english)" class="btn-style">
                    Ski Rental
                </button>
            </div>
            <div class="responsive-button" (click)="routeBuchung()">
                <button class="btn-style" *ngIf="lang(skiiraLanguage.german)">Onlinebuchen</button>
                <button class="btn-style" *ngIf="lang(skiiraLanguage.english)">Book Online</button>
            </div>
            <div class="responsive-button" (click)="infoClickResponsive()">
                <button *ngIf="lang(skiiraLanguage.german)" class="btn-style">Info</button>
                <button *ngIf="lang(skiiraLanguage.english)" class="btn-style">Information</button>
                <ul *ngIf="infoResponsiveClicked && lang(skiiraLanguage.german)" style="list-style-type: none;">
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.buero">Büros</a></li>
                    <li (click)="toogleBurgerMenu()">
                        <a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.kitzbueheltv">Kitzb&uuml;hel TV</a>
                    </li>
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.kontakt">Kontakt</a></li>
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.faq">FAQ</a></li>
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.partner">Partner</a></li>
                </ul>
                <ul *ngIf="infoResponsiveClicked && lang(skiiraLanguage.english)">
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.buero">Offices</a></li>
                    <li (click)="toogleBurgerMenu()">
                        <a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.kitzbueheltv">Kitzb&uuml;hel TV</a>
                    </li>
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.kontakt">Contact</a></li>
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.faq">FAQ</a></li>
                    <li (click)="toogleBurgerMenu()"><a class="dropdown-item-sidebar" [routerLink]="skiiraRoute.partner">Partners</a></li>
                </ul>
            </div>
            <div class="responsive-button" *ngIf="lang(skiiraLanguage.german)">
                <button class="btn-style" [routerLink]="skiiraRoute.imprint" (click)="toogleBurgerMenu()">Impressum</button>
            </div>
            <div class="responsive-button" *ngIf="lang(skiiraLanguage.german)">
                <button class="btn-style" [routerLink]="skiiraRoute.agb" (click)="toogleBurgerMenu()">AGB</button>
            </div>
            <div class="responsive-button" *ngIf="lang(skiiraLanguage.english)">
                <button class="btn-style" [routerLink]="skiiraRoute.imprint" (click)="toogleBurgerMenu()">Impressum</button>
            </div>
            <div class="responsive-button" *ngIf="lang(skiiraLanguage.english)">
                <button class="btn-style" [routerLink]="skiiraRoute.tos" (click)="toogleBurgerMenu()">T&C</button>
            </div>
        </div>
        <div class="agb-impressum-button" *ngIf="lang(skiiraLanguage.german)">
            <button class="btn-style" [routerLink]="skiiraRoute.imprint" (click)="toogleBurgerMenu()">Impressum</button>
        </div>
        <div class="agb-impressum-button" *ngIf="lang(skiiraLanguage.german)">
            <button class="btn-style" [routerLink]="skiiraRoute.agb" (click)="toogleBurgerMenu()">AGB</button>
        </div>
        <div class="agb-impressum-button" *ngIf="lang(skiiraLanguage.english)">
            <button class="btn-style" [routerLink]="skiiraRoute.imprint" (click)="toogleBurgerMenu()">Impressum</button>
        </div>
        <div class="agb-impressum-button" *ngIf="lang(skiiraLanguage.english)">
            <button class="btn-style" [routerLink]="skiiraRoute.tos" (click)="toogleBurgerMenu()">T&C</button>
        </div>
    </p-sidebar>
</header>
