import { SkiiraRoute } from './../../../../../../../libs/skiira-core/src/lib/enums/skiira-routes.enum';
import { EndUserService } from './../../../../../../../libs/skiira-core/src/lib/services/enduser.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-kontakt-de',
    templateUrl: './kontakt-de.component.html',
    styleUrls: ['./kontakt-de.component.scss'],
})
export class KontaktDeComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('KontaktDe');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.kontaktEn]);
            }
        });
    }

    openMail() {
        window.open('mailto: book@snowacademy-stanglwirt.com');
    }
}
