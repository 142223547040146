import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ImageService {

  getImages(param: string): object {
    console.log(param);
    switch (param) {
      case 'areitexpress':
        console.log('areitexpress called');
        return {type: 'image', src: '/assets/areitexpress.jpg', alt:'Areitexpress', title:'Areitexpress'};
        
      case 'cityexpress':
        return {type: 'image', src: '/assets/cityexpress.jpg', alt:'CityExpress', title:'CityExpress'};
        
      case 'schmittenhoehe':
        return {type: 'image', src: '/assets/schmittenhoehe.jpg', alt:'Schmittenhöhe Zell am See', title:'Schmittenhöhe Zell am See'};
        
      case 'postplatz':
        return {type: 'image', src: '/assets/postplatz.jpg', alt:'Postplatz Zell am See', title:'Postplatz Zell am See'};

        
      default:
        return {type: 'image', src: '/assets/postplatz.jpg', alt:'Schmittenhöhe Zell am See', title:'Schmittenhöhe Zell am See'};
    }

  }
  getImagesFromDomain(param: string): object {
      
    switch(param){
      case 'skischule-stanglwirt.com':
        return {type: 'image', src: '/assets/bio-hotel-stanglwirt.jpg', alt:'Stanglwirt', title:'Stanglwirt'};

      case 'snowacademy-stanglwirt.com':
        return {type: 'image', src: '/assets/bio-hotel-stanglwirt.jpg', alt:'Stanglwirt', title:'Stanglwirt'};
      
      case 'skischulekitzbuehel.com':
        return {type: 'image', src: '/assets/kitzbuehel-im-sommer.jpg', alt:'Kitzbühel', title:'Kitzbühel'};
      
      default:
        return {type: 'image', src: '/assets/skkinggulmarg.jpg', alt:'Skking', title:'Skkking'};
    }
  }
}
