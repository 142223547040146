import { Component, OnInit } from '@angular/core';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';
import { Router } from '@angular/router';

@Component({
    selector: 'skiira-heli-skiing-en',
    templateUrl: './heli-skiing-en.component.html',
    styleUrls: ['./heli-skiing-en.component.scss'],
})
export class HeliSkiingEnComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.english;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('HeliSkiingEn');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.heliskiing]);
            }
        });
    }
}
