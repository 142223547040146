import { Component, OnInit } from '@angular/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { Router } from '@angular/router';

@Component({
  selector: 'skiira-economy-class-de',
  templateUrl: './economy-class-de.component.html',
  styleUrls: ['./economy-class-de.component.scss'],
})
export class EconomyClassDeComponent implements OnInit{
  rangeDates: Date[] | undefined;

  skiiraLanguage = SkiiraLanguage.english;
  skiiraRoutes = SkiiraRouteStanglwirt;

  constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

  ngOnInit() {

      this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
          if (lang.abkuerzung !== this.skiiraLanguage) {
              this.router.navigate([this.skiiraRoutes.economyen]);
          }
      });
  }


}
