import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-privatunterricht-de',
    templateUrl: './privatunterricht-de.component.html',
    styleUrls: ['./privatunterricht-de.component.scss'],
})
export class PrivatunterrichtDeComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;
    constructor(
        private metaService: MetaTagsService,
        private meta: Meta,
        private endUserService: EndUserService,
        private router: Router
    ) {}

    ngOnInit() {
        this.metaService.metaTagCaller('PrivatunterrichtDe');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.privatunterrichtEn]);
            }
        });
    }
}
