import { NgModule } from "@angular/core";
import { ScrollTrackerDirective } from "./track-scroll.directive"; 

@NgModule({
  declarations: [
    ScrollTrackerDirective,
  ],
  exports: [
    ScrollTrackerDirective,
  ]
})
export class DirectivesModule {}
