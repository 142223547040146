import { Pipe, PipeTransform } from '@angular/core';
import { IKursLevel } from '../interfaces';
import { KursTextModel } from '../models';

@Pipe({
  name: 'articleTextPipe'
})
export class ArticleTextPipe implements PipeTransform {
  transform(textList : KursTextModel[], key: string): string {
    var textTypeList = textList.filter(t => t.type.textType === key);
    var text = textTypeList?.find(t => t.kursText.spracheId.toLowerCase() === 
        'DB8AE4A9-947F-4CF8-906B-AE55CCB2C963'.toLowerCase()) || null;
    if (text){
        return text.kursText.beschreibung;
    }
    return 'nf.';
  }
}
