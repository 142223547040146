<div class="headerImage"><img src="../../../../../assets/langlauf.jpg" /></div>


<skiira-textbox>
    <h1 headline>Langlauf - Klassischer Stil & Skating Technik</h1>
    <ng-container headline2>Dominik Landertinger, vierfacher Olympia-, fünffacher WM- Medaillengewinner und Weltmeister</ng-container>
    <ng-container left>Langlauf, eine Disziplin des nordischen Skisports, verkörpert die Schönheit der Natur, die Herausforderung der Ausdauer und die Kunst der Technik. In Zusammenarbeit mit Dominik Landertinger, einem angesehenen Biathleten und Langlauf-Enthusiasten, werfen wir einen Blick auf die Faszination des Langlaufs, seine gesundheitlichen Vorteile und Dominiks persönliche Erfahrungen.</ng-container>
    <ng-container right>Langlaufen, auch bekannt als Ski-Langlauf, zeichnet sich durch das Gleiten über verschneite Landschaften aus. Es verbindet den Genuss der Natur mit körperlicher Aktivität und ermöglicht es den Sportlern, in einer friedlichen Umgebung in den Rhythmus zu kommen. Die gleichmäßigen Bewegungen beanspruchen den ganzen Körper, stärken das Herz-Kreislauf-System und verbessern die Ausdauer.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/langlauf1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/langlauf2.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/langlauf3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
</skiira-picture-grid>

<skiira-textbox>
    <ng-container left><b>Gesundheitliche Vorteile des Langlaufs</b><br>Dominik Landertinger hebt hervor, wie der Langlauf zu seiner körperlichen Fitness und mentalen Ausgeglichenheit beigetragen hat. Die sportliche Betätigung in der frischen Bergluft fördert die körperliche Gesundheit, während das Eintauchen in die Natur Stress abbaut und das Wohlbefinden steigert. Langlaufen ist auch für alle Altersgruppen zugänglich und bietet eine schonende Möglichkeit, die Gelenke zu schonen, während Muskeln und Ausdauer gestärkt werden.</ng-container>
    <ng-container right><b>Technik und Herausforderung</b><br>Die Technik des Langlaufs kann anspruchsvoll sein, erfordert jedoch keine besonderen Fähigkeiten. Anfänger können die klassische Technik erlernen, bei der die Bewegungen ähnlich dem Gehen oder Laufen sind. Die Skating-Technik erinnert hingegen eher an das Eislaufen und ist dynamischer. Dominik Landertinger betont die Bedeutung einer guten Technik, die nicht nur die Effizienz erhöht, sondern auch das Verletzungsrisiko verringert.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Dominik Landertingers Begeisterung für den Langlauf</b><br>Dominik hat eine tiefe Leidenschaft für den Langlauf entwickelt. Neben seiner Biathlon-Karriere genießt er die friedlichen Momente auf den Langlaufloipen. Er schätzt die Verbindung zur Natur und die Möglichkeit, sich abseits des Wettbewerbs zu erholen. Landertinger betrachtet den Langlauf nicht nur als Trainingsmethode, sondern als einen Weg, das Leben in vollen Zügen zu genießen.</ng-container>
    <ng-container right><b>Ein Sport für Körper und Seele</b><br>Langlauf ist mehr als nur eine Sportart – es ist eine Verbindung zur Natur, eine Auszeit vom Alltag und eine Möglichkeit, die Gesundheit zu fördern. In Zusammenarbeit mit Dominik Landertinger wird die Magie des Langlaufs hervorgehoben. Unabhängig von Ihrem Alter oder Fitnessniveau bietet der Langlauf eine Möglichkeit, die Freude an der Bewegung, die Schönheit der Natur und die Befriedigung, sich selbst zu überwinden, zu erleben.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <h1 headline>Maximiere dein Potential</h1>
    <ng-container headline2>Maßgeschneiderte Leistungstests und Trainingspläne mit Laktatmessung</ng-container>
    <ng-container left>Unsere individuellen Leistungstests und Trainingspläne mit Laktatmessung bieten eine bahnbrechende Möglichkeit, deine sportliche Leistungsfähigkeit zu optimieren. Durch präzise Messungen und personalisierte Analysen helfen wir dir, deine körperlichen Grenzen zu erkennen und maßgeschneiderte Trainingspläne zu erstellen. </ng-container>
    <ng-container right>Egal, ob du ein erfahrener Athlet oder ein Fitnessenthusiast bist, unser Angebot ermöglicht es dir, effizienter zu trainieren, Verletzungen zu minimieren und deine Ziele schneller zu erreichen. Lass uns gemeinsam dein volles Potential entfalten.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>