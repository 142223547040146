<div class="headerImage"><img src="../../../../../assets/heliskiing.jpg" /></div>


<skiira-textbox>
    <h1 headline>Heli-Skiing in den Österreichischen und Italienischen Alpen</h1>
    <ng-container headline2>Ein ultimatives Abenteuer auf und abseits der Pisten</ng-container>
    <ng-container left>Für Wintersportenthusiasten, die nach einem wahrhaft außergewöhnlichen Erlebnis suchen, bietet Heli-Skiing in den Österreichischen und Italienischen Alpen die Möglichkeit, unberührte Tiefschneehänge in einem spektakulären Bergpanorama zu erleben. Diese Form des Skifahrens ist eine Kombination aus Abenteuer, Nervenkitzel und atemberaubender Naturschönheit und lässt das Herz jedes Skiliebhabers höherschlagen.</ng-container>
    <ng-container right>Heli-Skiing ist eine Art des Skifahrens, bei der Skifahrer und Snowboarder mit einem Hubschrauber zu abgelegenen Berggipfeln transportiert werden, um dann die Abfahrt auf unberührtem Tiefschnee zu genießen. Im Gegensatz zu herkömmlichen Skigebieten bietet Heli-Skiing den Zugang zu Gebieten, die normalerweise schwer oder gar nicht erreichbar sind. Dies eröffnet die Möglichkeit, lange Abfahrten in unberührtem Schnee zu erleben und die Freiheit des Off-Piste-Fahrens zu genießen.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Die Österreichischen Alpen:</b><br>Ein Paradies für Heli-Skifahrer<br>Die Österreichischen Alpen bieten eine beeindruckende Kulisse für Heli-Skiing-Abenteuer. Von den majestätischen Gipfeln der Alpen bis zu den weitläufigen Tiefschneehängen, gibt es unzählige Möglichkeiten für unvergessliche Abfahrten. Berühmte Orte wie Kitzbühel und Going sind die Ausgangspunkte für unsere Heli-Skiing-Touren, die unvergleichliche Panoramen und pures Schneevergnügen bieten.</ng-container>
    <ng-container right><b>Die Italienischen Alpen:</b><br>Eine unberührte Oase für Abenteurer<br>Die Italienischen Alpen sind ein Geheimtipp für Heli-Skifahrer, die die Magie unberührter Schneehänge suchen. Sie bieten endlose Möglichkeiten für spannende Abfahrten. Das einzigartige alpine Ambiente, gepaart mit der italienischen Gastfreundschaft und Küche, macht Heli-Skiing in den Italienischen Alpen zu einem unvergesslichen Erlebnis.</ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/heliskiing_powder.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Preise Heli-Skiing</h2>
        <p beschreibung><li><b>Heli-Skiing - 3 Stunden</b></li>
                        von 09:00 - 12:00 Uhr
                        <br>
                        <b><font color="#b68b1c">Preis auf Anfrage</font></b><br>
        </p>
        <p beschreibung><li><b>Heli-Skiing - 3 Stunden</b></li>
                        von 13:00 - 16:00 Uhr
                        <br>
                        <b><font color="#b68b1c">Preis auf Anfrage</font></b><br>
        </p>
        <p beschreibung><li><b>Heli-Skiing - ganzer Tag</b></li>
                        ...zur freien Verfügung!
                        <br>
                        <b><font color="#b68b1c">Preis auf Anfrage</font></b><br>
        </p>
</skiira-werbecontainer>

<skiira-footer></skiira-footer>