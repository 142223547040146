import { Component, OnInit } from '@angular/core';
import { EndUserService, ISprache, MetaTagsService, PartnerService, SkiiraRouteStanglwirt } from '@skiira/core';
import { firstValueFrom } from 'rxjs';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';
import { Router } from '@angular/router';
@Component({
    selector: 'skiira-partner-de',
    templateUrl: './partner-de.component.html',
    styleUrls: ['./partner-de.component.scss'],
})
export class PartnerDeComponent implements OnInit {
    public r: any[] = [];
    skiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(
        public partnerService: PartnerService,
        private metaService: MetaTagsService,
        private endUserService: EndUserService,
        private router: Router
    ) {}

    ngOnInit() {
        this.metaService.metaTagCaller('PartnerDe');

        try {
            this.partnerService.getAll().subscribe((data) => {
                this.r = data;
            });
        } catch (error) {
            console.error('Error occured receiving Observable in ngOnInit:', error);
        }

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.partnerEn]);
            }
        });
    }
}
