<div class="image-screen-container">
  <div class="image-screen-cover">
    <img src="/assets/skifahrenFam.jpg" alt="image broken" />
    <div class="flyin-carousel">
      <h1>Erlebnis am Berg</h1>
      <a>das ist ein flyin</a>
      <br/>
      <button type="button">Online-Buchen</button>
    </div>
  </div>
</div>

<div class="body-content">
  <h2>Online Rabatt in allen Wochen</h2>
  <ul>
    <li>-50% für Kinder bis zum 15. Lebensjahr</li>
    <li>-20% für Jugendliche bis zum 18. Lebensjahr</li>
    <li>-15% für Erwachsene</li>
  </ul>
  <p>
    Pro Elternteil, der die komplette Ausrüstung (SKI & Stöcke & Schuhe) für 6/7
    Tage mietet, ist 1 Kind unter 11 Jahre GRATIS
  </p>
  <p><strong>...auf die unten angegebenen Preise!</strong></p>

  <div>
    <h2>Economy-Class</h2>
    <p>Der ideale Ski für Allrounder, denn er ist fehlerverzeihend und griffig bei allen Schneeverhältnissen.</p>
    <p>Der Ski ist nicht mehr von der neuestesten Generation, aber selbstverständlich trotzdem in einem ausgezeichneten Zustand. Unserer Empfehlung nach bestens geeignet für Anfänger, die nicht unbedingt das neueste Material brauchen!</p>
    <h3>...unsere Shops</h3>
  </div>
  <div class="shop-container">
    <div class="card flex justify-content-center">
        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="false"></p-calendar>
    </div>
    <h4>SKI & Stöcke</h4>
    <ul>
        
        <li>1 Tag...  € 28</li>
        <li>2 Tage...  € 55</li>
        <li>3 Tage...  € 82</li>
        <li>4 Tage...  € 107</li>
        <li>5 Tage...  € 117</li>
        <li>6-7 Tage...  € 130</li>
    </ul>
    <h4>SKI & Stöcke & Schuhe</h4>
    <ul>
        <li>1 Tag...  € 39</li>
        <li>2 Tage...  € 76</li>
        <li>3 Tage...  € 113</li>
        <li>4 Tage...  € 143</li>
        <li>5 Tage...  € 159</li>
        <li>6-7 Tage...  € 179</li>
    </ul>
    <h4>SCHUHE</h4>
    <ul>
        <li>1 Tag...  € 11</li>
        <li>2 Tage...  € 21</li>
        <li>3 Tage...  € 31</li>
        <li>4 Tage...  € 36</li>
        <li>5 Tage...  € 42</li>
        <li>6-7 Tage...  € 49</li>
    </ul>
    <h4>HELM</h4>
    <ul>
        <li>1 Tag...  € 8</li>
        <li>2 Tage...  € 15</li>
        <li>3 Tage...  € 21</li>
        <li>4 Tage...  € 23</li>
        <li>5 Tage...  € 27</li>
        <li>6-7 Tage...  € 29</li>
    </ul>
</div>

</div>
