export enum SkiiraRouteJopchberg{
    none = '',
    areitexpress = 'areitexpress',
    boardkurse = 'snowboardschool-zellamsee-boardlesson',
    boardverleih = 'rent-a-ski-snowboard',
    buero = 'skischool-office',
    economy = 'rent-a-ski-basic',
    faq = 'information_faq',
    jochberg = 'jochberg',
    kinderskischule = 'skischool-zellamsee-skilesson',
    kitzbuehl = 'kitzbuehl',
    kontakt = 'contact',
    partner = 'information_partner',
    platin = 'rent-a-ski-premium',
    postplatz = 'postplatz',
    premium = 'rent-a-ski-economy',
    privatunterricht = 'skischool-zellamsee-private',
    schikurse = 'skischool-zellamsee',
    schmittentv = 'information_livetv',
    tourenski = 'rent-a-ski-tourenski',
    location = '',
}