<div class="gridContainer">
    <div class="gridItem">
        <img [src]="image1" alt="Image Description">
        <ng-content select="[text1]"></ng-content>
        <a [href]="button1href" class="button"><ng-content select="[button1content]"></ng-content></a>
    </div>
    <div class="gridItem">
        <img [src]="image2" alt="Image Description">
        <ng-content select="[text2]"></ng-content>
        <a [href]="button2href" class="button"><ng-content select="[button2content]"></ng-content></a>
    </div>
    <div class="gridItem">
        <img [src]="image3" alt="Image Description">
        <ng-content select="[text3]"></ng-content>
        <a [href]="button3href" type="button" class="button"><ng-content select="[button3content]"></ng-content></a>
    </div>
</div>
