import { Injectable } from "@angular/core";
import { Benutzer } from "../models";
import { RestService } from "./rest.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class BenutzerService extends RestService<Benutzer>{
  override entity = "benutzer";
  public isLoggedIn = false;
  public forgotPassword = false;
  public token = '';

  constructor(httpClient : HttpClient){
    super(httpClient);
    const localToken = localStorage.getItem('skiadmintoken');
    if (localToken && localToken !== null && localToken !== undefined){
      this.token = localToken;
      this.isLoggedIn = true;
    }
  }

  login(benutzernameOderEmail: string, passwort: string, verkaufsstelleId: string) : void {
    const payload = { benutzernameOderEmail, passwort, verkaufsstelleId };
    this.http.post<any>(`${this.getDefaultUrl()}/login`, payload).subscribe(r => {
      this.token = r.token;
      localStorage.setItem('skiadmintoken', this.token);
      this.isLoggedIn = true;
    });
  }

  resetPassword(email: string): Observable<any> {
    const payload = { email };
    return this.http.post<any>(`${this.getDefaultUrl()}/resetpassword`, payload);
  }
}