import { Injectable, PLATFORM_ID } from "@angular/core";
import { RestService } from "./rest.service";
import { Sprache } from "../models/sprache.model";
import { BehaviorSubject, Observable, map } from "rxjs";
import { ISprache, ISpracheAktiv } from "../interfaces";

@Injectable({providedIn: 'root'})
export class SpracheService extends RestService<Sprache> {
  override entity = 'sprache';
  private localStorageKey = 'skiira-selected-language';

  override getAll(): Observable<Sprache[]> {
    return super.getAll().pipe(
      map((sprachen: Sprache[]) => {
        return sprachen.sort((a, b) => a.bezeichnung.localeCompare(b.bezeichnung));
      })
    )
  }
  private _languages = new BehaviorSubject<ISprache[]>([]);
  private _activeLanguage = new BehaviorSubject<ISpracheAktiv[]>([]);
  public readonly activeLanguage$: Observable<ISpracheAktiv[]> = this._activeLanguage.asObservable();
  public readonly language$: Observable<ISprache[]> = this._languages.asObservable();
  

public storeLanguages() {
    return this.getAll().subscribe( (sprachen: ISprache[]) => {
      this._languages.next(sprachen);
    })
  }

  setInitSprachenAktiv() {
    this.getAll().pipe(
      map((sprachen: ISprache[]) => {
        this._activeLanguage.next(sprachen.map((sprache) => ({...sprache, aktiv: false} )));
    })
  )}

  public setLanguage(language: ISprache) {
    this._activeLanguage.next(this._activeLanguage.value.map((sprache) => {
      if (sprache.id === language.id) {
        return {...sprache, aktiv: true};
      }
      return {...sprache, aktiv: false};
    }));
  }

  
}
