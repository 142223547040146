<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>


<skiira-textbox>
  <h1 headline>FAQ</h1>
  <ng-container headline2>Hier finden Sie die häufigsten Fakten, Fragen & Antworten die Sie interessieren könnten.</ng-container>
  <ng-container left></ng-container>
  <ng-container right></ng-container>
</skiira-textbox>

<div class="container">
  <div class="content-row">
    <br><b>Welche Zahlungsmittel werden akzeptiert?</b><br>
    Nach erfolgreicher Onlinebuchung und Prüfung der Verfügbarkeit erhalten Sie innerhalb von 24 Stunden eine Buchungsbestätigung mit den akzeptierten Zahlungsmöglichkeiten und erforderlichen Details, um eine Überweisung zu tätigen. 
    Erst nach der Überweisung der vollständigen Summe auf unser Konto ist die Buchung endgültig reserviert und verbindlich. 
    Das Stornieren einer Buchung bis 7 Tage vor dem Anreisetermin ist kostenlos und der volle Betrag wird zurück überwiesen, weniger als 7 Tage vor dem Anreisetermin werden 100% verrechnet!
    <br><br><b>Wann beginnt ein Gruppenkurs?</b><br>
    Kursbeginn für Kinder ist grundsätzlich täglich möglich außer Samstag.
    <br><br><b>Wie lange dauert ein Gruppenkurs?</b><br>
    Ganztageskurse dauern von 10:00 Uhr - 12:00 Uhr und von 13:00 Uhr - 15:00 Uhr, inbegriffen ist eine Stunde Mittagspause. Halbtageskurse dauern von 10:00 Uhr - 12:00 Uhr.
    <br><br><b>Besteht die Möglichkeit einer Mittagsbetreuung?</b><br>
    Mittagsbetreuung für Kinder ist jederzeit möglich. Es kostet € 13,- pro Kind pro Tag (Essen, Getränk & Mittagsbetreuung). Die Mittagsbetreuung wird für Sie direkt in unserem Büro in der Lobby im Hotel Kempinski das Tirol bei der Anmeldung organisiert.  
    Ansonsten ist das Kind in der Mittagspause von einem Elternteil abzuholen.
    <br><br><b>Ganztageskurs oder Halbtageskurs für Kinder?</b><br>
    In der Regel fahren wir bei Kindern ab 4 Jahren grundsätzlich ganztägig. Halbtageskurse empfehlen wir bei Kindern zwischen 3 - 4 Jahre, sollte es jedoch gut klappen können Sie jederzeit auf ganztags um die Differenzsumme vor Ort verlängern. 
    Sollte es wider Erwarten gar nicht klappen wird der nicht genutzte Kurs selbstverständlich zurück erstattet!
    <br><br><b>In welchen Sprachen findet ein Gruppenkurs statt?</b><br>
    Alle unsere Lehrer sprechen Deutsch und Englisch. Wir haben eine Menge Lehrer mit Fremdsprachenkenntnisse und teilen sie zuerst für gebucht Privatstunden ein, wenn gefordert, dann zum Kinder Anfängerunterricht und so weiter. 
    Es gibt keine Garantie einen Lehrer außerhalb der Sprachen Deutsch und Englisch zu bekommen, aber wir geben natürlich unser Bestes damit es möglich ist. Alle anderen Sprachwünsche versuchen wir bei Bedarf zu erfüllen, doch geht der Sprachwunsch bei einer Privatbuchung vor dem einer Gruppenbuchung!
    <br><br><b>In welchen Sprachen findet Privatunterricht statt?</b><br>
    Alle unsere Lehrer sprechen Deutsch und Englisch! Profitieren Sie von Skilehrerinnen und -lehrern mit Fremdsprachkenntnissen anderer Sprachen, wie zum Beispiel: 
    Holländisch, Flämisch, Schwedisch, Dänisch, Italienisch, Französisch, Spanisch, Portugiesisch, Kroatisch, Slowenisch, Bulgarisch, Russisch, Tschechisch, die Sie aber im Voraus reservieren müssen. 
    Wir empfehlen frühzeitig online zu buchen, weil viele Stammgäste lange im Voraus ihre Privatlehrerinnen und -lehrer reservieren lassen! Wir vergeben unsere Lehrer nach Buchungseingang und Datum!	
    <br><br><b>Warum sollte ich Online buchen?</b><br>
    Der große Vorteil einer Onlinebuchung ist, dass Sie den wöchentlichen Rabatt auf alle Kurse und Verleih bekommen. Doch vielmehr sichern Sie sich damit einen fixen Platz in unserer Skischule und beim Skiverleih, vor allem in den Hauptsaisonwochen! 
    Wir nehmen immer so viele Gäste wie unserer Kapazität und Leistungsvorstellung entspricht! Wir schließen unsere Büros sobald wir voll sind. So kann es sein, dass Sie vor Ort keinen Platz mehr bekommen.
    <br><br><br><br>
  </div>
</div>

<skiira-footer></skiira-footer>
