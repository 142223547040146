<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>


<skiira-textbox>
    <h1 headline>Events - Teambuilding und Abenteuer in den Alpen</h1>
    <ng-container headline2>Unvergessliche Events der Snow-Academy in Zusammenarbeit mit unseren Partnern</ng-container>
    <ng-container left>Erlebnis, Abenteuer und Spaß erwarten Sie bei unseren Events. Gerne helfen wir Ihnen in Zusammenarbeit mit unseren Partnern </ng-container>
    <ng-container right>bei der Organisation Ihres maßgeschneiderten Events. Ob Firmen- oder Privatevent, alles ist möglich.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/privatunterricht1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/privatunterricht2_kl.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/privatunterricht3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
</skiira-picture-grid>

<skiira-textbox>
    <ng-container left>
        <ul>
            <li>Teambuilding aller Art</li>
            <li>Winterolympiade mit oder ohne Musikbegleitung und Moderation</li>
            <li>Organisation von verschiedenen Skirennformaten für alle Können Stufen</li>
            <li>Landing Airbag</li>
            <li>Party am Berg</li>
            <li>Verschiedene Locations von exklusiv bis urig</li>
            <li>Rodellabend mit Grillen am Berg</li>
            <li>Firmenfeiern am Berg</li>
        </ul>
    </ng-container>
    <ng-container right>
        <ul>
            <li>Eisstock oder Curling Turnier</li>
            <li>Fackelwanderungen</li>
            <li>Pferdeschlittenfahrten</li>
            <li>Heißluftballonfahrten</li>
            <li>Paragleiten</li>
            <li>Biathlon</li>
            <li>Eislaufen</li>
            <li>Moonbikes</li>
        </ul>
    </ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Events, die Eindrücke hinterlassen</ng-container>
    <ng-container left>Die Veranstaltungen der Snow-Academy sind mehr als nur Events – sie sind Erlebnisse, die Eindrücke hinterlassen und den Teamgeist stärken. Von sportlichen Wettbewerben über Adrenalin-Abenteuer bis hin zu festlichen </ng-container>
    <ng-container right>Feiern bieten wir eine Vielfalt von Möglichkeiten, um Ihr Event zu einem unvergesslichen Highlight zu machen. Lassen Sie uns gemeinsam die Alpenlandschaft erkunden und unvergessliche Erinnerungen schaffen.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>