import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    ApplicationService,
    DomainMetaTagNames,
    EndUserService,
    ISprache,
    MetaTag,
    SkiiraLocations,
} from '@skiira/core';
import { ImageService, MetaTagsService } from '@skiira/core';
import { Meta } from '@angular/platform-browser';
import { SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';
import { LocationEnComponent } from '../englisch/location-en.component';

@Component({
    selector: 'skiira-location-de',
    templateUrl: './location-de.component.html',
    styleUrls: ['./location-de.component.scss'],
})
export class LocationDeComponent implements OnInit {
    domain: string | undefined = undefined;
    imageObject: any | null = null;
    imageObjectSub: any | null = null;
    scrollEvent = false;
    cashdeskHover = false;
    socialHover = false;
    skiiraRoutes = SkiiraRouteStanglwirt;
    skiiraLanguage = SkiiraLanguage.german;

    metaTag: MetaTag | null = null;
    location = '';

    constructor(
        private route: ActivatedRoute,
        private metaTagService: MetaTagsService,
        private appService: ApplicationService,
        private endUserService: EndUserService,
        private router: Router
    ) {}
    ngOnInit(): void {
        console.log('location-de.component.ts: ngOnInit()');
        this.metaTagService.metaTagCaller('LocationDe');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {

                this.router.navigate([this.skiiraRoutes.locationEn], { skipLocationChange: false, replaceUrl: false });
              }
        });
    }
    /*
    this.domain = await this.appService.getCurrentDomain();
    console.log('this.domain: ' + this.domain);

    const queryParam = this.route.snapshot.queryParamMap.get('image');
    if (this.domain != '' || this.domain != undefined){
        for (const loc of Object.keys(DomainMetaTagNames)) {
          if (loc === this.domain) {
            console.log('executed location: ' + loc);
            this.imageObject = this.imageService.getImagesFromDomain(loc);
            this.location = loc;
            break;
          } else {
            this.imageObject = this.imageService.getImagesFromDomain('skischule-stanglwirt.com');
            this.location = 'skischule-stanglwirt.com';
            
          }
          if (queryParam) {
          this.imageObjectSub = this.imageService.getImages(queryParam);
          }
        }
      } else {
        console.log('Doamin not found');
      }
        try {
          await this.metaTagService.getMetaTagsFromDomain(this.domain).then((metaName) => {
            if (metaName != null) {
              console.log('metaName: ', metaName);
              this.metaTagService.resolveMetaTags(metaName);
            } else {
              console.log('metaName is null');
            }
          });
          
        } catch (error) {
          console.log('error: ', error);
        }      
    */

    clickCashdeskHover() {
        console.log('cashdesk: ' + this.cashdeskHover);
        this.cashdeskHover = !this.cashdeskHover;
    }

    clickSocialHover() {
        this.socialHover = !this.socialHover;
    }

    toogleCashdeskHover(value: boolean) {
        this.cashdeskHover = value;
        if (this.socialHover) {
            this.socialHover = false;
        }
    }

    toogleSocialMedia(value: boolean) {
        this.socialHover = value;
        if (this.cashdeskHover) {
            this.cashdeskHover = false;
        }
    }

    scrollingButtons1(scrollEvent: number) {
        if (scrollEvent > 500) {
            this.scrollEvent = true;
        } else {
            this.scrollEvent = false;
        }
    }
}
