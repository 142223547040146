import { Pipe, PipeTransform } from '@angular/core';
import { IKursTyp } from '../interfaces';

@Pipe({
  name: 'kursTypById'
})
export class KursTypByIdPipe implements PipeTransform {
  transform(id: string, kursTypList: IKursTyp[]): string {
    if (!kursTypList || !id) {
      return '';
    }

    const typ = kursTypList.find(t => t.id === id);
    return typ ? typ.typ : '';
  }
}
