import { Component, OnInit, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../enums/skiira.language.enum';
import { Router } from '@angular/router';

@Component({
    selector: 'skiira-imprint-de',
    templateUrl: './imprint-de.component.html',
    styleUrls: ['./imprint-de.component.scss'],
})
export class ImprintDeComponent implements OnInit {
    isGerman: boolean = true;
    skiiraLanguage: SkiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;
    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('ImprintDe');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.imprint]);
            }
        });

        /*
        this.isGerman = this.endUserService.getCurrentLanguage(this.skiiraLanguage);
        //Wenn wir einen Behaviour Subject subscriben bekommen wir den Change der Sprache mit und können den User auf die Entsprechende Seite redirecten
        if (!this.isGerman) {
          this.router.navigate([this.skiiraRoutes.imprint]);
        }
        */
    }
}
