<div class="headerImage"><img src="../../../../../assets/rennlauf.jpg" /></div>


<skiira-textbox>
    <h1 headline>Rennlauf-Training für Kinder und Erwachsene</h1>
    <ng-container headline2>Vom Anfänger bis zum Profi</ng-container>
    <ng-container left>Der Rennlauf ist eine fesselnde Facette des Skisports, die nicht nur Schnelligkeit, sondern auch Technik, Präzision und Ausdauer erfordert. Vom Nachwuchsfahrer bis zum erfahrenen Erwachsenen </ng-container>
    <ng-container right>bietet das Rennlauf-Training die Möglichkeit, sich in einem wettbewerbsorientierten Umfeld zu entwickeln und seine Fähigkeiten auf der Piste auf das nächste Level zu bringen.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Für Kinder:</b><br>Die Grundlage für zukünftige Champions<br>Junge Skifahrer, die von der Geschwindigkeit und dem Wettbewerbsgeist des Rennlaufs fasziniert sind, finden im Rennlauf-Training eine hervorragende Gelegenheit, ihre Technik und Selbstsicherheit zu fördern. Rennlauf-Training für Kinder konzentriert sich auf die Entwicklung solider Grundlagen, einschließlich Körperhaltung, Kurventechnik und Slalomfahren. Dies legt den Grundstein für eine erfolgreiche Rennkarriere und fördert gleichzeitig wichtige Fähigkeiten wie Disziplin, Durchhaltevermögen und Teamgeist. Kinder werden von erfahrenen Trainern betreut, die nicht nur auf technische Aspekte eingehen, sondern auch einen spielerischen Ansatz wählen, um das Lernen unterhaltsam zu gestalten. Dies hilft, das Interesse der Kinder am Rennlauf zu wecken und gleichzeitig ihre technischen Fertigkeiten zu verfeinern.</ng-container>
    <ng-container right><b>Für Jugendliche:</b><br>Technik, Strategie und Wettkampfgeist<br>Mit dem Fortschreiten der Rennlauf-Karriere werden die Jugendlichen in immer anspruchsvollere Techniken und Strategien eingeführt. Sie erlernen fortgeschrittene Techniken in verschiedenen Disziplinen wie Slalom, Riesenslalom und Super-G. Zudem wird ein stärkerer Fokus auf Wettkampf und Taktik gelegt, während sie sich auf regionale und nationale Wettkämpfe vorbereiten. Jugendliche werden durch gezielte Übungen, Videoanalysen und Wettkampfsimulationen unterstützt, um ihre Fähigkeiten zu verfeinern und sich auf eine Vielzahl von Rennbedingungen vorzubereiten. Gleichzeitig werden Werte wie Fairness, Respekt und Teamarbeit gefördert, um die Persönlichkeitsentwicklung zu unterstützen.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Für Erwachsene:</b><br>Herausforderung und Leidenschaft<br>Der Rennlauf ist nicht nur den jungen Generationen vorbehalten. Im Erwachsenenalter bietet das Rennlauf-Training eine Möglichkeit, die eigenen Fähigkeiten herauszufordern und die Leidenschaft für den Skisport auf eine neue Ebene zu heben. Erwachsene erhalten die Gelegenheit, ihre Technik zu verfeinern, ihre Grenzen zu testen und an Meisterschaften und Amateurrennen teilzunehmen. Das Rennlauf-Training für Erwachsene fördert nicht nur die physische Fitness und Technik, sondern bietet auch eine Chance, sich in einer Gemeinschaft von Gleichgesinnten zu engagieren, die die Liebe zum Skifahren teilen.</ng-container>
    <ng-container right><b>Fazit:</b><br>Mehr als nur Geschwindigkeit<br>Rennlauf-Training ist eine Bereicherung für Skifahrer jeden Alters und Niveaus. Es geht nicht nur um Geschwindigkeit auf der Piste, sondern um das Streben nach kontinuierlicher Verbesserung, die Entwicklung wichtiger Fähigkeiten und den Spaß am Wettbewerb. Von der Entwicklung solider Grundlagen bis hin zur Teilnahme an Wettkämpfen bietet der Rennlauf eine Reise des Lernens, der persönlichen Entwicklung und der Leidenschaft, die weit über die Pisten hinausgeht.</ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/rennlauf.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Preise Rennlauftraining</h2>
        <p beschreibung><li><b>Rennlauf-Training - 3 Stunden</b></li>
                        von 09:00 - 12:00 Uhr
                        <br>
                        <b><font color="#b68b1c">€ 255,00</font></b><br>
                        jede weitere Person...  € 30,00
        </p>
        <p beschreibung><li><b>Rennlauf-Training - 3 Stunden</b></li>
                        von 13:00 - 16:00 Uhr
                        <br>
                        <b><font color="#b68b1c">€ 230,00</font></b><br>
                        jede weitere Person...  € 30,00
        </p>
        <p beschreibung><li><b>Rennlauf-Training - ganzer Tag</b></li>
                        ...zur freien Verfügung!
                        <br>
                        <b><font color="#b68b1c">€ 395,00</font></b><br>
                        jede weitere Person...  € 30,00
        </p>
</skiira-werbecontainer>

<skiira-footer></skiira-footer>