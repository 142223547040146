import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SkiiraRouteStanglwirt, EndUserService, ISprache } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-touren-ski-deutsch',
    templateUrl: './touren-ski-deutsch.component.html',
    styleUrls: ['./touren-ski-deutsch.component.scss'],
})
export class TourenSkiDeutschComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.english;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.tourenskiEn]);
            }
        });
    }
}
