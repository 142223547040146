<p-carousel [value]="items" [circular]="true">
    <ng-template let-item pTemplate="item">
        <div class="image">
            <div class="image-carousel" [ngStyle]="{'background-image': 'url(' + item.src + ')'}"></div>
        </div>
        <div class="flyin-carousel">
            <h1>Snow-Academy im 5-Sterne Hotel Stanglwirt</h1>
            <a>Ihre perfekte Ski-Erfahrung</a>
            <button type="button" (click)="buchenRoute()">Online-Buchen</button>
        </div>
    </ng-template>
</p-carousel>


