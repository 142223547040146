<div class="headerImage"><img src="../../../../../assets/freeride-touren.jpg" /></div>


<skiira-textbox>
    <h1 headline>Freeride & Touren</h1>
    <ng-container headline2>Die Faszination des Abenteuers abseits der Pisten</ng-container>
    <ng-container left><b>Freeride und Skitouren:</b><br>Die Faszination des Abenteuers abseits der Pisten. Für viele Skifahrer und Snowboarder geht es beim Wintersport nicht nur um gut präparierte Pisten, sondern um das Abenteuer abseits der ausgetretenen Pfade. Freeride und Skitouren bieten die Möglichkeit, unberührte Schneehänge zu erkunden, die Natur in ihrer ursprünglichen Schönheit zu erleben und das Gefühl von Freiheit und Abenteuer inmitten der verschneiten Berglandschaft zu genießen. Freeriden, auch bekannt als Tiefschneefahren, ist eine Disziplin, die den Nervenkitzel des Abfahrens unberührter Hänge in den Vordergrund stellt. Abseits der präparierten Pisten geht es darum, den natürlichen Schnee zu meistern und die Herausforderungen des Geländes zu bewältigen. Die Wahl der richtigen Linie, die Interpretation des Geländes und die Beherrschung der Techniken wie Powder-Turns tragen zum Erfolg und zur Sicherheit beim Freeriden bei. Sicherheit steht dabei an erster Stelle. Das Tragen von Lawinenausrüstung wie LVS-Geräten (Lawinenverschüttetensuchgeräten), Lawinensonden und Schaufeln sowie das Verständnis für Lawinengefahr und Geländebeurteilung sind unerlässlich. Die Snow- Academy Stanglwirt bietet Freeride-Guides an, die die besten Stellen kennen und wertvolle Tipps für ein sicheres Abenteuer abseits der Pisten geben.
        </ng-container>
    <ng-container right><b>Skitouren:</b><br>Die Verbindung von Aufstieg und Abfahrt. Skitourenkurse erfreuen sich wachsender Beliebtheit bei Wintersportlern, die das Beste aus beiden Welten genießen möchten: den Aufstieg durch unberührte Natur und die Abfahrt auf präparierten oder eigenen Spuren. Bei Skitouren geht es darum, selbst auf die Gipfel zu gelangen, sei es im Pulverschnee oder auf kompaktem Schnee. Es ist eine körperlich anspruchsvolle Aktivität, die jedoch mit einem atemberaubenden Panoramablick und der Befriedigung belohnt wird, die eigenen Grenzen zu überwinden. Skitouren erfordern spezielle Ausrüstung, einschließlich Tourenski, Felle (aufklebbare Haftfelle für den Aufstieg) und Bindungen, die zwischen Aufstiegs- und Abfahrtsmodus wechseln können. Erfahrene Skitourengeher nutzen oft Steighilfen, um den Aufstieg zu erleichtern. Die Abfahrt, ob im frischen Pulverschnee oder auf anspruchsvollen Hängen, ist das Highlight eines Skitourentages.
        </ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/powdern.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Preise Freeride & Touren</h2>
        <p beschreibung><li><b>Freeride & Touren - 3 Stunden</b></li>
                        von 09:00 - 12:00 Uhr
                        <br>
                        <b><font color="#b68b1c">€ 255,00</font></b><br>
                        jede weitere Person...  € 30,00
        </p>
        <p beschreibung><li><b>Freeride & Touren - 3 Stunden</b></li>
                        von 13:00 - 16:00 Uhr
                        <br>
                        <b><font color="#b68b1c">€ 230,00</font></b><br>
                        jede weitere Person...  € 30,00
        </p>
        <p beschreibung><li><b>Freeride & Touren - ganzer Tag</b></li>
                        ...zur freien Verfügung!
                        <br>
                        <b><font color="#b68b1c">€ 395,00</font></b><br>
                        jede weitere Person...  € 30,00
        </p>
</skiira-werbecontainer>
<skiira-textbox>
    <ng-container headline2>Die Verantwortung für die Natur und die Sicherheit</ng-container>
    <p left>Sowohl Freeriden als auch Skitouren bringen eine größere Nähe zur Natur mit sich. Die Achtung der Umwelt, das Vermeiden von Schäden an der Tierwelt und das Einhalten von lokalen Regelungen sind entscheidend, um diese Naturschönheit für zukünftige Generationen zu bewahren. Zusätzlich ist das Verständnis der Lawinengefahr und die richtige Vorbereitung auf unvorhergesehene Situationen von höchster Bedeutung, um das Abenteuer abseits der Pisten sicher zu gestalten.</p>
    <p right>Freeriden und Skitouren bieten eine ganz besondere Art des Wintersports, die nicht nur körperliche Herausforderung und Nervenkitzel mit sich bringt, sondern auch die Möglichkeit, die Magie der Berge in ihrer ursprünglichsten Form zu erleben. Indem man sich der Natur nähert, seine Grenzen erweitert und die Bedeutung der Sicherheit respektiert, kann jeder, der das Abenteuer sucht, in diesen Disziplinen eine unvergleichliche Erfüllung finden.</p>
</skiira-textbox>
<skiira-footer></skiira-footer>
