<div><div class="headerImage"><img src="../../../assets/buero.jpg" /></div></div>

<skiira-textbox>
  <h1 headline>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h1>
  <ng-container headline2>LR Snow Academies GmbH<br>Oberhausenweg 2b, 6373 Jochberg</ng-container>
</skiira-textbox>


<div class="container">
  <div class="content-row">
    <br><b>§ 1 Geltungsbereich der AGB</b><br>
    1.1 Für Geschäftsbeziehungen zwischen der Skischule SNOW-ACADEMY Stanglwirt (im Folgenden kurz: „Skischule“) und dem Vertragspartner (im Folgenden kurz: „Kunde“) gelten ausschließlich diese Allgemeinen Geschäftsbedingungen (im Folgenden kurz: „AGB“) in der jeweils gültigen Fassung. Die gültige Fassung der AGB richtet sich jeweils nach dem Zeitpunkt der betreffenden Angebotslegung durch den Kunden.
    <br><br>
    1.2 Die Skischule erbringt gegenüber dem Kunden Dienstleistungen im Bereich des Ski- und Schneesports. Davon umfasst sind unter anderem das Erteilen von Ski-, Snow-board- und Langlaufunterricht, das Führen und Begleiten auf Skitouren (iSd § 1 Abs 1 T-SSG 1995) sowie damit zusammenhängenden Tätigkeiten (im Folgenden kurz: „Kurse“). Umfasst sind auch allfällige Kinderbetreuungsleistungen und damit verbundene Leistungen (zB Verpflegung für Kinder). Diese AGB gelten für alle Dienstleistungen der Skischule in diesem Zusammenhang.
    <br><br>
    1.3 Von diesen AGB abweichende Bestimmungen gelten nur bei ausdrücklicher schriftlicher Vereinbarung zwischen der Skischule und dem Kunden. Widersprechende Geschäftsbedingungen des Kunden entfalten keine Wirkung auf die in Punkt 1.2 angeführten Geschäftsbeziehungen.
    <br><br><b>§ 2 Vertragsabschluss</b><br>
    <br><br>2.1 Ein Vertragsabschluss zwischen der Skischule und dem Kunden ist sowohl auf elektronischem Wege (Abgabe eines Angebotes über Online-Formular und Vertragsannahme durch Bestätigungs-E-Mail) als auch in den Geschäftsräumlichkeiten der Skischule möglich.
    <br><br>2.2 Das Absenden eines vollständig ausgefüllten Online-Formulars durch den Kunden stellt ein rechtlich bindendes Angebot an die Skischule zum Abschluss eines Vertrages über die von der Skischule angepriesenen Leistungen dar. Die Skischule übermittelt aufgrund eines solchen Angebotes eine Bestellbestätigung an den Kunden. Erst durch die Übermittlung dieser Bestellbestätigung kommt ein Vertragsverhältnis zwischen der Skischule und dem Kunden zustande.
    <br><br>2.3 Die Erklärung des Kunden gegenüber einem Mitarbeiter der Skischule, Leistungen der Skischule in Anspruch nehmen zu wollen, stellt ein rechtlich bindendes Angebot an die Skischule zum Abschluss eines Vertrages dar. Erst durch die Bezahlung der gewünschten Leistung und durch die Ausgabe eines Zahlungsbeleges kommt ein Vertragsverhältnis zwischen der Skischule und dem jeweiligen Kunden zustande.
    <br><br>2.4 Die Buchungsbestätigung und/oder der Zahlungsbeleg dienen als Beleg für die Inanspruchnahme der gebuchten Leistung und sind vom Kunden bei Kursbeginn dem jeweiligen Kursleiter vorzulegen. 
    <br><br>2.5 Die Skischule ist nicht verpflichtet, dem Kunden eine Ablehnung von Online Buchungen mitzuteilen. Wird das Angebot des Kunden von der Skischule nicht innerhalb einer Frist von 2 (zwei) Wochen ab Erhalt bestätigt, gilt das Angebot des Kunden jedenfalls als nicht angenommen. 
    <br><br><b>§ 3 Widerrufsrecht beim Vertragsabschluss über Telefon, Webformular oder E-Mail</b><br>
    <br><br>3.1 Bei den angebotenen Dienstleistungen handelt es sich um eine „Freizeitdienstleistung“ im Sinne des Fern- und Auswärtsgeschäfte-Gesetz (FAGG).
    <br><br>3.2 Für Freizeitdienstleistung besteht gemäß § 18 Abs 1 Z 10 FAGG kein Widerrufsrecht.
    <br><br><b>§ 4 Rücktrittsrecht </b><br>
    <br><br>4.1 Der Kunde ist berechtigt, zu den nachgenannten Bedingungen schriftlich (E-Mail ist aus-reichend) vom Vertrag einseitig zurückzutreten.
    <br><br>4.2 Bei einer Buchung wird Ihnen Ihre Buchungssumme bis 7 Tage vor dem Anreisetermin anstandslos zurückbezahlt. Bei weniger als 7 Tage vor dem Anreisetermin verfällt das Rückzahlungsrecht!
    <br><br>4.3 Maßgeblich für die Fristwahrung ist jeweils das Einlangen des Rücktrittsschreibens bei der Skischule. Zur Wahrung der jeweiligen Frist ist es erforderlich, dass das Rücktrittsschreiben bis spätestens 24:00h vor Fristablauf bei der Skischule eingeht. Übermittlungsfehler und ähnliches gehen zu Lasten des Kunden.
    <br><br>4.4 In allen anderen Fällen ist der Kunde ohne ausdrückliche, schriftliche Zustimmung der Skischule nicht zum Rücktritt berechtigt und hat das volle Entgelt zu entrichten. Dies gilt insbesondere auch für den Fall des Nichterscheinens oder des verspäteten Erscheinens zum vereinbarten Termin.
    <br><br>4.5 Die Skischule ist berechtigt, jederzeit vom Vertrag zurückzutreten, wenn die Teilnahme des Kunden an Kursen unter Alkohol-, Drogen- oder Medikamenteneinfluss erfolgt, die eine sichere Teilnahme nicht mehr gewährleisten lassen. Gleiches gilt, wenn sich der Kunde beharrlich den Anweisungen der Skischule, der Lehrkräfte oder der Betreuungspersonen widersetzt (siehe Punkt 9.4). Dem Kunden stehen im Falle einer solchen Vertragsauflösung keine Ansprüche zu; er ist insbesondere auch zur Zahlung des vollen Entgelts verpflichtet.
    <br><br><b>§ 5 Unmöglichkeit der Leistung</b><br>
    <br><br>5.1 Wenn die die Durchführung der Dienstleistung aus Sicherheitsgründen (zB Witterungsverhältnisse, Lawinengefahr, etc) nicht möglich ist, ist die Skischule nicht zur Leistungserbringung verpflichtet. Es obliegt allein dem billigen Ermessen der Skischule, die Un-möglichkeit der Leistungserbringung zu beurteilen, wobei eine teilweise Unmöglichkeit – zB an drei von fünf Tagen ist kein Skiunterricht möglich – die Durchführung des mögli-chen Leistungsteils unberührt lässt.
    <br><br>5.2 Im Falle der (teilweisen oder gänzlichen) Leistungsunmöglichkeit gemäß Punkt 5.1 wird die Skischule das anteilige Entgelt an den Kunden zurückzahlen. Ein darüberhinausge-hender Anspruch steht dem Kunden nicht zu.
    <br><br>5.3 Höhere Gewalt, insbesondere Epidemien, Pandemien, behördliche Maßnahmen wie Schließungen, sonstige unvorhersehbare und unabwendbare Ereignisse befreien die Skischule von ihren Leistungspflichten. 
    <br><br>5.4 Im Falle der (teilweisen oder gänzlichen) Leistungsunmöglichkeit gemäß Punkt 5.3 stellt die Skischule über das anteilige Entgelt entweder eine Gutschrift aus oder bezahlt das anteilige Entgelt an den Kunden zurück. Diesbezüglich steht dem Kunden ein Wahlrecht zu. Ein darüber hinausgehender Anspruch steht dem Kunden nicht zu. Ein allfälliges Rücktrittsrecht gemäß § 10 Abs 2 Pauschalreisegesetz bleibt unberührt.
    <br><br><b>§ 6 Preise, Zahlungsmodalitäten</b><br>
    <br><br>6.1 Sämtliche Angaben – insbesondere Preislisten der Skischule im Internet, in Prospekten, Anzeigen oder sonstigen Informationsträgern – sind für die Skischule unverbindlich. Die Skischule behält sich vor, jederzeit Änderungen vorzunehmen. 
    <br><br>6.2 Sämtliche Preisangaben sind in EURO (€) und verstehen sich brutto inklusive einer allfälligen gesetzlichen Umsatzsteuer, sofern nichts anderes angegeben ist.
    <br><br>6.3 Kosten für Skitickets oder Skiausrüstung sind nicht von den Kursgebühren umfasst. Diese sind vom Kunden auf eigene Rechnung zu erwerben und mitzubringen.
    <br><br>6.4 Der Entgeltanspruch der Skischule gegenüber dem Kunden entsteht mit Vertragsabschluss. Zu diesem Zeitpunkt wird die Zahlung fällig. Bei Online-Buchungen erfolgt die Zahlung allenfalls jedoch schon unmittelbar bei Angebotslegung mittels einer der für Online-Buchungen eingerichteten Zahlungsmethoden. Sollte das Angebot des Kunden von der Skischule nicht angenommen werden, wird ein bereits geleisteter Betrag im Wege der gleichen Zahlungsmethode, wie sie der Kunde verwendet hat, zurückerstattet.
    <br><br>6.5 Im Falle anderer Buchungswege, z.B. per E-Mail oder direkt vor Ort, kann die Zahlung der Kursgebühr innerhalb von 7 Tagen ab Vertragsabschluss per Überweisung auf das Konto der Skischule oder bar vor Ort erfolgen; sie hat aber jedenfalls vor Kursbeginn bei der Skischule einzulangen. Sämtliche Spesen – insbesondere Bankspesen – in Ver-bindung mit der Bezahlung des bei der Skischule gebuchten Kurses gehen ausschließ-lich zu Lasten des Kunden. 
    <br><br>6.6 Der Kunde kann nur mit solchen Gegenforderungen aufrechnen, die rechtskräftig ge-richtlich festgestellt oder von der Skischule ausdrücklich anerkannt worden sind sowie im Falle der Zahlungsunfähigkeit der Skischule. Gesetzliche Zurückbehaltungsrechte werden von diesem Vertragspunkt nicht berührt. 
    <br><br>6.7 Zahlungsverzug tritt ohne weitere Mitteilung der Skischule ein. Für den Fall, dass der Kunde mit einer Zahlung in Verzug gerät, ist die Skischule berechtigt, die gesetzlichen Verzugszinsen und sämtliche zusätzlich anfallenden Kosten und Spesen, insbesondere Inkasso- oder Rechtsanwaltskosten, gegenüber dem Kunden zu verrechnen. Bei offenen Forderungen kann die Skischule vom Kunden getätigte Zahlungen ungeachtet einer allfälligen Widmung durch den Kunden beliebig auf dessen offene Forderungen anrechnen. Für den Fall der Nichtzahlung einer Forderung sind auch alle übrigen Forderungen gegen den Kunden sofort fällig. 
    <br><br>6.8 Erfüllungsort ist für alle sowohl von der Skischule als auch dem Kunden zu erfüllenden Pflichten der Ort des Sitzes der Skischule.
    <br><br><b>§ 7 Leistungserbringung</b><br>
    <br><br>7.1 Der Kunde hat sich zur Leistungserbringung am Sammelplatz der Skischule oder an einem sonst von der Skischule bekannt gegebenen Ort im Skischulgebiet rechtzeitig vor Kursbeginn einzufinden.
    <br><br>7.2 Die Skischule behält sich das Recht vor, den Treffpunkt der Kurse kurzfristig zu ändern. In diesen Fällen werden die Kunden von der Skischule informiert.
    <br><br>7.3 Die Skischule behält sich das Recht vor, vor Beginn eines jeden Skikurstages eine Fiebermessung unter Einhaltung der datenschutzrechtlichen Vorschriften bei jedem Kunden vorzunehmen und im Falle von erhöhter Temperatur, Fieber oder sonstigen deutlichen Anzeichen auf ansteckende Krankheiten (z.B.: COVID-19), die ein Risiko für andere Skikursteilnehmer sowie für Lehrkräfte und Betreuungspersonen darstellen können, nach eigenem Ermessen den Kunden vom Unterricht auszuschließen. Der Kunde hat in diesen Fällen nach seiner Wahl entweder Anspruch auf eine anteilige Rückerstattung eines bereits geleisteten Entgelts oder auf Ausstellung einer entsprechenden Gutschrift.
    <br><br>7.4 Die Skischule verpflichtet sich, jeweils nur für die jeweilige Leistung qualifizierte Lehr- oder Kinderbetreuungspersonen einzusetzen.
    <br><br><b>§ 8 Haftungsbeschränkung</b><br>
    <br><br>8.1 Im Zusammenhang mit den angebotenen Kursen wird seitens der Skischule keine Ga-rantie für den Ausbildungserfolg der Kursteilnehmer übernommen. 
    <br><br>8.2 Die Skischule haftet – mit Ausnahme von Personenschäden – nicht für Schäden, soweit die Schäden nicht auf einem vorsätzlichen oder grob fahrlässigen Verhalten der Skischule selbst oder einer ihr zurechenbaren Person beruhen und das schadensverursachende Verhalten nicht die aus dem abgeschlossenen Vertrag treffenden Haupflichten betrifft. 
    <br><br>8.3 Die Skischule übernimmt keine Haftung für Schäden, die der Kunde während der Durchführung der vereinbarten Leistung ohne Verschulden der Skischule sich selbst oder anderen Personen zufügt oder durch diese ihm zugefügt werden. 
    <br><br>8.4 Unabhängig vom Verschulden haftet die Skischule nicht für entgangenen Gewinn, reine Vermögensschäden und Folgeschäden, soweit diese das 3-fache Leistungsentgelt übersteigen. 
    <br><br>8.5 Der Kunde wird darauf hingewiesen, dass das Nicht-Tragen eines Sturzhelmes im Falle von Verletzungen ein Mitverschulden des Kunden begründen kann, weshalb dem Kunden empfohlen wird, einen Sturzhelm sowie weitere für die jeweils gebuchte Leistung empfohlene Sicherheitsausrüstung (z.B. Lawinensuchgerät bei Fahrten im freien Gelände) zu tragen bzw im Falle von Lawinenausrüstung, mitzuführen. Sturzhelme und Sicherheitsausrüstung verringern in der Regel das Verletzungsrisiko. 
    <br><br>8.6 Es wird ausdrücklich festgehalten, dass die Ausübung von Schneesport mit zahlreichen Risiken verbunden ist und insbesondere bei Touren oder Abfahrten im freien Gelände ein erhöhtes Risiko für Verletzungen oder sogar Tod, insbesondere auch durch Lawinen, besteht, das nicht gänzlich ausgeschlossen werden kann.
    <br><br>8.7 Der Kunde nimmt zur Kenntnis, eine Rettung von Pisten oder im freien Gelände oftmals mit hohen Kosten verbunden ist. Dem Kunden wird deshalb empfohlen eine entsprechende Versicherung abzuschließen, zumal die Skischule nicht für Bergungs- und Flugrettungskosten haftet, es sei denn, die Skischule oder eine ihr zurechenbare Person hat diese Bergungs- und/oder Flugrettungskosten durch vorsätzliches oder grob fahrlässiges Verhalten verursacht.
    <br><br><b>§ 9 Pflichten des Kunden</b><br>
    <br><br>9.1 Der Kunde hat die Skischule über seine Fähigkeiten und Erfahrungen in der jeweils ge-buchten Schneesportart wahrheitsgemäß und umfassend aufzuklären sowie selbständig für eine dem Stand der Skitechnik und den äußeren Bedingungen entsprechende Ausrüstung Sorge zu tragen. Der Kunde hat der Skischule über allfällige gesundheitliche Leiden oder Beeinträchtigungen zu berichten. 
    <br><br>9.2 Weiters verpflichtet sich der Kunde bei fieberhaften Infekten, ansteckenden Krankheiten sowie bei Erkrankungen, die mit Durchfall und Erbrechen einhergehen, nicht am Skikurs teilzunehmen. Insbesondere bei Auftreten von COVID-19 Symptomen (Fieber, trockener Husten, Müdigkeit, Atembeschwerden etc.) verpflichtet sich der Kunden von einer Teilnahme am Skikurs Abstand zu nehmen. Sofern der Kunde einen Gruppenkurs gebucht hat, kann er bei Vorlage eines ärztlichen Attests das in Punkt 4.4 dieser AGB vorgesehene Rücktrittsrecht in Anspruch nehmen. 
    <br><br>9.3 Vor Beginn des Unterrichts ist durch den Kunden selbständig die Überprüfung seiner Skiausrüstung (insbesondere Skibindung) durch einen Fachbetrieb zu veranlassen.
    <br><br>9.4 Anweisungen der Skischule, der Lehrkräfte und der Betreuungspersonen hat der Kunde zu befolgen. Missachtungen von Anweisungen und Ermahnungen berechtigen die Skischule umgehend zur Vertragsauflösung. Dies gilt auch, wenn Kunden ein ungebührliches Verhalten, insbesondere gegenüber anderen Kursteilnehmern, an den Tag legen.
    <br><br><b>§ 10 Datenschutzerklärung</b><br>
    <br><br>10.1 Nähere Informationen sind der Datenschutzerklärung der Skischule, welche unter <a href="" target="_self">https://www.snowacademy-stanglwirt.com/data-protection.php?lang=deu</a> abrufbar ist, zu entnehmen.
    <br><br><b>§ 11 Sonstige Bestimmungen</b><br>
    <br><br>11.1 Mündliche Nebenabreden zu diesen AGBs bestehen nicht. Nebenabreden welcher Art auch immer, Änderungen oder Ergänzungen bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für das Abgehen von diesem Schriftformerfordernis.
    <br><br>11.2 Für sämtliche Streitigkeiten aus Rechtsgeschäften, denen diese AGB zugrunde liegen, gilt die Anwendbarkeit des materiellen österreichischen Rechts, unter Ausschluss des österreichischen internationalen Privatrechts, als vereinbart. 
    <br><br>11.3 Wenn der Kunde Unternehmer oder Konsument mit Wohnsitz außerhalb des Geltungsbereiches der EuGVVO oder des Lugano-Übereinkommens ist (das sind alle Länder mit Ausnahme der EU-Mitgliedsstaaten, der Schweiz, Norwegen und Island), wird für alle Rechtsstreitigkeiten aus oder in Zusammenhang mit diesen Allgemeinen Geschäftsbedingungen und/oder Verträgen zwischen der Skischule und dem Kunden über die Erbringungen von Skischul-Dienstleistungen das sachlich und örtlich zuständige Gericht am Sitz der Skischule als ausschließlich zuständiges Gericht vereinbart. 
    <br><br>11.4 Soweit der Kunde Konsument mit Wohnsitz innerhalb der EU oder des Geltungsbereiches des Lugano Übereinkommens ist, gelten die gesetzlichen Bestimmungen über die Gerichtszuständigkeit.
    <br><br>11.5 Sofern eine oder mehrere der Bestimmungen dieser AGBs nichtig ist/sind, gelten zwischen der Skischule und dem Kunden ausdrücklich solche rechtswirksame Bestimmungen als vereinbart, welche dem wirtschaftlichen Zweck der unwirksamen Bestimmung am nächsten kommen. Die Wirksamkeit der übrigen Bestimmungen wird durch eine nichtige Bestimmung nicht berührt. 
    <br><br>11.6 Sämtliche Rechte und Pflichten aus diesen AGB gehen auf allfällige Rechtsnachfolger der Skischule über.
  </div>
</div>

<skiira-footer></skiira-footer>
