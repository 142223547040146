<div>
    <img src="../../../../../assets/langlauf.jpg" style="width: 100%; height: 80%;" />
</div>

<skiira-textbox>
    <h1 headline>Biathlon - Eine Fusion aus Schießen und Langlauf</h1>
    <ng-container headline2>Wenn Präzision auf Ausdauer trifft</ng-container>
    <ng-container left>Der Biathlon, eine faszinierende Kombination aus Langlauf und Schießen, fesselt Sportbegeisterte weltweit. Diese einzigartige Disziplin verlangt nicht nur nach körperlicher Ausdauer, sondern auch nach höchster Präzision und Konzentration. Tauchen wir ein in die Welt des Biathlons und entdecken wir die spannenden Facetten dieses vielseitigen Sports.</ng-container>
    <ng-container right>Der Biathlon vereint zwei scheinbar gegensätzliche Elemente: den körperlich anspruchsvollen Langlauf und das präzise Schießen. Athleten legen beim Langlauf Strecken von unterschiedlicher Länge zurück, um dann im Schießstand Ruhe und Genauigkeit zu bewahren. Die Kombination aus intensiver körperlicher Anstrengung und ruhiger Konzentration macht den Biathlon zu einem einzigartigen Wettkampf.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/langlauf1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/langlauf2.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/langlauf3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
</skiira-picture-grid>

<skiira-textbox>
    <ng-container left><b>Die Herausforderung des Schießens</b><br>Das Schießen im Biathlon erfordert eine außergewöhnliche Kombination aus physischer Stabilität und mentaler Ruhe. Athleten müssen nach anstrengendem Langlauf zur Ruhe kommen und Zielsicherheit bewahren, um die kleinen Ziele auf 50 Meter Entfernung zu treffen. Die beiden Schießeinlagen, stehend und liegend, stellen unterschiedliche Anforderungen an die Sportler und testen ihre Fähigkeit, unter Druck präzise zu agieren.</ng-container>
    <ng-container right><b>Trainingsansatz und Vorbereitung</b><br>Biathleten folgen einem umfassenden Trainingsprogramm, das sowohl Ausdauer- als auch Schießübungen umfasst. Langlauftraining verbessert die aerobe Fitness und Ausdauer, während Schießübungen die Schussgenauigkeit unter stressigen Bedingungen fördern. Ein starker Fokus liegt auch auf mentaler Stärke, um die Konzentration während des Rennens aufrechtzuerhalten.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Wettkämpfe und Olympische Spiele</b><br>Der Biathlon ist eine olympische Disziplin, die die Aufmerksamkeit der Welt auf sich zieht. Wettkämpfe variieren in ihrer Länge und Anforderung, von Sprintrennen bis hin zu Verfolgungs- und Staffelwettkämpfen. Der Biathlon erfordert nicht nur körperliche und technische Fertigkeiten, sondern auch strategische Entscheidungen, wann und wie oft geschossen wird.</ng-container>
    <ng-container right><b>Faszination und Gemeinschaft</b><br>Der Biathlon hat eine breite Fangemeinde gewonnen, nicht nur wegen der spannenden Wettkämpfe, sondern auch wegen der einzigartigen Verbindung von Sport und Natur. Die Gemeinschaft der Biathleten teilt eine Leidenschaft für den Sport und ermutigt zur Zusammenarbeit und zur persönlichen Entwicklung.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Eine einzigartige Symbiose aus Kraft und Präzision</ng-container>
    <ng-container left>Durch die Verbindung von Langlauf und Schießen entsteht eine einzigartige Symbiose aus Kraft und Präzision. Egal ob als Zuschauer oder als Athlet, der Biathlon fasziniert und inspiriert gleichermaßen.</ng-container>
    <ng-container right>Der Biathlon ist mehr als nur ein Sport – er ist eine körperliche und mentale Herausforderung, die die Athleten an ihre Grenzen bringt.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>