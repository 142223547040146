import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { Kurs } from "../models/kurs.model";
import { KursBasis, KursModel } from "../models";
import { Observable, map } from "rxjs";

@Injectable({providedIn: 'root'})
export class KursService extends RestService<Kurs> {
  override entity = 'Kurs';

  
  createKursModel(data: KursModel): Observable<KursModel> {
    return this.http.post<KursModel>(`${this.getDefaultUrl()}/kursmodel`, data);
  }

  updateKursModel(id: string, data: KursModel): Observable<KursModel> {
    return this.http.put<KursModel>(`${this.getDefaultUrl()}/kursmodel/${id}`, data);
  }

  getAllKursModels() : Observable<KursModel[]>{
    return this.http.get<KursModel[]>(`${this.getDefaultUrl()}/GetAllKursModels`).pipe(map(r => {
      let result = new Array<KursModel>();
      r.forEach(item => {
        let km = item as KursModel;
        km.basis = new KursBasis(
          item.basis.id, 
          item.basis.kursId,
          item.basis.kursSerieId,
          new Date(item.basis.startdatum?.toString() || ''),
          item.basis.ticketAnzahlType,
          item.basis.ticketAnzahl,
          item.basis.gueltigkeit,
          item.basis.wochentage,
          item.basis.besucherWaehlenDauer,
          item.basis.maxDauerTage,
          item.basis.bisWochentag,
          item.basis.isDeleted);
        result.push(km);
      })
      return result;
    }));
  }

}
