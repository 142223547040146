import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { MetaTag } from '../interfaces';
import { Meta } from '@angular/platform-browser';
import { WebContentService } from './web-content.service';
import { DomainMetaTagNames } from '../enums';
import { firstValueFrom, from } from 'rxjs';
import { WebContent } from '../models';
import { isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class MetaTagsService {
    webContentName = '';

    constructor(
        @Inject(PLATFORM_ID) private platformObject: Object,
        private meta: Meta,
        private webContentService: WebContentService
    ) {}
    async getMetaTagsFromDomain(domain: string): Promise<MetaTag | null> {
        for (const iDomain of Object.keys(DomainMetaTagNames)) {
            if (iDomain === domain) {
                this.webContentName = DomainMetaTagNames[iDomain as keyof typeof DomainMetaTagNames];
                break;
            } else {
                this.webContentName = DomainMetaTagNames['default' as keyof typeof DomainMetaTagNames];
                console.log('else match' + domain);
            }
        }
        try {
            console.log('this.webContentName: ', this.webContentName);
            const webContent = await firstValueFrom<WebContent>(this.webContentService.getByName(this.webContentName));
            console.log('webContentMeta: ', webContent);
            const metaTags = JSON.parse(webContent.meta) as MetaTag;
            return metaTags;
        } catch (error) {
            console.log('error: ', error);
            return null;
        }
    }

    resolveMetaTags(metaTags: MetaTag | string) {
        if (isPlatformServer(this.platformObject)) {
            console.log('is platform server');
            if (typeof metaTags === 'string') {
                metaTags = JSON.parse(metaTags) as MetaTag;
            }

            this.meta.addTag({ name: 'title', content: metaTags.title });
            this.meta.addTag({ name: 'description', content: metaTags.description });
            this.meta.addTag({ name: 'keywords', content: metaTags.keywords });

            this.meta.addTag({ property: 'og:title', content: metaTags.ogTitle });
            this.meta.addTag({ property: 'og:description', content: metaTags.ogDescription });
            this.meta.addTag({ property: 'og:image', content: metaTags.ogImage });
            this.meta.addTag({ property: 'og:url', content: metaTags.ogUrl });
            this.meta.addTag({ property: 'og:site_name', content: 'Your Site Name' });

            this.meta.addTag({ name: 'twitter:card', content: 'summary' });
            this.meta.addTag({ name: 'twitter:title', content: metaTags.twitterTitle });
            this.meta.addTag({ name: 'twitter:description', content: metaTags.twitterDescription });
            this.meta.addTag({ name: 'twitter:image', content: metaTags.twitterImage });
            this.meta.addTag({ name: 'twitter:site', content: metaTags.twitterSite });
            this.meta.addTag({ name: 'twitter:creator', content: metaTags.twitterCreator });

            this.meta.addTag({ name: 'author', content: metaTags.author ?? 'Your Name' });
            this.meta.addTag({ name: 'robots', content: metaTags.robots ?? 'index, follow' });
            this.meta.addTag({ name: 'googlebot', content: metaTags.googlebot ?? 'index, follow' });
            this.meta.addTag({ name: 'bingbot', content: metaTags.bingbot ?? 'index, follow' });
        }
    
         else {
            
            if (typeof metaTags === 'string') {
                metaTags = JSON.parse(metaTags) as MetaTag;
            }

            this.meta.addTag({ name: 'title', content: metaTags.title });
            this.meta.addTag({ name: 'description', content: metaTags.description });
            this.meta.addTag({ name: 'keywords', content: metaTags.keywords });

            this.meta.addTag({ property: 'og:title', content: metaTags.ogTitle });
            this.meta.addTag({ property: 'og:description', content: metaTags.ogDescription });
            this.meta.addTag({ property: 'og:image', content: metaTags.ogImage });
            this.meta.addTag({ property: 'og:url', content: metaTags.ogUrl });
            this.meta.addTag({ property: 'og:site_name', content: 'Your Site Name' });

            this.meta.addTag({ name: 'twitter:card', content: 'summary' });
            this.meta.addTag({ name: 'twitter:title', content: metaTags.twitterTitle });
            this.meta.addTag({ name: 'twitter:description', content: metaTags.twitterDescription });
            this.meta.addTag({ name: 'twitter:image', content: metaTags.twitterImage });
            this.meta.addTag({ name: 'twitter:site', content: metaTags.twitterSite });
            this.meta.addTag({ name: 'twitter:creator', content: metaTags.twitterCreator });

            this.meta.addTag({ name: 'author', content: metaTags.author ?? 'Your Name' });
            this.meta.addTag({ name: 'robots', content: metaTags.robots ?? 'index, follow' });
            this.meta.addTag({ name: 'googlebot', content: metaTags.googlebot ?? 'index, follow' });
            this.meta.addTag({ name: 'bingbot', content: metaTags.bingbot ?? 'index, follow' });
        }
    }

    metaTagCaller(name: string) {
        if(isPlatformServer(this.platformObject)) {
            try {
                this.webContentService.getByName(name).subscribe((webContent) => {
                    const metaTags = JSON.parse(webContent.meta) as MetaTag;
                    this.resolveMetaTags(metaTags);
                });
            } catch (error) {
                console.error('Error occured receiving Observable in ngOnInit:', error);
            }

            console.log('metatagcaller is on platform server');
        } else {
            try {
                this.webContentService.getByName(name).subscribe((webContent) => {
                    const metaTags = JSON.parse(webContent.meta) as MetaTag;
                    this.resolveMetaTags(metaTags);
                });
            } catch (error) {
                console.error('Error occured receiving Observable in ngOnInit:', error);
            }
    
        }
    
    }
}
