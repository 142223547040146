import { Component, OnInit } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { SkiiraRouteStanglwirt } from '@skiira/core';
import { Router } from '@angular/router';

@Component({
  selector: 'skiira-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {

  skiiraRoutes = SkiiraRouteStanglwirt;
  items = [
    {
      type: 'image',
      src: '/assets/powdern.jpg',
      alt: 'First slide',
      headline: 'First slide headline',
    },
    {
      type: 'image',
      src: '/assets/skifahrenFam.jpg',
      alt: 'Second slide',
      headline: 'Second slide headline',
    },
    {
      type: 'image',
      src: '/assets/skifahrer.jpg',
      alt: 'Third slide',
      headline: 'Third slide headline',
    },
    {
      type: 'image',
      src: '/assets/skkinggulmarg.jpg',
      alt: 'Fourth slide',
      headline: 'Fourth slide headline',
    }
  ];

  constructor(private router: Router) {
    // vertical swipe event enabled
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    Carousel.prototype.onTouchMove = () => { };
}
buchenRoute(){
    this.router.navigate([SkiiraRouteStanglwirt.buchen]);
  }
  }
