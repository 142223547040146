import { Component, Input } from '@angular/core';

@Component({
  selector: 'skiira-picture-container-3row',
  templateUrl: './picture-container-3row.component.html',
  styleUrls: ['./picture-container-3row.component.scss'],
})
export class PictureContainer3rowComponent {
  @Input() image1: string | undefined;
  @Input() button1href: string | undefined;

  @Input() image2: string | undefined;
  @Input() button2href: string | undefined;

  @Input() image3: string | undefined;
  @Input() button3href: string | undefined;

}
