import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { WebContent } from "../models/web-content.model";
import { Observable } from "rxjs";


@Injectable({providedIn: 'root'})
export class WebContentService extends RestService<WebContent> {
    override entity = 'WebContent';

    getByName(name: string): Observable<WebContent> {
        return this.http.get<WebContent>(`${this.getDefaultUrl()}/name/${name}`)
    }
    }