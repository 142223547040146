<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>

  
  <skiira-textbox>
    <h1 headline>TERMS & CONDITIONS</h1>
    <ng-container headline2>LR Snow Academies GmbH</ng-container>
    <ng-container left><br>Oberhausenweg 2b<br>6373 Jochberg</ng-container>
  </skiira-textbox>
  
  <div class="container">
    <div class="content-row">
        
      <br><b>§ 1 Scope of Terms and Conditions</b><br>
      1.1 For business relationships between the ski school SNOW-ACADEMY Stanglwirt (hereinafter referred to as "Ski School") and the contracting party (hereinafter referred to as "Customer"), exclusively these General Terms and Conditions (hereinafter referred to as "AGB") in their respective valid version shall apply. The valid version of the AGB shall be determined by the time of the respective offer made by the Customer.
      <br><br>
      1.2 The ski school provides services in the field of skiing and snow sports to the Customer. This includes, among other things, the provision of ski, snowboard, and cross-country skiing instruction, as well as guiding and accompanying on ski tours (as defined in § 1 para. 1 T-SSG 1995), and related activities (hereinafter referred to as "Courses"). It also includes any childcare services and associated services (e.g., catering for children). These General Terms and Conditions (AGB) apply to all services provided by the ski school in this context.
      <br><br>
      1.3 Deviation from these AGB will only apply upon explicit written agreement between the ski school and the Customer. Conflicting terms and conditions of the Customer shall not have any effect on the business relationships specified in section 1.2.
      <br><br><b>§ 2 Contract Conclusion</b><br>
      <br><br>2.1 A contract between the ski school and the Customer can be concluded both electronically (by submitting an offer via an online form and acceptance of the contract through a confirmation email) and in the physical premises of the ski school.
      <br><br>2.2 The submission of a fully completed online form by the Customer constitutes a legally binding offer to the ski school for the conclusion of a contract regarding the services advertised by the ski school. The ski school, upon receiving such an offer, will send an order confirmation to the Customer. It is only through the transmission of this order confirmation that a contractual relationship is established between the ski school and the Customer.
      <br><br>2.3 The Customer's statement to a ski school employee expressing the intention to avail themselves of the ski school's services constitutes a legally binding offer to the ski school for the conclusion of a contract. It is only through the payment for the desired service and the issuance of a payment receipt that a contractual relationship is established between the ski school and the respective Customer.
      <br><br>2.4 The booking confirmation and/or the payment receipt serve as proof of the utilization of the booked service and must be presented by the Customer to the respective course instructor at the beginning of the course.
      <br><br>2.5 The ski school is not obligated to inform the Customer of the rejection of online bookings. If the ski school does not confirm the Customer's offer within a period of 2 (two) weeks from the receipt thereof, the Customer's offer is considered as not accepted.
      <br><br><b>§ 3 The right of withdrawal when concluding a contract via telephone, web form, or email</b><br>
      <br><br>3.1 The offered services are considered "leisure services" within the meaning of the Distance and Off-Premises Contracts Act (FAGG).
      <br><br>3.2 For leisure services, there is no right of withdrawal according to § 18 para. 1 clause 10 of the Distance and Off-Premises Contracts Act (FAGG).
      <br><br><b>§ 4 Right of withdrawal</b><br>
      <br><br>4.1 The Customer is entitled to unilaterally withdraw from the contract in writing (email is sufficient) under the following conditions.
      <br><br>4.2 With a booking, your booking amount will be refunded without any issues up to 7 days before the arrival date. If the cancellation occurs less than 7 days before the arrival date, the right to a refund expires.
      <br><br>4.3 The decisive factor for meeting the deadline is the receipt of the withdrawal letter by the ski school. To meet the respective deadline, it is necessary for the withdrawal letter to be received by the ski school no later than 24:00 hours before the deadline. Transmission errors and similar issues are the responsibility of the Customer.
      <br><br>4.4 In all other cases, the Customer is not entitled to withdraw without the explicit written consent of the ski school and must pay the full fee. This particularly applies to cases of non-appearance or late arrival at the agreed-upon appointment.
      <br><br>4.5 The ski school is entitled to withdraw from the contract at any time if the Customer participates in courses under the influence of alcohol, drugs, or medication that no longer ensure safe participation. The same applies if the Customer persistently disobeys the instructions of the ski school, instructors, or supervisors (see section 9.4). In the event of such contract termination, the Customer has no claims, and they are specifically obligated to pay the full fee.
      <br><br><b>§ 5 Impossibility of Performance</b><br>
      <br><br>5.1 If the provision of the service is not possible for safety reasons (e.g., weather conditions, avalanche danger, etc.), the ski school is not obligated to provide the service. It is solely at the discretion of the ski school to assess the impossibility of service provision, with partial impossibility – for example, when skiing lessons are not possible for three out of five days – not affecting the execution of the possible part of the service.
      <br><br>5.2 In the event of partial or complete impossibility of performance as described in section 5.1, the ski school will refund the proportional fee to the Customer. The Customer is not entitled to any further claims beyond this.
      <br><br>5.3 Force majeure, especially epidemics, pandemics, government measures such as closures, and other unforeseeable and unavoidable events, relieve the ski school of its obligations to provide services.
      <br><br>5.4 In the event of partial or complete impossibility of performance as described in section 5.3, the ski school will either issue a credit for the proportional fee or refund the proportional fee to the Customer. The Customer has the right to choose between these options. The Customer is not entitled to any further claims beyond this. Any existing right of withdrawal under § 10 para. 2 of the Package Travel Act remains unaffected.
      <br><br><b>§ 6 Prices, Payment Terms</b><br>
      <br><br>6.1 All information, especially price lists provided by the ski school on the internet, in brochures, advertisements, or other informational materials, are non-binding for the ski school. The ski school reserves the right to make changes at any time.
      <br><br>6.2 All price indications are in EURO (€) and are gross prices, including any applicable statutory value-added tax (VAT), unless otherwise stated.
      <br><br>6.3 Costs for ski tickets or ski equipment are not included in the course fees. These are to be acquired and brought by the Customer at their own expense.
      <br><br>6.4 The ski school's entitlement to payment from the Customer arises upon contract conclusion. At this point, the payment becomes due. In the case of online bookings, payment may be required immediately upon the submission of the offer using one of the payment methods established for online bookings. If the Customer's offer is not accepted by the ski school, any amount already paid will be refunded using the same payment method the Customer used.
      <br><br>6.5 In the case of other booking methods, such as via email or directly on-site, payment of the course fee can be made within 7 days from contract conclusion via bank transfer to the ski school's account or in cash on-site. However, payment must reach the ski school before the start of the course in any case. All expenses, especially bank charges, related to the payment of the booked course with the ski school, are solely the responsibility of the Customer.
      <br><br>6.6 The Customer can only set off counterclaims that have been legally determined by a court or explicitly recognized by the ski school, as well as in the event of the ski school's insolvency. Legal rights of retention are not affected by this contractual provision.
      <br><br>6.7 Payment default occurs without further notice from the ski school. In the event that the Customer is in payment default, the ski school is entitled to charge the legal interest on arrears and all additional costs and expenses, including collection or attorney's fees, to the Customer. In the case of outstanding debts, the ski school can allocate payments made by the Customer to its outstanding debts regardless of any specific allocation made by the Customer. In the event of non-payment of a claim, all other claims against the Customer become immediately due and payable.
      <br><br>6.8 The place of performance for all obligations to be fulfilled by both the ski school and the Customer is the location of the ski school's registered office.
      <br><br><b>§ 7 Service Provision</b><br>
      <br><br>7.1 The Customer must arrive at the meeting point of the ski school or at another location within the ski school area, as specified by the ski school, in a timely manner before the start of the course.
      <br><br>7.2 The ski school reserves the right to change the meeting point for the courses at short notice. In such cases, customers will be informed by the ski school.
      <br><br>7.3 The ski school reserves the right to conduct temperature measurements on each customer, in compliance with data protection regulations, before the start of each ski course day. In the event of an elevated temperature, fever, or other clear indications of contagious diseases (e.g., COVID-19) that may pose a risk to other ski course participants as well as instructors and supervisors, the ski school reserves the discretion to exclude the customer from the lessons. In such cases, the customer can choose either a partial refund of the fee already paid or the issuance of a corresponding credit.
      <br><br>7.4 The ski school commits to employing qualified instructors or childcare personnel specifically for each respective service.
      <br><br><b>§ 8 Limitation of Liability</b><br>
      <br><br>8.1 In connection with the offered courses, the ski school does not provide any guarantee for the success of the participants' training.
      <br><br>8.2 The ski school is not liable for damages, except for personal injuries, to the extent that the damages do not result from intentional or grossly negligent conduct by the ski school itself or a person attributable to it, and the behavior causing the damage does not concern the primary obligations arising from the concluded contract.
      <br><br>8.3 The ski school does not assume liability for damages that the Customer, without any fault of the ski school, inflicts upon themselves or other individuals during the execution of the agreed-upon service or that are caused by these individuals to the Customer.
      <br><br>8.4 Irrespective of fault, the ski school is not liable for loss of profit, pure financial losses, and consequential damages, insofar as they exceed three times the service fee.
      <br><br>8.5 The Customer is advised that not wearing a helmet in case of injuries may establish contributory negligence on the part of the Customer. Therefore, it is recommended that the Customer wear a helmet and any other safety equipment recommended for the specific booked service (e.g., avalanche transceiver for off-piste trips) or, in the case of avalanche equipment, carry it with them. Helmets and safety equipment typically reduce the risk of injury.
      <br><br>8.6 It is expressly stated that engaging in snow sports carries numerous risks, especially during tours or descents in off-piste areas, which may pose an increased risk of injury or even death, including avalanche-related risks, which cannot be entirely eliminated.
      <br><br>8.7 The Customer acknowledges that rescues on slopes or in off-piste areas can often incur high costs. Therefore, it is recommended that the Customer obtain appropriate insurance coverage, as the ski school is not liable for rescue and helicopter rescue costs, unless these rescue and/or helicopter rescue costs were caused by intentional or grossly negligent conduct by the ski school or a person attributable to it.
      <br><br><b>§ 9 Customer's Obligations</b><br>
      <br><br>9.1 The Customer is required to truthfully and comprehensively inform the ski school about their abilities and experience in the respective snow sports discipline booked, and to independently ensure appropriate equipment in line with the state of skiing technology and the prevailing conditions. The Customer must also inform the ski school about any existing health issues or impairments.
      <br><br>9.2 Furthermore, the Customer undertakes not to participate in the ski course in case of febrile infections, contagious diseases, or illnesses accompanied by diarrhea and vomiting. In particular, if COVID-19 symptoms are present (fever, dry cough, fatigue, breathing difficulties, etc.), the Customer commits to refraining from participating in the ski course. If the Customer has booked a group course, they may exercise the right of withdrawal as provided in section 4.4 of these AGB upon presentation of a medical certificate.
      <br><br>9.3 Before the start of the lessons, the Customer is responsible for independently arranging for the inspection of their ski equipment (especially ski bindings) by a specialist company.
      <br><br>9.4 The Customer must follow the instructions of the ski school, instructors, and supervisors. Disregarding instructions and warnings entitles the ski school to terminate the contract immediately. This also applies if Customers display inappropriate behavior, especially towards other course participants.
      <br><br><b>§ 10 Privacy Policy</b><br>
      <br><br>10.1 For more detailed information, please refer to the privacy policy of the ski school, which can be accessed at <a href="" target="_self">https://www.snowacademy-stanglwirt.com/data-protection.php?lang=eng</a>.
      <br><br><b>§ 11 Other Provisions</b><br>
      <br><br>11.1 Oral collateral agreements to these terms and conditions do not exist. Collateral agreements of any kind, as well as changes or additions, require written form to be effective. This also applies to waiving this written form requirement.
      <br><br>11.2 For all disputes arising from legal transactions based on these terms and conditions, the applicability of substantive Austrian law is agreed upon, excluding Austrian international private law.
      <br><br>11.3 If the Customer is a business entity or a consumer residing outside the scope of the EU Brussels I Regulation or the Lugano Convention (which includes all countries except EU member states, Switzerland, Norway, and Iceland), the competent and exclusive court for all legal disputes arising from or in connection with these General Terms and Conditions and/or contracts between the ski school and the Customer for the provision of ski school services shall be the court located at the ski school's registered office.
      <br><br>11.4 To the extent that the Customer is a consumer residing within the EU or within the scope of the Lugano Convention, the statutory provisions regarding jurisdiction apply.
      <br><br>11.5 If one or more provisions of these terms and conditions are invalid, effective provisions that come closest to the economic purpose of the invalid provision shall be deemed agreed upon between the ski school and the Customer. The validity of the remaining provisions is not affected by an invalid provision.
      <br><br>11.6 All rights and obligations arising from these terms and conditions are transferred to any legal successors of the ski school.
    </div>
  </div>
  
  <skiira-footer></skiira-footer>
  