<skiira-carousel></skiira-carousel>
<div scrollTracker [trackerType]="'sideButtons'" (scrollingPosition)="scrollingButtons1($event)" [ngClass]="scrollEvent ? 'sidebuttons-visible' : 'sidebuttons-invisible'" (mouseleave)="toogleCashdeskHover(false)" (mouseleave)="toogleSocialMedia(false)">
    <button class="pi pi-cart-plus" (mouseover)="toogleCashdeskHover(true)"  (click)="clickCashdeskHover()"></button>
    <button class="pi pi-share-alt" (mouseover)="toogleSocialMedia(true)"  (click)="clickSocialHover()"></button>

    <div class="cashdesk" [ngClass]="cashdeskHover ? 'visible' : 'invisible' " (mouseleave)="toogleCashdeskHover(false)" >
        <ul>
            <li><a [routerLink]="skiiraRoutes.kontakt">Anfrage</a></li>
            <li><a href="/buchen">Buchen</a></li>
        </ul>
    </div>

    <div class="socialdesk" [ngClass]="socialHover ? 'visible' : 'invisible'" (mouseleave)="toogleSocialMedia(false)">
        <ul>
            <li class="pi pi-facebook"></li>
            <li class="pi pi-instagram"></li>
            <li class="pi pi-twitter"></li>
            <li class="pi pi-youtube"></li>
        </ul>
    </div>
</div>


<skiira-textbox>
    <h1 headline>Snow-Academy Stanglwirt</h1>
    <ng-container headline2>Die exklusive Skischule im 5-Sterne Hotel Stanglwirt</ng-container>
    <ng-container left>Herzlich willkommen in der Snow Academy Stanglwirt. Bei uns sind Sie in Sachen Wintersport mit all seinen Facetten in den besten Händen. Neben einem erstklassigen Skischul-Erlebnis für Gäste jeden Alters und Könnens bieten wir Ihnen unseren Service der besonderen Art. Unsere Skischule liegt inmitten der atemberaubenden Tiroler Bergwelt und bietet die ideale Kulisse für Ihren Skiurlaub.</ng-container>
    <ng-container right>Buchen Sie Ihre Skischul-Erfahrung im 5-Sterne Hotel Stanglwirt und erleben Sie unvergessliche Momente auf den Pisten der Tiroler Alpen. Wir freuen uns darauf, Sie im Hotel willkommen zu heißen und Ihnen eine unvergleichliche Skiurlaubserfahrung zu bieten.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Ihr perfekter Start in die Welt des Skifahrens! Exklusiv für Gäste des Hotel Stanglwirt!</ng-container>
</skiira-textbox>

<skiira-picture-container-3row
    [image1]="'../assets/privatunterricht3_kl.jpg'" [button1href]="'/buchen'"
    [image2]="'../assets/privatunterricht3_kl.jpg'" [button2href]="'/buchen'"
    [image3]="'../assets/privatunterricht3_kl.jpg'" [button3href]="'/buchen'">
    <span text1><br><b>Professionelle Skilehrer</b><br> Unsere hochqualifizierten Skilehrer sind Experten auf ihrem Gebiet und sorgen dafür, dass Sie das Beste aus Ihrem Skiurlaub herausholen. Egal, ob Sie Anfänger sind, Ihre Technik verbessern möchten oder bereits erfahrener Skifahrer sind – unsere Lehrer passen den Unterricht individuell an Ihre Bedürfnisse an.</span>
    <span button1content>Online-Buchen</span>

    <span text2><br><b>Maßgeschneiderter Unterricht</b><br> Wir glauben an personalisierten Unterricht, der auf Ihre Fähigkeiten und Ziele zugeschnitten ist. Von Einzelstunden bis zu Gruppenkursen (Gruppenkurse nur für Kinder) bieten wir verschiedene Optionen an, um sicherzustellen, dass Sie Fortschritte machen und gleichzeitig Spaß haben.</span>
    <span button2content>Online-Buchen</span>

    <span text3><br><b>Kinderfreundlich</b><br> Wir wissen, wie wichtig es ist, dass Kinder eine positive Erfahrung auf der Piste machen. Unsere kinderfreundlichen Skilehrer gestalten den Unterricht spielerisch und sicher, um das Selbstvertrauen Ihrer Kinder aufzubauen und ihre Fähigkeiten zu entwickeln.</span>
    <span button3content>Online-Buchen</span>
></skiira-picture-container-3row>

<skiira-textbox>
    <p headline2></p>
</skiira-textbox>

<skiira-picture-container-3row
    [image1]="'../assets/privatunterricht3_kl.jpg'" [button1href]="'/buchen'"
    [image2]="'../assets/privatunterricht3_kl.jpg'" [button2href]="'/buchen'"
    [image3]="'../assets/privatunterricht3_kl.jpg'" [button3href]="'/buchen'">
    <span text1><br><b>Vielfältige Geländeoptionen</b><br> Die umliegenden Berge bieten eine Vielzahl von Pisten für alle Schwierigkeitsgrade. Unsere Skilehrer führen Sie zu den besten Orten, die zu Ihrem Niveau passen, damit Sie die Faszination des Skifahrens in vollen Zügen genießen können.</span>
    <span button1content>Online-Buchen</span>

    <span text2><br><b>Neueste Ausrüstung</b><br> Falls Sie Ihre eigene Ausrüstung nicht mitbringen, bieten wir hochwertige Skiausrüstung zum Verleih an. Unsere Mitarbeiter helfen Ihnen gerne dabei, die perfekte Ausrüstung auszuwählen, die zu Ihrem Stil und Können passt.</span>
    <span button2content>Online-Buchen</span>

    <span text3><br><b>Sicherheit an erster Stelle</b><br> Ihre Sicherheit hat oberste Priorität. Unsere Skilehrer achten nicht nur auf Ihre Technik, sondern auch darauf, dass Sie die Pistenregeln verstehen und einhalten, um eine sichere Umgebung für alle Skifahrer zu gewährleisten.</span>
    <span button3content>Online-Buchen</span>
></skiira-picture-container-3row>

<skiira-textbox>
    <p headline2>Entdecken Sie unser exklusives Anfängergelände im 5-Sterne Hotel Stanglwirt</p>
    <p left>Tauchen Sie ein in die faszinierende Welt des Skifahrens im 5-Sterne Hotel Stanglwirt und erleben Sie unser exklusives Anfängergelände – ein Paradies für alle, die ihre ersten Schritte auf Skiern wagen oder ihre Fähigkeiten verbessern möchten. </p>
    <p right>Unser speziell gestaltetes Anfängergelände mit Blick auf den Wilden Kaiser, einem eigenen Lift und modernster Beschneiungsanlage verspricht unvergleichliche Skifreude für Jung und Alt.</p>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Warum unser Anfängergelände so besonders ist:</ng-container>
    <ng-container left><b>1. Sanfte Hänge für ein sicheres Lernumfeld:</b><br>Unser Gelände wurde sorgfältig gestaltet, um Anfängern ein sicheres und komfortables Umfeld zu bieten. Die sanften Hänge ermöglichen Ihnen einen schrittweisen Einstieg in das Skifahren, ohne sich überfordert zu fühlen.</ng-container>
    <ng-container right><b>2. Dedizierter Lift für bequemen Zugang:</b><br>Ein eigener Lift bringt Sie bequem den Hang hinauf, sodass Sie mehr Zeit auf den Skiern verbringen können und weniger Zeit mit dem Warten auf den Aufstieg verschwenden. </ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>3. Vielfältige Attraktionen und Übungsbereiche:</b><br>Unser Anfängergelände bietet nicht nur hervorragende Pisten, sondern auch abwechslungsreiche Attraktionen und Übungsbereiche. Von Slalomparcours bis zu spaßigen Hindernissen gibt es viele Möglichkeiten, Ihre Fähigkeiten spielerisch zu entwickeln.</ng-container>
    <ng-container right><b>4. Optimale Schneebedingungen durch Beschneiung:</b><br>Egal ob am Anfang der Saison oder im Laufe des Winters – dank unserer modernen Beschneiungsanlage garantieren wir optimale Schneebedingungen auf unserem Anfängergelände. So können Sie bei jedem Besuch auf bestens präparierten Pisten üben.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>5. Individuelle Betreuung durch erfahrene Skilehrer:</b><br>Unsere freundlichen und erfahrenen Skilehrer stehen Ihnen jederzeit zur Seite, um Ihnen bei Ihren ersten Schritten auf den Skiern zu helfen. Sie passen den Unterricht an Ihr Können an und sorgen dafür, dass Sie Vertrauen aufbauen und Spaß haben.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/privatunterricht1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/privatunterricht2_kl.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/privatunterricht3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
<span text1>testText1</span>
<span text2>testText2</span>
<span text3>testText3</span>
</skiira-picture-grid>

<skiira-textbox>
    <p headline2>Buchen Sie jetzt Ihren Aufenthalt und erleben Sie unvergessliche Momente auf den Pisten unserer traumhaften Umgebung.</p>
</skiira-textbox>

<div class="container">
  <div class="content-row">
    <br>Egal ob Sie zum ersten Mal auf den Skiern stehen oder Ihre Technik verfeinern möchten – unser Anfängergelände ist der ideale Ort, um Ihre Skireise zu beginnen. Entdecken Sie die Freude des Skifahrens auf unserem einzigartigen Anfängergelände im 5-Sterne Hotel Stanglwirt.
    <br><br>
  </div>
</div>

<skiira-footer></skiira-footer>
