import { EndUserService } from './../../../../../../libs/skiira-core/src/lib/services/enduser.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../enums/skiira.language.enum';

@Component({
  selector: 'skiira-online-buchen-englisch',
  templateUrl: './online-buchen-englisch.component.html',
  styleUrls: ['./online-buchen-englisch.component.scss'],
})
export class OnlineBuchenEnglischComponent implements OnInit{

  skiiraLanguage = SkiiraLanguage.english;
  skiiraRoutes = SkiiraRouteStanglwirt;
    
      constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router){}
    
      ngOnInit() {
        this.metaService.metaTagCaller('OnlineBuchenEn');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
          if (lang.abkuerzung !== this.skiiraLanguage) {
              this.router.navigate([this.skiiraRoutes.buchen]);
          }
      });

      }

}
