import { NgModule } from "@angular/core";
import { KursLevelByIdPipe } from "./kurs-level-by-id.pipe";
import { KursTypByIdPipe } from "./kurs-typ-by-id.pipe";
import { YesNoPipe } from "./yes-no.pipe";
import { ArticleTextPipe } from "./article-text.pipe";
import { KursVarianteContentTextPipe } from "./kurs-variant-content.pipe";

@NgModule({
  declarations: [
    KursLevelByIdPipe,
    KursTypByIdPipe,
    YesNoPipe,
    ArticleTextPipe,
    KursVarianteContentTextPipe,
  ],
  exports: [
    KursLevelByIdPipe,
    KursTypByIdPipe,
    YesNoPipe,
    ArticleTextPipe,
    KursVarianteContentTextPipe,
  ]
})
export class PipesModule {}
