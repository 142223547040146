<div class="headerImage"><img src="../../../../../assets/freeride-touren.jpg" /></div>


<skiira-textbox>
    <h1 headline>Freeride & Ski Touring</h1>
    <ng-container headline2>The Fascination of Off-Piste Adventure</ng-container>
    <ng-container left><b>Freeride and Ski Touring:</b><br>The fascination of adventure off the slopes. For many skiers and snowboarders, winter sports are about more than just well-groomed slopes; they seek the thrill of adventure off the beaten path. Freeride and ski touring offer the opportunity to explore untouched snow slopes, experience nature's raw beauty, and revel in the feeling of freedom and adventure amidst the snowy mountain landscape. Freeriding, also known as powder skiing, is a discipline that emphasizes the thrill of descending pristine slopes. Away from groomed trails, it's about mastering natural snow and conquering the challenges of the terrain. Choosing the right line, interpreting the landscape, and mastering techniques like powder turns contribute to success and safety in freeriding. Safety is paramount. Wearing avalanche equipment such as avalanche transceivers, probes, and shovels, as well as understanding avalanche risk and terrain assessment, is essential. The Snow Academy Stanglwirt offers freeride guides who know the best spots and provide valuable tips for a safe adventure off-piste.</ng-container>
    <ng-container right><b>Ski Touring:</b><br>The Connection of Ascent and Descent. Ski touring courses are gaining popularity among winter sports enthusiasts who want to enjoy the best of both worlds: ascending through pristine nature and descending on groomed or self-made tracks. Ski touring involves reaching summits on your own, whether it's in powder or compact snow. It's a physically demanding activity, but one that is rewarded with breathtaking panoramic views and the satisfaction of pushing one's own limits. Ski touring requires special equipment, including touring skis, climbing skins for ascent, and bindings that can switch between climbing and downhill modes. Experienced ski tourers often use climbing aids to facilitate the ascent. The descent, whether in fresh powder or on challenging slopes, is the highlight of a ski touring day.</ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/powdern.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Prices Freeride & Touren</h2>
        <p beschreibung><li><b>Freeride & Ski Touring - 3 hours</b></li>
                        from 09:00 - 12:00 Uhr
                        <br>
                        <b><font color="#b68b1c">€ 255,00</font></b><br>
                        each additional person...  € 30,00
        </p>
        <p beschreibung><li><b>Freeride & Ski Touring - 3 hours</b></li>
                        from 13:00 - 16:00 Uhr
                        <br>
                        <b><font color="#b68b1c">€ 230,00</font></b><br>
                        each additional person...  € 30,00
        </p>
        <p beschreibung><li><b>Freeride & Ski Touring - full day</b></li>
                        ...at free disposal!
                        <br>
                        <b><font color="#b68b1c">€ 395,00</font></b><br>
                        each additional person...  € 30,00
        </p>
</skiira-werbecontainer>
<skiira-textbox>
    <ng-container headline2>Responsibility for Nature and Safety</ng-container>
    <p left>Both freeriding and ski touring bring a closer connection to nature. Respecting the environment, avoiding damage to wildlife, and adhering to local regulations are crucial to preserving this natural beauty for future generations. Additionally, understanding avalanche risk and proper preparation for unforeseen situations are of utmost importance in making off-piste adventures safe.</p>
    <p right>Freeride and ski touring offer a unique form of winter sports that not only brings physical challenge and excitement but also provides the opportunity to experience the magic of the mountains in their most primal form. By approaching nature, pushing boundaries, and respecting safety, anyone seeking adventure can find unparalleled fulfillment in these disciplines.</p>
</skiira-textbox>
<skiira-footer></skiira-footer>
