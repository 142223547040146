import { IKursBasis } from "../interfaces";

export class KursBasis implements IKursBasis {
  constructor(
  public id: string = '',
  public kursId: string = '',
  public kursSerieId: string = '',
  public startdatum: Date | null = null,
  public ticketAnzahlType: number = 0,
  public ticketAnzahl: number = 0,
  public gueltigkeit: number = 0,
  public wochentage: string = '',
  public besucherWaehlenDauer: number = 0,
  public maxDauerTage: number = 0,
  public bisWochentag: number = 0,
  public isDeleted : boolean = false,
  ){}
}