import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    EndUserService,
    ISprache,
    IVerleihSubmitParent,
    SkiiraRouteStanglwirt,
    Sprache,
    SpracheService,
    ZahlungService,
} from '@skiira/core';
import { VerleihSubmitParent } from 'libs/skiira-core/src/lib/models/verleihSubmitParent.model';
import { SkiiraLanguage } from '../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    skiiraRoute = SkiiraRouteStanglwirt;
    burgerMenuOpen = false;
    hoveringHeader = false;
    scrollEvent = false;
    skischuleSubMenuOpen = false;
    skiSchuleResponsiveClicked = false;
    skiiraLanguage = SkiiraLanguage;
    infoResponsiveClicked = false;
    shoppingCartAvailable = false;
    articleCount = 0;
    shoppingCartFullcontext = false;
    shoppingCartItems: IVerleihSubmitParent[] = [];
    currentLanguage: ISprache = new Sprache();

    shoppingCartTotalPrice = 0;
    mwst = 0;
    latestTotalPrice = 0;
    latestTotalmwst = 0;
    constructor(
        private router: Router,
        private zahlungSerive: ZahlungService,
        public userService: EndUserService,
        private sprachService: SpracheService
    ) {}

    ngOnInit() {
        this.zahlungSerive.getHasChangedInitially().subscribe((state: boolean) => {
            console.log('state from has changed initially: ', state);
            this.shoppingCartAvailable = state;
        });
        try {
            this.zahlungSerive.getLatestData().subscribe((data) => {
                //console.log('data from latest data: ', data);
                this.latestTotalPrice += data.gesamtPreisMitMwst;
                this.latestTotalmwst += data.mwst;
                this.shoppingCartItems.push(data);
                this.articleCount = this.shoppingCartItems.length - 1;
                if (this.articleCount > 0) {
                    this.shoppingCartAvailable = true;
                }
            });
        } catch (error) {
            console.log('Error receiving Zahlungs Object from Behaviour Subject');
        }

        this.userService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            this.currentLanguage = lang;
        });
    }
    deleteShoppingCartArticle(item: IVerleihSubmitParent) {
        console.log('item to delete: ', item);
        this.latestTotalPrice -= item.gesamtPreisMitMwst;
        this.latestTotalmwst -= item.mwst;
        this.shoppingCartItems = this.shoppingCartItems.filter((article) => article !== item);
        this.articleCount = this.shoppingCartItems.length - 1;
        if (this.articleCount === 0) {
            this.shoppingCartAvailable = false;
        }
    }

    routeKasse() {
        this.zahlungSerive.setHistoryData(this.shoppingCartItems);
        this.zahlungSerive.setTotalPrice(this.latestTotalPrice);
        this.shoppingCartFullcontext = false;
        this.router.navigate([`/${SkiiraRouteStanglwirt.zahlung}`]);
    }
    deleteCart() {
        this.shoppingCartAvailable = false;
        this.shoppingCartFullcontext = false;
        this.latestTotalmwst = 0;
        this.latestTotalPrice = 0;
        this.shoppingCartItems = [new VerleihSubmitParent()];
    }
    shoppingCartDisplay() {
        this.shoppingCartFullcontext = !this.shoppingCartFullcontext;
        console.log('this shopping cart display: ', this.shoppingCartFullcontext);
    }

    toogleBurgerMenu() {
        this.burgerMenuOpen = !this.burgerMenuOpen;
    }

    hoverHeader() {
        this.hoveringHeader = true;
    }

    unhoverHeader() {
        this.hoveringHeader = false;
    }

    scrollIsActive(scrollEvent: boolean) {
        this.scrollEvent = scrollEvent;
    }

    routeVerleih(lang: string) {
        if (lang === 'german') {
            this.router.navigate([`/${SkiiraRouteStanglwirt.verleih}`]);
        } else if (lang === 'english') {
            this.router.navigate([`/${SkiiraRouteStanglwirt.verleihEn}`]);
        }
    }

    routeBuchung() {
        console.log('trigger');
        this.router.navigate([`/${SkiiraRouteStanglwirt.buchen}`]);
    }

    selectLanguage(selectedLanguage: ISprache) {
        this.userService.selectLanguage(selectedLanguage);
        this.toogleBurgerMenu();
    }

    lang(language: SkiiraLanguage) {
        if (this.currentLanguage) {
            if (language === this.currentLanguage.abkuerzung) {
                return true;
            } else {
                return false;
            }
        }
        return null;
    }

    skiSchuleClickResponsive() {
        this.skiSchuleResponsiveClicked = !this.skiSchuleResponsiveClicked;
    }

    infoClickResponsive() {
        this.infoResponsiveClicked = !this.infoResponsiveClicked;
    }

    toggleSchischuleSubMenu() {
        this.skischuleSubMenuOpen = !this.skischuleSubMenuOpen;
    }
}
