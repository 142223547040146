import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ISprache, Sprache, SpracheService } from '@skiira/core';
import { SkiiraLanguage } from '../../../../../apps/skiira/src/app/enums/skiira.language.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EndUserService {
    public selectedLanguage = new Sprache();
    public count = 0;
    private localStorageKey = 'skiira-selected-language';
    public languageList = new Array<Sprache>();
    private languageSubject = new BehaviorSubject<Sprache>(this.selectedLanguage);
    initial: boolean = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private spracheService: SpracheService) {
        this.spracheService.getAll().subscribe((result: ISprache[]) => {
            console.log('result: ', result);
            result.filter(r => r.reihenfolge === 1 || r.reihenfolge === 2).forEach((sprache) => {
                this.languageList.push(sprache);
                console.log('languageList: ', this.languageList);
            })
        });
        this.selectedLanguage = this.languageList.find(l => l.abkuerzung === 'DEU')!;
        this.languageSubject.next(this.selectedLanguage);

    }


    public selectLanguage(language: ISprache): void {
        this.selectedLanguage = language;
        //We can remove the selected Language normaly but i want to keep it for now, because the behaviour subject is only for testing now
        // i use the behaviour subject in the imprint-de component
        this.languageSubject.next(language);
        // if (isPlatformBrowser(this.platformId)) {
        //     localStorage.setItem(this.localStorageKey, language.id);
        // }
    }

     // We subscribe to a Behaviour Subject, so we can react to the changes in the components when language is changed.
     // Because the components are not reloaded when the language is changed, we need to subscribe to the Behaviour Subject
     public getCurrentLanguageObservable() {
        this.count += 1;
        console.log('count: ', this.count)
        return this.languageSubject.asObservable();
    }

}
