<div class="container">
  <form action="mailto:book@snowacademy-stanglwirt.com">
    <div class="marginBox">
      <label for="name">Name:</label><br>
      <input type="text" id="name" name="name">
    </div>

    <div class="marginBox">
      <label for="urlaubsbeginn">Urlaubsbeginn: </label><br>
      <input type="date" id="urlaubsbeginn" name="urlaubsbeginn">
    </div>

    <div class="marginBox">
      <label for="urlaubsende">Urlaubsende: </label><br>
      <input type="date" id="urlaubsende" name="urlaubsende">
    </div>

    <div class="marginBox">
      <label for="nation">Nation: </label><br>
      <input type="text" id="nation" name="nation">
    </div>

    <div class="marginBox">
      <label for="mail">Mail:</label><br>
      <input type="email" id="mail" name="mail">
    </div>

    <div class="marginBox">
      <label for="message">Message:</label><br>
      <textarea id="message" name="message"></textarea>
    </div>

    <div class="marginBox">
      <input type="button" value="Send Mail" (click)="openMail()">
    </div>
  </form>
</div>
