import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SkiiraRouteStanglwirt, MetaTagsService, EndUserService, ISprache } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
  selector: 'skiira-platin-class-de',
  templateUrl: './platin-class-de.component.html',
  styleUrls: ['./platin-class-de.component.scss'],
})
export class PlatinClassDeComponent implements OnInit{

  skiiraLanguage = SkiiraLanguage.english;
  skiiraRoutes = SkiiraRouteStanglwirt;

  constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

  ngOnInit() {

      this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
          if (lang.abkuerzung !== this.skiiraLanguage) {
              this.router.navigate([this.skiiraRoutes.platinen]);
          }
      });
  }

}
