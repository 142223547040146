<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>

  
  <skiira-textbox>
    <h1 headline>Unternehmensbezeichnung:</h1>
    <ng-container headline2>LR Snow Academies GmbH<br>Oberhausenweg 2b, 6373 Jochberg</ng-container>
  </skiira-textbox>
  
  <div class="container">
    <div class="content-row">
      <br><b>Geschäftsführer:</b><br>
      Alois Reichholf laut Tiroler Skischulgesetz 1995
      <br><br>Wir unterliegen der Aufsicht der Bezirksverwaltungsbehörde BH Kitzbühel mit dem Tiroler Schilehrerverband. Zum Tiroler Schischulgesetz.
      <br><br>Oberhausenweg 2b
      <br>A-6373 Jochberg
      <br>Büro - Tel. +43699 / 10 71 03 30
      <br><br>Änderungen und Irrtümer vorbehalten!
      <br>UID-Nummer: ATU 72362105
      <br>FN 471714y | Landesgericht Innsbruck
      <br><br><b>Unsere Bankverbindung:</b>
      <br>Raiffeisen Bank
      <br>IBAN: AT55 3626 3000 0406 3418
      <br>BIC: RZTIAT22263
      <br><br>Konzeption, Design, Programmierung: © 2023 WA-UF, all rights reserved 
      <br><br><b>Urheber- und Kennzeichenrecht:</b><br>
      <br><br>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem österreichischen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. 
      <br><br><b>Inhalt der Webseite:</b><br>
      <br><br>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. Sämtliche auf den Seiten angeführten Preise in EUR, Preisänderungen vorbehalten. 
      <br><br><b>Verweise und Links:</b><br>
      <br><br>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      <br><br><b>Haftungsausschluss:</b><br>
      <br><br>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
      Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
      Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      <br><br><br><br>
    </div>
  </div>
  
  <skiira-footer></skiira-footer>
  