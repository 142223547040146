import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-freeride-touren-de',
    templateUrl: './freeride-touren-de.component.html',
    styleUrls: ['./freeride-touren-de.component.scss'],
})
export class FreerideTourenDeComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('FreerideTourenDe');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.freerideEn]);
            }
        });
    }
}
