import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessagingService } from '../services/messaging.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private messagingService : MessagingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          // You can also handle specific error codes here, for example:
          if (error.status === 401) {
            this.messagingService.error(error.error);
            // Unauthorized
            // Perform action, such as redirecting to login page
          } else if (error.status === 404) {
            // Not found
            // Perform action, such as displaying a message to the user
          }
        }
        console.error(errorMessage);
        return throwError(errorMessage);
      })
    );
  }
}
