import { IVerleihSubmitParent } from "../interfaces";

export class VerleihSubmitParent implements IVerleihSubmitParent {
    id = '';
    bezeichnung = '';
    grundPreis = 0;
    gesamtPreis = 0;
    mwst = 0;
    gesamtPreisMitMwst = 0;
    menge = 0;
    tage = 0;
    dateFrom = new Date();
    dateTo = new Date();
    helm = false;
    schuhe = false;
    versichert = false;
    schuhepreis = 0;
    versichertpreis = 0;
    helmpreis = 0;
}