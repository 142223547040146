import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({providedIn: 'root'})
export class MessagingService {
  private life = 3000;

  constructor(private messageService: MessageService) {}

  success(successMessage : string) {+
      this.messageService.add({ severity: 'success', summary: 'Success', detail: successMessage, life: this.life });
  }

  info(infoMessage : string) {
      this.messageService.add({ severity: 'info', summary: 'Info', detail: infoMessage, life: this.life });
  }

  warn(warnMessge : string) {
      this.messageService.add({ severity: 'warn', summary: 'Warn', detail: warnMessge, life: this.life });
  }

  error(errorMessage : string) {
      this.messageService.add({ severity: 'error', summary: 'Fehler', detail: errorMessage, life: this.life });
  }
}