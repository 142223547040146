<div class="footer-container" *ngIf="lang(skiiraLang.german)">
    <div class="col-3">
        Urlaubshotline: 
        <div><a href="tel:+43676841805210">+43(0)676 84 18 05 210</a></div>
    </div>
    <div class="col-3">
        Nachricht senden: 
        <div><a href="mailto:book@snowacademy-stanglwirt.com">book@snowacademy-stanglwirt.com</a></div>
    </div>
    <div class="col-3">
        <span style="cursor: pointer;" [routerLink]="['/',skiiraRoutes.impressum]">Impressum</span>
    </div>
    <div class="col-3">
        <span style="cursor: pointer;" [routerLink]="['/',skiiraRoutes.agb]">AGB</span>
    </div>
</div>

<div class="footer-container" *ngIf="lang(skiiraLang.english)">
    <div class="col-3">
        Holiday Hotline: 
        <div><a href="tel:+43676841805210">+43(0)676 84 18 05 210</a></div>
    </div>
    <div class="col-3">
        Send Message: 
        <div><a href="mailto:book@snowacademy-stanglwirt.com">book@snowacademy-stanglwirt.com</a></div>
    </div>
    <div class="col-3">
        <span style="cursor: pointer;" [routerLink]="['/',skiiraRoutes.imprint]">Imprint</span>
    </div>
    <div class="col-3">
        <span style="cursor: pointer;" [routerLink]="['/',skiiraRoutes.tos]">Terms & Condition</span>
    </div>
</div>


