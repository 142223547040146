import { Component, OnInit } from '@angular/core';
import { EndUserService, ISprache, SkiiraRouteStanglwirt, Sprache } from '@skiira/core';
import { SkiiraLanguage } from '../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    skiiraRoutes = SkiiraRouteStanglwirt;
    skiiraLang = SkiiraLanguage;
    currenLanguage: ISprache = new Sprache();

    constructor(private endUserService: EndUserService) {}

    ngOnInit(): void {
        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            this.currenLanguage = lang;
        });
    }

    public lang(lang: SkiiraLanguage): boolean | null {
        if (this.currenLanguage) {
            if (this.currenLanguage.abkuerzung === lang) {
                return true;
            } else {
                return false;
            }
        }
        return null;
    }
}
