import { Injectable, Inject, PLATFORM_ID, OnInit } from "@angular/core";
import { Domain } from "../enums/domain.enum";
import { isPlatformBrowser } from "@angular/common";
import { BehaviorSubject, Observable } from "rxjs";
import { IMandant, ISprache } from "../interfaces";
import { SpracheService } from "./sprache.service";
import { MandantService } from "./mandant.service";
import { DomainMetaTagNames } from "../enums";

@Injectable({ providedIn: 'root' })

export class ApplicationService {
  public header = '';
  public clientDomain = '';
  public domain: Domain = Domain.none;

  constructor(@Inject(PLATFORM_ID) private platformId: object,
  ) { }

  getCurrentDomain(): string {
    if (isPlatformBrowser(this.platformId)) {

      this.clientDomain = window.location.host;
      if (this.clientDomain === '') {
        this.clientDomain = document.domain
      }
      console.log('clientDomain: ' + this.clientDomain);

      return this.clientDomain
    }
    return '';
  }
}
