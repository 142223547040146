<div class="headerImage"><img src="../../../../../assets/heliskiing.jpg" /></div>


<skiira-textbox>
    <h1 headline>Heli-Skiing in the Austrian and Italian Alps</h1>
    <ng-container headline2>An Ultimate Adventure On and Off the Slopes</ng-container>
    <ng-container left>For winter sports enthusiasts seeking a truly extraordinary experience, Heli-Skiing in the Austrian and Italian Alps offers the opportunity to explore untouched powder slopes against a backdrop of spectacular mountain scenery. This form of skiing combines adventure, thrill, and breathtaking natural beauty, igniting the excitement of every skiing enthusiast.</ng-container>
    <ng-container right>Heli-Skiing involves transporting skiers and snowboarders to remote mountain peaks using helicopters, allowing them to descend on pristine powder slopes. Unlike traditional ski resorts, Heli-Skiing provides access to areas that are usually challenging or even inaccessible. This offers the chance to experience long descents on untouched snow and relish the freedom of off-piste skiing.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>The Austrian Alps:</b><br>A Heli-Skier's Paradise<br>The Austrian Alps serve as a stunning backdrop for Heli-Skiing adventures. From the majestic peaks to the expansive powder slopes, countless opportunities await for unforgettable descents. Renowned locations like Kitzbühel and Going serve as the starting points for our Heli-Skiing tours, providing unparalleled panoramas and pure snow enjoyment.</ng-container>
    <ng-container right><b>The Italian Alps:</b><br>An Untouched Haven for Adventurers<br>The Italian Alps remain a hidden gem for Heli-Skiers in search of the magic of untouched snow slopes. They offer endless possibilities for exhilarating descents. The unique alpine ambiance, coupled with Italian hospitality and cuisine, makes Heli-Skiing in the Italian Alps an unforgettable experience.</ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/heliskiing_powder.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Prices heli-skiing</h2>
        <p beschreibung><li><b>Heli-Skiing - 3 hours</b></li>
                        from 09:00 - 12:00 Uhr
                        <br>
                        <b><font color="#b68b1c">Price on demand</font></b><br>
        </p>
        <p beschreibung><li><b>Heli-Skiing - 3 hours</b></li>
                        from 13:00 - 16:00 Uhr
                        <br>
                        <b><font color="#b68b1c">Price on demand</font></b><br>
        </p>
        <p beschreibung><li><b>Heli-Skiing - full day</b></li>
                        ...at free disposal!
                        <br>
                        <b><font color="#b68b1c">Price on demand</font></b><br>
        </p>
</skiira-werbecontainer>

<skiira-footer></skiira-footer>