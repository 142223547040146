import { Component, OnInit, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { Router } from '@angular/router';
import { SkiiraLanguage } from '../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-agb-en',
    templateUrl: './agb-en.component.html',
    styleUrls: ['./agb-en.component.scss'],
})
export class AgbEnComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.english;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('AgbEn');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.agb]);
            }
        });
    }
}
