<div class="headerImage"><img src="../../../../../assets/langlauf.jpg" /></div>


<skiira-textbox>
    <h1 headline>Cross-Country Skiing - Classic style & skating technique</h1>
    <ng-container headline2>Dominik Landertinger, Four-time Olympian, Five-time World Medalist, and World Champion</ng-container>
    <ng-container left>Cross-country skiing, a discipline of Nordic skiing, embodies the beauty of nature, the challenge of endurance, and the art of technique. In collaboration with Dominik Landertinger, a respected biathlete and cross-country skiing enthusiast, we delve into the fascination of cross-country skiing, its health benefits, and Dominik's personal experiences.</ng-container>
    <ng-container right>Cross-country skiing, also known as Nordic skiing, involves gliding across snow-covered landscapes. It combines the enjoyment of nature with physical activity, allowing athletes to find their rhythm in a peaceful environment. The fluid movements engage the entire body, strengthen the cardiovascular system, and enhance endurance.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/langlauf1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/langlauf2.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/langlauf3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
</skiira-picture-grid>

<skiira-textbox>
    <ng-container left><b>Health Benefits of Cross-Country Skiing</b><br>Dominik Landertinger emphasizes how cross-country skiing has contributed to his physical fitness and mental balance. Engaging in the sport in the fresh mountain air promotes physical health, while immersing oneself in nature reduces stress and enhances well-being. Cross-country skiing is accessible for all age groups and provides a gentle way to preserve joints while strengthening muscles and endurance.</ng-container>
    <ng-container right><b>Technique and Challenge</b><br>The technique of cross-country skiing can be demanding but doesn't require special skills. Beginners can learn the classic technique, where movements resemble walking or jogging. The skating technique, on the other hand, is more dynamic, reminiscent of ice skating. Dominik Landertinger highlights the importance of good technique, which not only increases efficiency but also reduces the risk of injury.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Dominik Landertinger's Passion for Cross-Country Skiing</b><br>Dominik has developed a deep passion for cross-country skiing. In addition to his biathlon career, he enjoys the peaceful moments on cross-country trails. He values the connection to nature and the opportunity to rejuvenate away from competition. Landertinger sees cross-country skiing not only as a training method but also as a way to fully enjoy life.</ng-container>
    <ng-container right><b>A Sport for Body and Soul</b><br>Cross-country skiing is more than just a sport – it's a connection to nature, a break from everyday life, and a way to promote health. Through collaboration with Dominik Landertinger, the magic of cross-country skiing is highlighted. Regardless of your age or fitness level, cross-country skiing provides an opportunity to experience the joy of movement, the beauty of nature, and the satisfaction of pushing your limits.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <h1 headline>Maximize Your Potential</h1>
    <ng-container headline2>Tailored Performance Tests and Training Plans with Lactate Measurement</ng-container>
    <ng-container left>Our personalized performance tests and training plans with lactate measurement offer a groundbreaking way to optimize your athletic performance. Through precise measurements and personalized analyses, we help you identify your physical limits and create tailored training plans.</ng-container>
    <ng-container right>Whether you're an experienced athlete or a fitness enthusiast, our offering enables you to train more efficiently, minimize injuries, and achieve your goals faster. Let's unlock your full potential together.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>