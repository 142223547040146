import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class RestService<T> {
  private baseUrl = `${environment.api}/v1`;
  abstract entity: string;

  constructor(public http: HttpClient) { }

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.getDefaultUrl());
  }

  get(id: string): Observable<T> {
    return this.http.get<T>(`${this.getDefaultUrl()}/${id}`);
  }

  create(data: T): Observable<T> {
    return this.http.post<T>(this.getDefaultUrl(), data);
  }

  update(id: string, data: T): Observable<T> {
    return this.http.put<T>(`${this.getDefaultUrl()}/${id}`, data);
  }

  delete(id: string): Observable<T> {
    return this.http.delete<T>(`${this.getDefaultUrl()}/${id}`);
  }

  getDefaultUrl(): string {
    return `${this.baseUrl}/${this.entity}`;
  }
}
