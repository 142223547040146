import {
    Directive,
    HostListener,
    ElementRef,
    Renderer2,
    AfterViewInit,
    Output,
    EventEmitter,
    Input,
  } from '@angular/core';
  
  @Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[scrollTracker]',
  })
  export class ScrollTrackerDirective implements AfterViewInit {
    @Input() trackerType: 'header' | 'sideButtons' | undefined;
    @Output() isScrolling: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() scrollingPosition: EventEmitter<number> = new EventEmitter<number>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
    ngAfterViewInit(): void {
      console.log('trackerType: ' + this.trackerType);
    }
    previousScrollPosition = 0;
    isHeaderVisible = false;
    currentScrollPosition = 0;
  
  
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
      this.currentScrollPosition = window.scrollY;
      switch (this.trackerType) {
        case 'sideButtons':
          this.scrollingPosition.emit(this.currentScrollPosition);
        break;
        case 'header':
          if (this.currentScrollPosition > 350 ) {
            this.renderer.addClass(this.elementRef.nativeElement, "header-background-visible");
            this.renderer.removeClass(this.elementRef.nativeElement, "header-background-hidden");
            this.isScrolling.emit(true);
          } else {
            this.renderer.removeClass(this.elementRef.nativeElement, "header-background-visible");
            this.renderer.addClass(this.elementRef.nativeElement, "header-background-hidden");
            this.isScrolling.emit(false);
          }
          break;
        
    }
  }
}