import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BenutzerService } from '../services';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private benutzerService : BenutzerService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Füge den Token zum Request Header hinzu
    const modifiedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.benutzerService.token}`
      }
    });

    // Weiterleiten des modifizierten Requests
    return next.handle(modifiedRequest);
  }
}
