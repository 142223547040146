import { Component, OnInit, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../enums/skiira.language.enum';
import { Router } from '@angular/router';

@Component({
    selector: 'skiira-agb-de',
    templateUrl: './agb-de.component.html',
    styleUrls: ['./agb-de.component.scss'],
})
export class AgbDeComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.german;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('AgbDe');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.tos]);
            }
        });
    }
}
