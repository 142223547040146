import { Component, Input, OnInit } from '@angular/core';
import { IVerleihSubmit, IVerleihSubmitParent, ZahlungService } from '@skiira/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'skiira-zahlung',
    templateUrl: './zahlung.component.html',
    styleUrls: ['./zahlung.component.scss'],
})
export class ZahlungComponent implements OnInit {
    dateTo: Date | undefined;
    dateFrom: Date | undefined;
    tage: number | undefined;
    mwst: number | undefined;
    gesamtPreisMitMwst: number = 0;
    articles: IVerleihSubmitParent[] | undefined;
    visible: boolean = false;
    transfomedData: any[] | undefined;
    submitForm: FormGroup | undefined;

    constructor(private zahlungService: ZahlungService, private fb: FormBuilder, ) {
        this.zahlungService.getHistoryDataObject().subscribe( data => {
            this.articles = data;
            console.log("data from history: ", data);
        });

        
    }

    ngOnInit() {
        try {
            this.transfomedData = this.articles?.map(article => this.dataTransformation(article));
            this.transfomedData?.forEach(article => {
                console.log("article: ", article);
                this.gesamtPreisMitMwst += article.gesamtPreisMitMwst;
                
                console.log(' Gesamtpreis: ', this.gesamtPreisMitMwst);
                console.log(' article mwst: ', article.gesamtPreisMitMwst);
            });
        } catch (error) {
            console.log(error);
        }
    }

    dataTransformation(article: IVerleihSubmitParent): any {
        try {
        const [gesamtPreisMitMwst, mwst] = this.getGesamtPreis(article) || [0, 0];
        const gesamtPreis: number = article.gesamtPreis || 0;
        return {
            ...article,
            dateTo: this.getDateTo(article),
            gesamtPreis,
            mwst,
            gesamtPreisMitMwst,
        };
    } catch (error) {
        console.log("error occured on transformation: ", error);
    }
}
    
    getDateTo(article: IVerleihSubmitParent) {
        if (article) {
            const tage = article.tage;
            let dateTo = new Date(article.dateFrom);
            dateTo.setDate(dateTo.getDate() + tage);
            return dateTo;
        } else {
            console.log('article is undefined, cant set DateTo');
            return undefined;
        }
    }
    getGesamtPreis(article: IVerleihSubmitParent) {
        if (article) {            
            const mwst = article.gesamtPreis * 0.2;
            const gesamtPreisMitMwst = article.gesamtPreis + mwst;
            return [gesamtPreisMitMwst, mwst];
        } else {
            console.log('article is undefined cant set GesamtPreis and Mwst');
            return [0, 0];
        }
    }

    showDialog() {
        this.visible = true;
    }
    
}
