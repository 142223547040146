export enum SkiiraAdminRoutes {
    none = '',
    benutzer = 'benutzer',
    benutzerrecht = 'benutzerrecht',
    benutzerbenutzerrechte = 'benutzerbenutzerrecht',
    kurs = 'kurs',
    kurskalender = 'kurs-kalender',
    kurskarten = 'kurs-karten',
    kurslevel = 'kurs-level',
    kurstyp = 'kurs-typ',
    lehrer = 'lehrer',
    mandant = 'mandant',
    sprache = 'sprache',
    partner = 'partner',
    webcontent = 'webcontent',
    reisebuero = 'reisebuero',
    sammelplatz = 'sammelplatz',
    sammelplatztext = 'sammelplatztext',
}