<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>


<skiira-textbox>
    <h1 headline>Events - Teambuilding and Adventure in the Alps</h1>
    <ng-container headline2>Unforgettable Snow Academy Events in Collaboration with Our Partners</ng-container>
    <ng-container left>Experience, adventure, and fun await you at our events. We are delighted to assist you in collaboration with our partners </ng-container>
    <ng-container right>in organizing your tailor-made event. Whether it's a corporate or private event, everything is possible.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/privatunterricht1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/privatunterricht2_kl.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/privatunterricht3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
</skiira-picture-grid>

<skiira-textbox>
    <ng-container left>
        <ul>
            <li>Teambuilding of All Kinds</li>
            <li>Winter Olympics with or without Music and Moderation</li>
            <li>Organization of Various Ski Race Formats for All Skill Levels</li>
            <li>Landing Airbag</li>
            <li>Mountain-Top Party</li>
            <li>Various Locations from Exclusive to Rustic</li>
            <li>Sled Evening with Mountain-Top BBQ</li>
            <li>Corporate Celebrations on the Mountain</li>
        </ul>
    </ng-container>
    <ng-container right>
        <ul>
            <li>Curling or Ice Stock Tournament</li>
            <li>Torchlight Walks</li>
            <li>Horse-Drawn Sleigh Rides</li>
            <li>Hot Air Balloon Rides</li>
            <li>Paragliding</li>
            <li>Biathlon</li>
            <li>Ice Skating</li>
            <li>Moonbikes</li>
        </ul>
    </ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Events that leave Impressions</ng-container>
    <ng-container left>The Snow Academy's events are more than just gatherings – they are experiences that leave lasting impressions and strengthen team spirit. From athletic competitions to adrenaline-fueled adventures and festive celebrations,</ng-container>
    <ng-container right>we offer a variety of options to turn your event into an unforgettable highlight. Let's explore the Alpine landscape together and create memories that will endure.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>