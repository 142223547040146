<div class="headerImage"><img src="../../../../../assets/rennlauf.jpg" /></div>


<skiira-textbox>
    <h1 headline>Racing Training for Kids and Adults</h1>
    <ng-container headline2>From Beginner to Pro</ng-container>
    <ng-container left>Alpine racing is a captivating facet of skiing that demands not only speed but also technique, precision, and endurance. From young aspiring racers to seasoned adults,</ng-container>
    <ng-container right>alpine racing training offers the opportunity to develop within a competitive environment and elevate one's skills on the slopes to the next level.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>For Kids:</b><br>The Foundation for Future Champions<br>Young skiers who are fascinated by the speed and competitive spirit of alpine racing find an excellent opportunity in racing training to enhance their technique and self-confidence. Alpine racing training for kids focuses on building solid foundations, including body posture, turning techniques, and slalom racing. This sets the stage for a successful racing career while fostering essential qualities like discipline, perseverance, and teamwork. Kids are supervised by experienced trainers who not only address technical aspects but also adopt a playful approach to make learning entertaining. This aids in sparking the children's interest in alpine racing while refining their technical skills.</ng-container>
    <ng-container right><b>For Teens:</b><br>Technique, Strategy, and Competitive Spirit<br>As one's alpine racing career progresses, teenagers are introduced to increasingly advanced techniques and strategies. They learn advanced techniques in various disciplines like slalom, giant slalom, and super-G. Additionally, a greater emphasis is placed on competition and tactics as they prepare for regional and national competitions. Teens are supported through targeted exercises, video analyses, and competition simulations to refine their skills and prepare for a variety of racing conditions. Simultaneously, values such as fairness, respect, and teamwork are nurtured to support personal development.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>For Adults:</b><br>Challenge and Passion<br>Alpine racing isn't solely reserved for the younger generations. In adulthood, alpine racing training offers an avenue to challenge one's own abilities and elevate the passion for skiing to new heights. Adults have the opportunity to refine their technique, test their limits, and participate in championships and amateur races. Alpine racing training for adults not only promotes physical fitness and technique but also provides a chance to engage within a community of like-minded individuals who share a love for skiing.</ng-container>
    <ng-container right><b>Conclusion:</b><br>Beyond Speed<br>Alpine racing training enriches skiers of all ages and levels. It's not solely about speed on the slopes; it's about striving for continuous improvement, developing crucial skills, and enjoying the thrill of competition. From building strong foundations to competing in races, alpine racing offers a journey of learning, personal growth, and passion that extends far beyond the slopes.</ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/rennlauf.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Prices race training</h2>
        <p beschreibung><li><b>Race training - 3 hours</b></li>
                        from 09:00 - 12:00
                        <br>
                        <b><font color="#b68b1c">€ 255,00</font></b><br>
                        each additional person...  € 30,00
        </p>
        <p beschreibung><li><b>Race training - 3 hours</b></li>
                        from 13:00 - 16:00
                        <br>
                        <b><font color="#b68b1c">€ 230,00</font></b><br>
                        each additional person...  € 30,00
        </p>
        <p beschreibung><li><b>Race training - full day</b></li>
                        ...at free disposal!
                        <br>
                        <b><font color="#b68b1c">€ 395,00</font></b><br>
                        each additional person...  € 30,00
        </p>
</skiira-werbecontainer>

<skiira-footer></skiira-footer>