<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>


<skiira-textbox>
    <h1 headline>Ein Willkommenszentrum der Extraklasse</h1>
    <ng-container headline2>Das Büro in der Lobby des Stanglwirt</ng-container>
    <ng-container left>Das Büro ermöglicht es der Snow-Academy Stanglwirt seine Gäste persönlich zu betreuen und auf individuelle Bedürfnisse einzugehen. Für alle Fragen rund um Winteraktivitäten und Veranstaltungen stehen Ihnen unsere Mitarbeiterinnen und Mitarbeiter mit Rat und Tat zur Seite und freuen sich auf ein persönliches Gespräch mit Ihnen.</ng-container>
    <ng-container right>Die Lobby eines Hotels ist oft der erste Ort, an dem Gäste eintreffen und sich willkommen fühlen. Mit dem Büro direkt in der Lobby geht die Skischule noch einen Schritt weiter, um Gästen den bestmöglichen Service zu bieten. Dieser einladende Ort dient als Anlaufstelle für verschiedene Bedürfnisse, sei es für Informationen, Reservierungen, Auskünfte oder jegliche Anliegen.</ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/powdern.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Unser Büro an der Rezeption</h2>
        <p beschreibung>Das Büro in der Lobby des Stanglwirts ist eine Anlaufstelle für Gäste, die vielfältige Dienstleistungen in Anspruch nehmen möchten. Hier können Gäste Reservierungen für Aktivitäten und vieles mehr vornehmen. Das engagierte Team im Büro steht zur Verfügung, um den Gästen bei allen Fragen und Wünschen behilflich zu sein und einen reibungslosen Ablauf ihres Aufenthalts zu gewährleisten.</p>
</skiira-werbecontainer>

<skiira-textbox>
    <h1 headline></h1>
    <ng-container headline2></ng-container>
    <ng-container left></ng-container>
    <ng-container right></ng-container>
</skiira-textbox>
<skiira-footer></skiira-footer>