<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>


<skiira-textbox>
    <h1 headline>A Welcome Center of Excellence</h1>
    <ng-container headline2>The Lobby Office at Stanglwirt</ng-container>
    <ng-container left>The office allows the Snow-Academy Stanglwirt to personally attend to its guests and cater to individual needs. For all questions related to winter activities and events, our staff members are there to provide advice and assistance, looking forward to engaging in personal conversations with you.</ng-container>
    <ng-container right>The lobby of a hotel is often the first place where guests arrive and feel welcome. With the office right in the lobby, the ski school goes a step further to provide guests with the best possible service. This inviting space serves as a hub for various needs, whether for information, reservations, inquiries, or any concerns.</ng-container>
</skiira-textbox>

<skiira-werbecontainer [imageUrl]="'../assets/powdern.jpg'" [imageAlt]="'AlternativBeschreibung'">
        <h2 header>Our office at the reception</h2>
        <p beschreibung>The lobby office at Stanglwirt is a central point for guests who wish to access a variety of services. Here, guests can make reservations for activities and much more. The dedicated team in the office is available to assist guests with all their questions and needs, ensuring a seamless experience during their stay.</p>
</skiira-werbecontainer>

<skiira-textbox>
    <h1 headline></h1>
    <ng-container headline2></ng-container>
    <ng-container left></ng-container>
    <ng-container right></ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>