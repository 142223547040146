<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>

  
  <skiira-textbox>
    <h1 headline>Kitzbühel TV</h1>
    <ng-container headline2>LIVE stream from Kitzbühel and surroundings</ng-container>
    <ng-container left>“Stay tuned” on your ski holiday in Kitzbühel with the LIVE-Stream of Kitzbühel. Here you will find all the information about the current weather status, snow conditions, opening hours and lot more.</ng-container>
    <ng-container right>All of the webcams on the mountains are shown at consecutive intervals so you can watch the latest information at all times!</ng-container>
  </skiira-textbox>

  
<div class="container">
  <div class="content-row">
    <iframe id='KitzSkiWidget map-container' src='https://partner.kitzski.at/en/webcam/?mode=iframe&demo=true&font=Roboto, sans-serif&fontsize=18&fontcolor=000000&background=FFFFFF&maxwidth=1300' style='width: 100%; height: 100%; max-width: 1300px; overflow: auto;'></iframe>
    <script src='https://partner.kitzski.at/de/resources/wg-height.js'></script>		
    <br><br><br><br>	
  </div>
</div>
  
<skiira-footer></skiira-footer>