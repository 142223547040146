import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-location-en',
    templateUrl: './location-en.component.html',
    styleUrls: ['./location-en.component.scss'],
})
export class LocationEnComponent implements OnInit {
    scrollEvent = false;
    cashdeskHover = false;
    socialHover = false;
    skiiraLanguage = SkiiraLanguage.english;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('LocationEn');
        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.location], { skipLocationChange: false, replaceUrl: false });
            }
        });
    }

    toogleCashdeskHover(value: boolean) {
        this.cashdeskHover = value;
        if (this.socialHover) {
            this.socialHover = false;
        }
    }

    toogleSocialMedia(value: boolean) {
        this.socialHover = value;
        if (this.cashdeskHover) {
            this.cashdeskHover = false;
        }
    }

    scrollingButtons1(scrollEvent: number) {
        if (scrollEvent > 500) {
            this.scrollEvent = true;
        } else {
            this.scrollEvent = false;
        }
    }
}
