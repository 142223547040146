import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';
import { Router } from '@angular/router';

@Component({
    selector: 'skiira-buero-en',
    templateUrl: './buero-en.component.html',
    styleUrls: ['./buero-en.component.scss'],
})
export class BueroEnComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.english;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private metaService: MetaTagsService,
        private endUserService: EndUserService,
        private router: Router
    ) {}

    ngOnInit() {
        if (isPlatformServer(this.platformId)) {
            this.metaService.metaTagCaller('BueroStanglwirtEn');
        }

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.buero]);
            }
        });
    }
}
