<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>

  
  <skiira-textbox>
    <h1 headline>Kitzbühel TV</h1>
    <ng-container headline2>LIVE-Stream von Kitzbühel und Umgebung</ng-container>
    <ng-container left>Lassen Sie sich auf Ihren Skiurlaub in Jochberg einstimmen mit dem LIVE-Stream von Jochberg. Hier sehen Sie alle Informationen zur Wetterlage, Schneehöhe, Öffnungszeiten und vieles mehr.</ng-container>
    <ng-container right>Alle Webcams am Berg werden in Intervallen hintereinander gezeigt, so dass Sie zu jeder Zeit über den aktuellen Stand informiert sind!</ng-container>
  </skiira-textbox>

  
<div class="container">
  <div class="content-row">
    <iframe id='' class="z-depth-1-half map-container" src='https://partner.kitzski.at/de/webcam/?mode=iframe&demo=true&font=Roboto, sans-serif&fontsize=18&fontcolor=000000&background=FFFFFF&maxwidth=1300' ></iframe>
    <script src='https://partner.kitzski.at/de/resources/wg-height.js'></script>		
    <br><br><br><br>	
  </div>
</div>

<skiira-footer></skiira-footer>