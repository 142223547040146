import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EndUserService, IVerleihSubmitParent, KursModel, KursSerieListModel, KursService, Sprache, SpracheService, Verleih, VerleihService, ZahlungService } from '@skiira/core';
import { Router,  } from '@angular/router';
import { MessagingService } from '@skiira/core';
import { environment } from 'libs/skiira-core/src/lib/environments/environment';
import { KursTextType } from 'libs/skiira-core/src/lib/models/kurs-text-type.model';
import { KursVarianteContentTextPipe } from 'libs/skiira-core/src/lib/pipes/kurs-variant-content.pipe';

@Component({
    selector: 'skiira-verleih-main-view-de',
    templateUrl: './verleih-main-view-de.component.html',
    styleUrls: ['./verleih-main-view-de.component.scss'],
})
export class VerleihMainViewDeComponent implements OnInit {
    layout: 'list' | 'grid' = 'list';
    verleihArticleList = new Array<KursSerieListModel>();
    kursTextType: KursTextType[] = [];
    selectedPrice: number | undefined;
    pricelist: number[] = [];
    carditem: FormGroup;
    numberlist: any[] = [];
    formdata: IVerleihSubmitParent;
    formvalid: boolean = true;
    totalprice: number = 0;
    totalpriceshow: boolean = false;
    preistag: { name: string; price: number } | undefined;
    selectedTag: any;
    environment = environment;
    sprachen: Sprache[] = [];
    currentLang: string = '';
    //languageId: string = '';

    constructor(
        private kursService: KursService,
        private formBuilder: FormBuilder,
        private router: Router,
        private zahlungService: ZahlungService,
        private messagingService: MessagingService,
        private sprachService: SpracheService,
        private userService: EndUserService,
    ) {
        this.carditem = this.formBuilder.group({
            bezeichnung: [''],
            zusatzpreis: [''],
            tage: [''],
            menge: [''],
            dateFrom: new FormControl<Date>(new Date()),
            dateTo: new FormControl<Date>(new Date()),
            mwst: [''],
            gesamtPreis: [''],
            helm: new FormControl<boolean>(false),
            schuhe: new FormControl<boolean>(false),
            versichert: new FormControl<boolean>(false),
        });

        for (let i = 1; i <= 24; i++) {
            this.numberlist.push({ name: i });
        }

        this.formdata = {} as IVerleihSubmitParent;
    }

    ngOnInit() {
        
        this.sprachService.getAll().subscribe({
            next: (sprache) => {
                this.sprachen = sprache;
                console.log('sprachen: ', this.sprachen)
            },
            error: (error) => {
                console.log('Error occured receiving Sprache from Service: ', error);
            },

        });

        // this.userService.selectedLanguage$.subscribe({
        //     next: (lang) => {
        //         this.currentLang = lang;
        //         console.log('currentLang: ', this.currentLang);

        //         var language = this.sprachen.find(l => l.bezeichnung.toLocaleLowerCase() === this.currentLang.toLocaleLowerCase());
                
        //         if (language) {
        //             this.languageId = language.id;

        //         } else {
        //             console.log("Language not found: ");
        //             this.currentLang = this.sprachen[1].bezeichnung;
        //         }

        //     },
        //     error: (error) => {
        //         console.log('Error occured receiving Language from Service: ', error);
        //     },
        // })




        this.verleihArticleList = [];
        this.kursService.getAllKursModels().subscribe({
            next: (articles) => {
                const verleihList = articles.filter(article => article.kurs.abkuerzung.toLowerCase() === 'verleih');
                verleihList.forEach(item => {
                    var foundInList = this.verleihArticleList.find(va => va.kursSerie.id === item.serie.id);
                    if (foundInList != null) {
                        foundInList.kursModelList.push(item);
                    }
                    else {
                        var newListItem = new KursSerieListModel();
                        newListItem.kursSerie = item.serie;
                        newListItem.kursModelList.push(item);
                        this.verleihArticleList.push(newListItem);
                    }
                });
                console.log('verleiharticleList: ', this.verleihArticleList);
            },
            error: (error) => {
                console.log('Error occured receiving Article from backend: ', error);
            },
        });
    }



    priceFactoring(event: any) {
        console.log('event: ', event);
        this.preistag = event.value;
        console.log('preistag: ', this.preistag);
        if (this.carditem && this.preistag) {
            const index = parseInt(this.preistag.name.split(' ')[1]);
            console.log('index: ', index);
            console.log('preis: ', this.preistag.price);
            this.carditem.get('zusatzpreis')?.setValue(this.preistag.price);
            this.carditem.get('tage')?.setValue(index);
        } else {
            console.log('carditem is not defiend!');
        }
    }

    verleihItemFunction(verleihItem: any) {
        const articlePipe = new KursVarianteContentTextPipe(this.userService);
        for (let model of verleihItem.kursModelList[0].varianten) {
            console.log('varianteContent: ', model.variantenContent[1].text);
            console.log(': ', articlePipe.transform(model.variantenContent[1].text));
        };
    }

    calcTotalPrice(verleih: Verleih) {
        const helm = this.carditem.get('helm')?.value;
        const schuhe = this.carditem.get('schuhe')?.value;
        const versichert = this.carditem.get('versichert')?.value;

        var helmprice = helm ? verleih.helm : 0;
        var schuheprice = schuhe ? verleih.schuhe : 0;
        var versichertprice = versichert ? verleih.versichert : 0;
        const zusatzpreis = this.carditem.get('zusatzpreis')?.value;
        const grundpreis = verleih.grundPreis;
        const menge = this.carditem.get('menge')?.value.name;
        console.log('helm: ', helmprice, 'schuhepreis: ', schuheprice, 'versichert: ', versichert);
        const subpreis = grundpreis + zusatzpreis + helmprice + schuheprice + versichertprice;
        helmprice = helm ? helmprice * menge : 0;
        schuheprice = schuhe ? schuheprice * menge : 0;
        versichertprice = versichert ? versichertprice * menge : 0;
        const total = subpreis * menge;
        const mwst = total * 0.2;
        const gesamtPreisMitMwst = total + mwst;

        console.log('zusatzpreis: ', zusatzpreis);
        console.log('grundpreis: ', grundpreis);
        console.log('menge: ', typeof menge);
        console.log('helm: ', helm);
        console.log('schuhe: ', schuhe);
        console.log('versichert: ', versichert);
        console.log('helmprice: ', helmprice);
        console.log('schuheprice: ', schuheprice);
        console.log('versichertprice: ', versichertprice);

        return [total, mwst, gesamtPreisMitMwst, schuheprice, versichertprice, helmprice];
    }

    formDataUtilityCheck(formdata: IVerleihSubmitParent) {
        return Object.entries(formdata).every(([key, value]) => {
            if (['versichert', 'helm', 'schuhe'].includes(key)) {
                return true;
            }
            if (value !== null && value !== undefined) {
            } else {
                return false;
            }
            return true;
        });
    }
    cardSubmit(verleih: Verleih) {
        console.log('verleih: ', verleih);
        try {
            this.carditem.get('bezeichnung')?.setValue(verleih.bezeichnung);
            const helm = this.carditem.get('helm')?.value;
            const schuhe = this.carditem.get('schuhe')?.value;
            const versichert = this.carditem.get('versichert')?.value;
            console.log('helm: ', helm, 'schuhe: ', schuhe, 'versichert: ', versichert);
            const dateFrom = this.carditem.get('dateFrom')?.value;
            const [total, mwst, gesamtPreisMitMwst, schuheprice, versichertprice, helmprice] =
                this.calcTotalPrice(verleih);

            this.formdata.bezeichnung = verleih.bezeichnung;
            this.formdata.grundPreis = verleih.grundPreis;
            this.formdata.gesamtPreis = total;
            this.formdata.mwst = mwst;
            this.formdata.gesamtPreisMitMwst = gesamtPreisMitMwst;
            this.formdata.menge = this.carditem.get('menge')?.value.name;
            this.formdata.tage = this.carditem.get('tage')?.value;
            this.formdata.dateFrom = this.carditem.get('dateFrom')?.value;
            this.formdata.dateTo = this.carditem.get('dateTo')?.value;
            this.formdata.dateTo.setDate(this.formdata.dateFrom.getDate() + this.formdata.tage);
            this.formdata.helm = helm;
            this.formdata.schuhe = schuhe;
            this.formdata.versichert = versichert;
            this.formdata.versichertpreis = versichertprice;
            this.formdata.helmpreis = helmprice;
            this.formdata.schuhepreis = schuheprice;
            this.formvalid = true;
        } catch (error) {
            this.formvalid = false;
            console.log('carditem not set: ', error);
        }
        console.log('formData: ', this.formdata);
        var check = this.formDataUtilityCheck(this.formdata);
        console.log('formDataUtilityCheck: ', check);
        console.log('formvalid: ', this.formvalid);
        if (this.formDataUtilityCheck(this.formdata) && this.formvalid) {
            this.zahlungService.setCurrentData(this.formdata);
            //clean up after submit
            this.formdata = {} as IVerleihSubmitParent;
            this.carditem.reset({
                dateFrom: new Date(),
                dateTo: new Date(),
                tage: this.carditem.get('tage')?.value,
            });
            this.selectedTag = null;
        } else {
            this.messagingService.warn('Please fill out all fields!');
            console.log('formData Object is not valid');
        }
    }
}
