<div class="headerImage"><img src="../../../../../assets/langlauf.jpg" /></div>


<skiira-textbox>
    <h1 headline>Biathlon - A Fusion of Shooting and Cross-Country Skiing</h1>
    <ng-container headline2>Where Precision Meets Endurance</ng-container>
    <ng-container left>Biathlon, a captivating combination of cross-country skiing and shooting, captivates sports enthusiasts worldwide. This unique discipline demands not only physical endurance but also the highest precision and concentration. Let's delve into the world of biathlon and discover the exciting facets of this versatile sport.</ng-container>
    <ng-container right>Biathlon combines two seemingly contrasting elements: the physically demanding cross-country skiing and the precise shooting. Athletes cover distances of varying lengths during cross-country skiing, only to maintain composure and accuracy at the shooting range. The combination of intense physical exertion and calm concentration makes biathlon a unique competition.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/langlauf1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/langlauf2.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/langlauf3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
</skiira-picture-grid>

<skiira-textbox>
    <ng-container left><b>The Challenge of Shooting</b><br>Shooting in biathlon requires an extraordinary combination of physical stability and mental composure. Athletes must transition from strenuous skiing to stillness and maintain accuracy to hit small targets at a distance of 50 meters. The two shooting positions, standing and prone, pose different demands on athletes, testing their ability to perform precisely under pressure.</ng-container>
    <ng-container right><b>Training Approach and Preparation</b><br>Biathletes follow a comprehensive training program that includes both endurance and shooting exercises. Cross-country skiing training enhances aerobic fitness and endurance, while shooting practice promotes shot accuracy under stressful conditions. A strong emphasis is also placed on mental strength to sustain focus during races.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>Competitions and Olympic Games</b><br>Biathlon is an Olympic discipline that captures global attention. Competitions vary in length and requirements, ranging from sprint races to pursuit and relay events. Biathlon demands not only physical and technical skills but also strategic decisions on when and how often to shoot.</ng-container>
    <ng-container right><b>Fascination and Community</b><br>Biathlon has gained a broad fan base, not only due to the exciting competitions but also because of the unique blend of sports and nature. The biathlon community shares a passion for the sport and encourages collaboration and personal development.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>A Unique Symbiosis of Strength and Precision</ng-container>
    <ng-container left>The fusion of cross-country skiing and shooting creates a unique symbiosis of strength and precision. Whether as a spectator or an athlete, biathlon equally captivates and inspires.</ng-container>
    <ng-container right>Biathlon is more than just a sport – it's a physical and mental challenge that pushes athletes to their limits.</ng-container>
</skiira-textbox>

<skiira-footer></skiira-footer>