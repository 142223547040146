import { Component } from '@angular/core';
import { EndUserService, ISprache, MetaTagsService, PartnerService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';
import { Router } from '@angular/router';

@Component({
    selector: 'skiira-partner-en',
    templateUrl: './partner-en.component.html',
    styleUrls: ['./partner-en.component.scss'],
})
export class PartnerEnComponent {
    public r: any[] = [];
    skiiraLanguage = SkiiraLanguage.english;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(
        public partnerService: PartnerService,
        private metaService: MetaTagsService,
        private endUserService: EndUserService,
        private router: Router
    ) {}

    ngOnInit() {
        this.metaService.metaTagCaller('PartnerEn');

        try {
            this.partnerService.getAll().subscribe((data) => {
                this.r = data;
            });
        } catch (error) {
            console.error('Error occured receiving Observable in ngOnInit:', error);
        }

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.partner]);
            }
        });
    }
}
