import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';

@Component({
    selector: 'skiira-events-en',
    templateUrl: './events-en.component.html',
    styleUrls: ['./events-en.component.scss'],
})
export class EventsEnComponent implements OnInit {
    skiiraLanguage = SkiiraLanguage.english;
    skiiraRoutes = SkiiraRouteStanglwirt;

    constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

    ngOnInit() {
        this.metaService.metaTagCaller('EventsEn');

        this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
            if (lang.abkuerzung !== this.skiiraLanguage) {
                this.router.navigate([this.skiiraRoutes.events]);
            }
        });
    }
}
