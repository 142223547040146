import { Component, Input } from '@angular/core';

@Component({
  selector: 'skiira-picture-grid',
  templateUrl: './picture-grid.component.html',
  styleUrls: ['./picture-grid.component.scss'],
})
export class PictureGridComponent {
  @Input() firstSource: string | undefined;
  @Input() secondSource: string | undefined;
  @Input() thirdSource: string | undefined;
  @Input() firstAlt: string | undefined;
  @Input() secondAlt: string | undefined;
  @Input() thirdAlt: string | undefined;

}
