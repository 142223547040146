import { Component, OnInit } from '@angular/core';
import { EndUserService, ISprache, MetaTagsService, SkiiraRouteStanglwirt } from '@skiira/core';
import { SkiiraLanguage } from '../../../enums/skiira.language.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'skiira-kitzbuehl-tv-en',
  templateUrl: './kitzbuehl-tv-en.component.html',
  styleUrls: ['./kitzbuehl-tv-en.component.scss']
})
export class KitzbuehelTvEnComponent implements OnInit {
  skiiraLanguage = SkiiraLanguage.english;
  skiiraRoutes = SkiiraRouteStanglwirt;

  constructor(private metaService: MetaTagsService, private endUserService: EndUserService, private router: Router) {}

  ngOnInit() {
      this.metaService.metaTagCaller('KitzbuehelTvEn');

      this.endUserService.getCurrentLanguageObservable().subscribe((lang: ISprache) => {
          if (lang.abkuerzung !== this.skiiraLanguage) {
              this.router.navigate([this.skiiraRoutes.kitzbueheltv]);
          }
      });
  }
}
