<div class="headerImage"><img src="../../../../../assets/powdern.jpg" /></div>


<skiira-textbox>
    <h1 headline>Snow-Academy Stanglwirt</h1>
    <ng-container headline2>Ski School at the 5-Star Hotel Stanglwirt</ng-container>
    <ng-container left>Welcome to the Snow Academy Stanglwirt. With us, you're in the best hands when it comes to winter sports in all its facets. Apart from offering a top-notch ski school experience for guests of all ages and skill levels, we provide a service that is truly exceptional. Our ski school is nestled amidst the breathtaking Tyrolean mountain scenery, providing the perfect backdrop for your ski vacation.</ng-container>
    <ng-container right>Book your ski school experience at the 5-star Hotel Stanglwirt and create unforgettable moments on the slopes of the Tyrolean Alps. We look forward to welcoming you to our hotel and providing you with an unparalleled skiing vacation experience.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Your Perfect Start to Skiing! Exclusive for Hotel Stanglwirt Guests!</ng-container>
</skiira-textbox>

<skiira-picture-container-3row
    [image1]="'../assets/privatunterricht3_kl.jpg'" [button1href]="'#link1'"
    [image2]="'../assets/privatunterricht3_kl.jpg'" [button2href]="'#link2'"
    [image3]="'../assets/privatunterricht3_kl.jpg'" [button3href]="'#link3'">
    <span text1><br><b>Professional Ski Instructors</b><br>Our highly qualified ski instructors are experts in their field, ensuring you get the most out of your ski vacation. Whether you're a beginner, looking to refine your technique, or an experienced skier, our instructors tailor lessons to your needs.</span>
    <span button1content>Online-Booking</span>

    <span text2><br><b>Tailored Instruction</b><br>We believe in personalized instruction that caters to your abilities and goals. From private lessons to group courses (group courses for children only), we offer various options to ensure progress while having fun.</span>
    <span button2content>Online-Booking</span>

    <span text3><br><b>Kid-Friendly</b><br>We understand the importance of creating a positive experience on the slopes for children. Our child-friendly ski instructors make lessons playful and secure, building your children's confidence and skills.</span>
    <span button3content>Online-Booking</span>
></skiira-picture-container-3row>

<skiira-textbox>
    <p headline2></p>
</skiira-textbox>

<skiira-picture-container-3row
    [image1]="'../assets/privatunterricht3_kl.jpg'" [button1href]="'#link1'"
    [image2]="'../assets/privatunterricht3_kl.jpg'" [button2href]="'#link2'"
    [image3]="'../assets/privatunterricht3_kl.jpg'" [button3href]="'#link3'">
    <span text1><br><b>Diverse Terrain Options</b><br>The surrounding mountains offer a variety of slopes for all skill levels. Our ski instructors will guide you to the best spots that match your level, so you can fully enjoy the thrill of skiing.</span>
    <span button1content>Online-Booking</span>

    <span text2><br><b>Latest Equipment</b><br>If you haven't brought your own gear, we offer high-quality ski equipment for rent. Our staff is here to assist you in selecting the perfect equipment that suits your style and ability.</span>
    <span button2content>Online-Booking</span>

    <span text3><br><b>Safety First</b><br>Your safety is our top priority. Our ski instructors not only focus on your technique but also ensure you understand and follow the rules of the slopes, creating a secure environment for all skiers.</span>
    <span button3content>Online-Booking</span>
></skiira-picture-container-3row>

<skiira-textbox>
    <p headline2>Explore Our Exclusive Beginner's Area at the 5-Star Hotel Stanglwirt</p>
    <p left>Immerse yourself in the fascinating world of skiing at the 5-star Hotel Stanglwirt and experience our exclusive beginner's area – a paradise for those taking their first steps on skis or looking to improve their skills.</p>
    <p right>Our specially designed beginner's area, overlooking the Wilder Kaiser, boasts its own lift and state-of-the-art snowmaking system, promising unparalleled skiing joy for all ages.</p>
</skiira-textbox>

<skiira-textbox>
    <ng-container headline2>Why Our Beginner's Area is Extraordinary:</ng-container>
    <ng-container left><b>1. Gentle Slopes for Safe Learning:</b><br>Our terrain has been carefully designed to offer beginners a safe and comfortable environment. The gentle slopes allow for a gradual introduction to skiing without feeling overwhelmed.</ng-container>
    <ng-container right><b>2. Dedicated Lift for Easy Access:</b><br>An exclusive lift conveniently takes you up the slope, allowing you to spend more time on skis and less time waiting to ascend.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>3. Diverse Attractions and Practice Areas:</b><br>Our beginner's area doesn't only offer excellent slopes, but also diverse attractions and practice zones. From slalom courses to fun obstacles, there are plenty of ways to develop your skills playfully.</ng-container>
    <ng-container right><b>4. Optimal Snow Conditions with Snowmaking:</b><br>Whether at the start of the season or throughout winter, our modern snowmaking system ensures optimal snow conditions on our beginner's area. This means you can practice on well-groomed slopes every visit.</ng-container>
</skiira-textbox>

<skiira-textbox>
    <ng-container left><b>5. Individual Guidance by Experienced Ski Instructors:</b><br>Our friendly and experienced ski instructors are here to support you every step of the way, helping you with your first ski experiences. They tailor their teaching to your abilities, ensuring you build confidence and have fun.</ng-container>
</skiira-textbox>

<skiira-picture-grid 
[firstSource]="'../assets/privatunterricht1_kl.jpg'"
[firstAlt]="'AlternativBeschreibung für das erste Bild'"
[secondSource]="'../assets/privatunterricht2_kl.jpg'"
[secondAlt]="'AlternativBeschreibung für das zweite Bild'"
[thirdSource]="'../assets/privatunterricht3_kl.jpg'"
[thirdAlt]="'AlternativBeschreibung für das dritte Bild'">
</skiira-picture-grid>

<skiira-textbox>
    <p headline2>Book your stay now and experience unforgettable moments on the slopes.</p>
</skiira-textbox>

<div class="container">
  <div class="content-row">
    <br>Whether you're taking your first ski steps or refining your technique, our beginner's area is the perfect starting point for your skiing journey. Discover the joy of skiing at our unique beginner's area at the 5-star Hotel Stanglwirt.
    <br><br>
  </div>
</div>

<skiira-footer></skiira-footer>
