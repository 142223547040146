import { Pipe, PipeTransform } from '@angular/core';
import { KursVarianteContentText } from '../models';
import { EndUserService } from '../services';

@Pipe({
    name: 'kursVariantContentTextPipe',
})
export class KursVarianteContentTextPipe implements PipeTransform {

    constructor(private userService : EndUserService) { }

    transform(textList: KursVarianteContentText[]): string | null {
        var beschreibungsList = textList.find(t => t.spracheId === this.userService.selectedLanguage.id);
        if (beschreibungsList) {
            return beschreibungsList.beschreibung;
        } else {
            return null;
        }
    }
}
