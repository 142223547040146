import { AgbDeComponent } from './agb/deutsch/agb-de.component';
import { AgbEnComponent } from './agb/englisch/agb-en.component';
import { AppComponent } from './app.component';
import { BiathlonDeComponent } from './skischule/biathlon/deutsch/biathlon-de.component';
import { BiathlonEnComponent } from './skischule/biathlon/englisch/biathlon-en.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BueroDeComponent } from './info/bueros/deutsch/buero-de.component';
import { BueroEnComponent } from './info/bueros/englisch/buero-en.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselComponent } from './tools/carousel/carousel.component';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConstructionSiteComponent } from './construction-site/construction-site.component';
import { DataViewModule } from 'primeng/dataview';
import { DirectivesModule } from '../../../../libs/skiira-core/src/lib/directives/directives.module';
import { DropdownModule } from 'primeng/dropdown';
import { EconomyClassDeComponent } from './verleih/economy-class/deutsch/economy-class-de.component';
import { EconomyClassEnComponent } from './verleih/economy-class/englisch/economy-class-en.component';
import { EventsDeComponent } from './skischule/events/deutsch/events-de.component';
import { EventsEnComponent } from './skischule/events/englisch/events-en.component';
import { FaqDeComponent } from './info/faq/deutsch/faq-de.component';
import { FaqEnComponent } from './info/faq/englisch/faq-en.component';
import { FooterComponent } from './tools/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FreerideTourenDeComponent } from './skischule/freeride-touren/deutsch/freeride-touren-de.component';
import { FreerideTourenEnComponent } from './skischule/freeride-touren/englisch/freeride-touren-en.component';
import { GruppenkursDeComponent } from './skischule/gruppenkurs/deutsch/gruppenkurs-de.component';
import { GruppenkursEnComponent } from './skischule/gruppenkurs/englisch/gruppenkurs-en.component';
import { HeaderComponent } from './tools/header/header.component';
import { HeliSkiingDeComponent } from './skischule/heli-skiing/deutsch/heli-skiing-de.component';
import { HeliSkiingEnComponent } from './skischule/heli-skiing/englisch/heli-skiing-en.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageAndContentComponent } from './template-components/image-and-content/image-and-content.component';
import { ImprintDeComponent } from './imprint/deutsch/imprint-de.component';
import { ImprintEnComponent } from './imprint/englisch/imprint-en.component';
import { KitzbuehelTvDeComponent } from './info/kitzbuehel-tv/deutsch/kitzbuehel-tv-de.component';
import { KitzbuehelTvEnComponent } from './info/kitzbuehel-tv/englisch/kitzbuehl-tv-en.component';
import { KontaktDeComponent } from './info/kontakt/deutsch/kontakt-de.component';
import { KontaktEnComponent } from './info/kontakt/englisch/kontakt-en.component';
import { LanglaufDeComponent } from './skischule/langlauf/deutsch/langlauf-de.component';
import { LanglaufEnComponent } from './skischule/langlauf/englisch/langlauf-en.component';
import { LocationDeComponent } from './locations/location/deutsch/location-de.component';
import { LocationEnComponent } from './locations/location/englisch/location-en.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { OnlineBuchenDeutschComponent } from './online-buchen/deutsch/online-buchen-deutsch.component';
import { OnlineBuchenEnglischComponent } from './online-buchen/englisch/online-buchen-englisch.component';
import { PartnerDeComponent } from './info/partner/deutsch/partner-de.component';
import { PartnerEnComponent } from './info/partner/englisch/partner-en.component';
import { PictureContainer3rowComponent } from './template-components/picture-container-3row/picture-container-3row.component';
import { PictureGridComponent } from './template-components/pictureGrid/picture-grid.component';
import { PlatinClassDeComponent } from './verleih/platin-class/deutsch/platin-class-de.component';
import { PlatinClassEnComponent } from './verleih/platin-class/englisch/platin-class-en.component';
import { PremiumClassDeutschComponent } from './verleih/premium-class/deutsch/premium-class-deutsch.component';
import { PremiumClassEnglischComponent } from './verleih/premium-class/englisch/premium-class-englisch.component';
import { PrivatunterrichtDeComponent } from './skischule/privatunterricht/deutsch/privatunterricht-de.component';
import { PrivatunterrichtEnComponent } from './skischule/privatunterricht/englisch/privatunterricht-en.component';
import { provideClientHydration } from '@angular/platform-browser';
import { RennlaufDeComponent } from './skischule/rennlauf/deutsch/rennlauf-de.component';
import { RennlaufEnComponent } from './skischule/rennlauf/englisch/rennlauf-en.component';
import { Route, RouterModule, Routes, Router } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { PipesModule, SkiiraRouteStanglwirt } from '@skiira/core';
import { SnowboardDeutschComponent } from './verleih/snowboard/deutsch/snowboard-deutsch.component';
import { SnowboardEnglischComponent } from './verleih/snowboard/englisch/snowboard-englisch.component';
import { TextboxComponent } from './template-components/textbox/textbox.component';
import { TextboxFullLengthComponent } from './template-components/textbox-full-length/textbox-full-length.component';
import { TourenSkiDeutschComponent } from './verleih/touren-ski/deutsch/touren-ski-deutsch.component';
import { TourenSkiEnglischComponent } from './verleih/touren-ski/englisch/touren-ski-englisch.component';
import { VerleihMainViewDeComponent } from './verleih-main-view/deutsch/verleih-main-view-de.component';
import { VerleihMainViewEnComponent } from './verleih-main-view/englisch/verleih-main-view-en.component';
import { WerbecontainerComponent } from './template-components/werbecontainer/werbecontainer.component';
import { IframeWideComponent } from './tools/iframe-wide/iframe-wide.component';
import { ZahlungComponent } from './zahlung/zahlung.component';
import { MessageService } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { CheckoutComponent } from './checkout/checkout.component';
import { DialogModule } from 'primeng/dialog';

registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeEn, 'en-US');

const routes: Route[] = [
    { path: '', redirectTo: '/' + SkiiraRouteStanglwirt.location, pathMatch: 'full' },
    { path: SkiiraRouteStanglwirt.agb, component: AgbDeComponent },
    { path: SkiiraRouteStanglwirt.tos, component: AgbEnComponent},
    { path: SkiiraRouteStanglwirt.biathlon, component: BiathlonDeComponent },
    { path: SkiiraRouteStanglwirt.biathlonEn, component: BiathlonEnComponent },
    { path: SkiiraRouteStanglwirt.buchen, component: OnlineBuchenDeutschComponent },
    { path: SkiiraRouteStanglwirt.buchenEn, component: OnlineBuchenEnglischComponent },
    { path: SkiiraRouteStanglwirt.buero, component: BueroDeComponent },
    { path: SkiiraRouteStanglwirt.bueroEn, component: BueroEnComponent },
    { path: SkiiraRouteStanglwirt.events, component: EventsDeComponent },
    { path: SkiiraRouteStanglwirt.eventsEn, component: EventsEnComponent },
    { path: SkiiraRouteStanglwirt.faq, component: FaqDeComponent },
    { path: SkiiraRouteStanglwirt.faqEn, component: FaqEnComponent },
    { path: SkiiraRouteStanglwirt.freeride, component: FreerideTourenDeComponent },
    { path: SkiiraRouteStanglwirt.freerideEn, component: FreerideTourenEnComponent },
    { path: SkiiraRouteStanglwirt.gruppenkurs, component: GruppenkursDeComponent },
    { path: SkiiraRouteStanglwirt.gruppenkursEn, component: GruppenkursEnComponent },
    { path: SkiiraRouteStanglwirt.heliskiing, component: HeliSkiingDeComponent },
    { path: SkiiraRouteStanglwirt.heliskiingEn, component: HeliSkiingEnComponent },
    { path: SkiiraRouteStanglwirt.imprint, component: ImprintEnComponent },
    { path: SkiiraRouteStanglwirt.impressum, component: ImprintDeComponent},
    { path: SkiiraRouteStanglwirt.kitzbueheltv, component: KitzbuehelTvDeComponent },
    { path: SkiiraRouteStanglwirt.kitzbueheltvEn, component: KitzbuehelTvEnComponent },
    { path: SkiiraRouteStanglwirt.kontakt, component: KontaktDeComponent },
    { path: SkiiraRouteStanglwirt.kontaktEn, component: KontaktEnComponent },
    { path: SkiiraRouteStanglwirt.langlauf, component: LanglaufDeComponent },
    { path: SkiiraRouteStanglwirt.langlaufEn, component: LanglaufEnComponent },
    { path: SkiiraRouteStanglwirt.location, component: LocationDeComponent },
    { path: SkiiraRouteStanglwirt.locationEn, component: LocationEnComponent },
    { path: SkiiraRouteStanglwirt.partner, component: PartnerDeComponent },
    { path: SkiiraRouteStanglwirt.partnerEn, component: PartnerEnComponent },
    { path: SkiiraRouteStanglwirt.privatunterricht, component: PrivatunterrichtDeComponent },
    { path: SkiiraRouteStanglwirt.privatunterrichtEn, component: PrivatunterrichtEnComponent },
    { path: SkiiraRouteStanglwirt.renntraining, component: RennlaufDeComponent },
    { path: SkiiraRouteStanglwirt.renntrainingEn, component: RennlaufEnComponent },
    { path: SkiiraRouteStanglwirt.tourenski, component: TourenSkiDeutschComponent },
    { path: SkiiraRouteStanglwirt.tourenskiEn, component: TourenSkiEnglischComponent },
    { path: SkiiraRouteStanglwirt.verleih, component: VerleihMainViewDeComponent },
    { path: SkiiraRouteStanglwirt.verleihEn, component: VerleihMainViewEnComponent },
    { path: SkiiraRouteStanglwirt.zahlung, component: ZahlungComponent },
    { path: SkiiraRouteStanglwirt.economyde, component: EconomyClassDeComponent },
    { path: SkiiraRouteStanglwirt.economyen, component: EconomyClassEnComponent },
    { path: SkiiraRouteStanglwirt.platinde, component: PlatinClassDeComponent },
    { path: SkiiraRouteStanglwirt.platinen, component: PlatinClassEnComponent },
    { path: SkiiraRouteStanglwirt.premiumde, component: PremiumClassDeutschComponent },
    { path: SkiiraRouteStanglwirt.premiumen, component: PremiumClassEnglischComponent },
    { path: SkiiraRouteStanglwirt.snowboard, component: SnowboardDeutschComponent },
    { path: SkiiraRouteStanglwirt.snowboarden, component: SnowboardEnglischComponent },
    
];

@NgModule({
    declarations: [
        AgbDeComponent,
        AgbEnComponent,
        AppComponent,
        AppComponent,
        BiathlonDeComponent,
        BiathlonEnComponent,
        BueroDeComponent,
        BueroEnComponent,
        CarouselComponent,
        ConstructionSiteComponent,
        EconomyClassDeComponent,
        EconomyClassEnComponent,
        EventsDeComponent,
        EventsEnComponent,
        FaqDeComponent,
        FaqEnComponent,
        FooterComponent,
        FreerideTourenDeComponent,
        FreerideTourenEnComponent,
        GruppenkursDeComponent,
        GruppenkursEnComponent,
        HeaderComponent,
        HeliSkiingDeComponent,
        HeliSkiingEnComponent,
        ImageAndContentComponent,
        ImprintDeComponent,
        ImprintEnComponent,
        KitzbuehelTvDeComponent,
        KitzbuehelTvEnComponent,
        KontaktDeComponent,
        KontaktEnComponent,
        LanglaufDeComponent,
        LanglaufEnComponent,
        LocationDeComponent,
        LocationEnComponent,
        OnlineBuchenDeutschComponent,
        OnlineBuchenEnglischComponent,
        PartnerDeComponent,
        PartnerEnComponent,
        PictureContainer3rowComponent,
        PictureGridComponent,
        PlatinClassDeComponent,
        PlatinClassEnComponent,
        PremiumClassDeutschComponent,
        PremiumClassEnglischComponent,
        PrivatunterrichtDeComponent,
        PrivatunterrichtEnComponent,
        RennlaufDeComponent,
        RennlaufEnComponent,
        SnowboardDeutschComponent,
        SnowboardEnglischComponent,
        TextboxComponent,
        TextboxFullLengthComponent,
        TourenSkiDeutschComponent,
        TourenSkiEnglischComponent,
        VerleihMainViewDeComponent,
        VerleihMainViewEnComponent,
        WerbecontainerComponent,
        IframeWideComponent,
        ZahlungComponent,
        HeaderComponent,
        CheckoutComponent,
        
    ],

    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            useHash: false,
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'top',
        }),
        BrowserAnimationsModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        CheckboxModule,
        DataViewModule,
        DialogModule,
        DirectivesModule,
        DropdownModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        PipesModule,
        ReactiveFormsModule,
        SidebarModule,
    ],
    providers: [provideClientHydration(),
                MessageService,
                ],
    bootstrap: [AppComponent],
})
export class AppModule {}
